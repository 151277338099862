<script>
  // library for creating dropdown menu appear on click
  import {createPopper} from '@popperjs/core';
  import {Navigate, routeIsActive} from 'svelte-router-spa';
  import {onMount} from 'svelte';
  import axios from 'axios';
  import {authToken} from '../../store/auth';
  import {parseAndShowErrorMessage} from '../../utils/errorParser';
  // core components

  const image = '/assets/img/user-profile.png';

  let dropdownPopoverShow = false;

  let btnDropdownRef;
  let popoverDropdownRef;
  export let role;

  let headers = {
    'X-Auth-Token': $authToken
  };

  const toggleDropdown = event => {
    event.preventDefault();
    if (dropdownPopoverShow) {
      dropdownPopoverShow = false;
    } else {
      dropdownPopoverShow = true;
      createPopper(btnDropdownRef, popoverDropdownRef, {
        placement: 'bottom-start'
      });
    }
  };

  function getUserDetails() {
    axios
      .get(`${morrDashboard.env.API_URL}/users/`, {
        headers: headers
      })
      .then(res => {
        let userDetails = res.data.data;
        if (userDetails.is_admin) {
          role = 'Admin';
        } else if (userDetails.is_manager) {
          role = 'Manager';
        } else {
          role = 'User';
        }
      })
      .catch(err => {
        parseAndShowErrorMessage(err);
      });
  }

  onMount(() => {
    getUserDetails();
  });
</script>

<div>
  <a
    class="text-gray-600 block"
    href="/#morr-home"
    bind:this={btnDropdownRef}
    on:click={toggleDropdown}
  >
    <div class="items-center flex">
      <span
        class="w-12 h-12 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full"
      >
        <img
          alt="..."
          class="w-full p-3 rounded-full align-middle border-none shadow-lg"
          src={image}
        />
      </span>
      <span
        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-blueGray-600 bg-blueGray-200 uppercase last:mr-0 mr-1"
      >
        {role}
      </span>
    </div>
  </a>
  <div
    bind:this={popoverDropdownRef}
    class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48 {dropdownPopoverShow
      ? 'block'
      : 'hidden'}"
  >
    <a
      href="/accounts/profile"
      on:click={toggleDropdown}
      class="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"
      style="z-index: 2;"
    >
      <i
        class="fas fa-user mr-2 text-sm"
        class:active={routeIsActive('/accounts/profile')}
      />
      Profile
    </a>
    <a
      href="/accounts/change-password"
      on:click={toggleDropdown}
      class="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"
      style="z-index: 2;"
    >
      <i
        class="fas fa-lock mr-2 text-sm"
        class:active={routeIsActive('/accounts/change-password')}
      />
      Change Password
    </a>
    <a
      href="/accounts/logout"
      on:click={toggleDropdown}
      class="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"
    >
      <i
        class="fas fa-power-off mr-2 text-sm"
        class:active={routeIsActive('/accounts/logout')}
      />
      Logout
    </a>
  </div>
</div>
