<script>
  import {authToken} from '../../../store/auth';
  import axios from 'axios';
  import {showErrorMessage, showSuccessMessage} from '../../../utils/toast';
  import {parseAndShowErrorMessage} from '../../../utils/errorParser';
  import {createEventDispatcher} from 'svelte';

  let dispatch = createEventDispatcher();

  export let showComponent;
  export let exported_merchant_id;
  export let showPrevComponent;
  export let merchantInfo;

  let successMessage;

  let merchantFormFields = {
    discounts: {
      bronze: {
        thresold: merchantInfo.point_tiers.bronze
          ? merchantInfo.point_tiers.bronze.thresold
          : '0',
        increment_by: merchantInfo.point_tiers.bronze
          ? merchantInfo.point_tiers.bronze.increment_by
          : 'amount',
        type: merchantInfo.point_tiers.bronze
          ? merchantInfo.point_tiers.bronze.type
          : 'percentage',
        cashback: merchantInfo.point_tiers.bronze
          ? merchantInfo.point_tiers.bronze.cashback
          : 3
      },
      silver: {
        thresold: merchantInfo.point_tiers.silver
          ? merchantInfo.point_tiers.silver.thresold
          : '500',
        increment_by: merchantInfo.point_tiers.silver
          ? merchantInfo.point_tiers.silver.increment_by
          : 'amount',
        type: merchantInfo.point_tiers.silver
          ? merchantInfo.point_tiers.silver.type
          : 'percentage',
        cashback: merchantInfo.point_tiers.silver
          ? merchantInfo.point_tiers.silver.cashback
          : 5
      },
      gold: {
        thresold: merchantInfo.point_tiers.gold
          ? merchantInfo.point_tiers.gold.thresold
          : '1000',
        increment_by: merchantInfo.point_tiers.gold
          ? merchantInfo.point_tiers.gold.increment_by
          : 'amount',
        type: merchantInfo.point_tiers.gold
          ? merchantInfo.point_tiers.gold.type
          : 'percentage',
        cashback: merchantInfo.point_tiers.gold
          ? merchantInfo.point_tiers.gold.cashback
          : 8
      }
    }
  };

  function performAddDiscount() {
    grecaptcha.ready(function () {
      grecaptcha
        .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
          action: 'MerchantDiscount'
        })
        .then(function (token) {
          if (token) {
            postToCreateDiscountApi(token);
          }
        });
    });
  }

  function postToCreateDiscountApi(token) {
    let payload = merchantFormFields.discounts;
    axios
      .post(
        `${morrDashboard.env.API_URL}/admin/merchants/onboarding/${exported_merchant_id}/discount-tiers`,
        payload,
        {
          headers: {
            'X-Auth-Token': $authToken,
            'x-google-token': token
          }
        }
      )
      .then(response => {
        showSuccessMessage('Discounts Saved');
        dispatch('GetMerchantID', exported_merchant_id);
        showComponent = 'Image';
      })
      .catch(err => {
        parseAndShowErrorMessage(err);
      });
  }

  function postToUpdateMerchantInfoApi(payload) {
    axios
      .put(
        `${morrDashboard.env.API_URL}/admin/merchants/update/${exported_merchant_id}`,
        payload,
        {
          headers: {
            'X-Auth-Token': $authToken
          }
        }
      )
      .then(response => {
        successMessage = 'Successfully updated.';
        showSuccessMessage(successMessage);

        showPrevComponent = '';
        showComponent = 'Image';
      })
      .catch(err => {
        parseAndShowErrorMessage(err);
        console.error('MERCHANT_UPDATE_ERROR', err);
      });
  }

  function updateMerchant() {
    grecaptcha.ready(function () {
      grecaptcha
        .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
          action: 'MerchantUpdate'
        })
        .then(function (token) {
          if (token) {
            let payload = {
              updated_merchant: {
                point_tiers: merchantFormFields.discounts
              },
              captcha: {
                captcha_token: token
              }
            };
            postToUpdateMerchantInfoApi(payload);
          }
        });
    });
  }

  function handleSave() {
    performAddDiscount();
  }

  function handlePrev() {
    showPrevComponent = 'Contact';
  }

  function handleUpdate() {
    updateMerchant();
  }
</script>

<div class="main">
  <h6 class="title text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
    Add Discounts
  </h6>
  <!-- Bronze discounts-->
  <div
    class="flex flex-auto flex-wrap p-3 xs:w-full"
    style={'background: #cd7f32;'}
  >
    <h6
      class=" text-black-500 text-sm w-full lg:w-full m-3 font-bold uppercase"
    >
      Bronze Level Discount
    </h6>
    <div class="w-full lg:w-3/12 px-4">
      <div class="relative w-full mb-3">
        <label
          class="block uppercase text-gray-700 text-xs font-bold mb-2"
          for={`bronze-discount`}
        >
          Type
        </label>
        <select
          class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
          bind:value={merchantFormFields.discounts.bronze.type}
        >
          <option value="percentage">Percentage</option>
          <option value="flat">Flat</option>
        </select>
      </div>
    </div>

    <div class="w-full lg:w-3/12 px-4">
      <div class="relative w-full mb-3">
        <label
          class="block uppercase text-gray-700 text-xs font-bold mb-2"
          for={`bronze-discount`}
        >
          <div class="tooltip right cashback">
            Cashback<i class="fas fa-info-circle ml-2" />
          </div>
        </label>
        <input
          class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
          type="number"
          min="1"
          bind:value={merchantFormFields.discounts.bronze.cashback}
        />
      </div>
    </div>
    <div class="w-full lg:w-3/12 px-4">
      <div class="relative w-full mb-3">
        <label
          class="block uppercase text-gray-700 text-xs font-bold mb-2"
          for={`bronze-discount`}
        >
          <div class="tooltip right thresold">
            Minimum Spend for Bronze Level<i class="fas fa-info-circle ml-2" />
          </div>
        </label>
        <input
          class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
          type="number"
          min="1"
          bind:value={merchantFormFields.discounts.bronze.thresold}
        />
      </div>
    </div>

    <div class="w-full lg:w-3/12 px-4">
      <div class="relative w-full mb-3">
        <label
          class="block uppercase text-gray-700 text-xs font-bold mb-2"
          for={`bronze-discount`}
        >
          Level Change By
        </label>
        <select
          class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
          bind:value={merchantFormFields.discounts.bronze.increment_by}
        >
          <option value="amount">Amount</option>
          <option value="visits">Visits</option>
          <option value="points">Points</option>
        </select>
      </div>
    </div>
  </div>

  <div class="m-8" />
  <!-- Silver discounts-->
  <div class="flex flex-wrap p-3" style={'background: #C0C0C0;'}>
    <h6
      class=" text-black-500 text-sm w-full lg:w-full m-3 font-bold uppercase"
    >
      Silver Level Discount
    </h6>
    <div class="w-full lg:w-3/12 px-4">
      <div class="relative w-full mb-3">
        <label
          class="block uppercase text-gray-700 text-xs font-bold mb-2"
          for={`silver-discount`}
        >
          Type
        </label>
        <select
          class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
          bind:value={merchantFormFields.discounts.silver.type}
        >
          <option value="percentage">Percentage</option>
          <option value="flat">Flat</option>
        </select>
      </div>
    </div>

    <div class="w-full lg:w-3/12 px-4">
      <div class="relative w-full mb-3">
        <label
          class="block uppercase text-gray-700 text-xs font-bold mb-2"
          for={`silver-discount`}
        >
          <div class="tooltip right cashback">
            Cashback<i class="fas fa-info-circle ml-2" />
          </div>
        </label>
        <input
          class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
          type="number"
          min="1"
          bind:value={merchantFormFields.discounts.silver.cashback}
        />
      </div>
    </div>
    <div class="w-full lg:w-3/12 px-4">
      <div class="relative w-full mb-3">
        <label
          class="block uppercase text-gray-700 text-xs font-bold mb-2"
          for={`silver-discount`}
        >
          <div class="tooltip right thresold">
            Minimum Spend for Silver Level<i class="fas fa-info-circle ml-2" />
          </div>
        </label>
        <input
          class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
          type="number"
          min="1"
          bind:value={merchantFormFields.discounts.silver.thresold}
        />
      </div>
    </div>

    <div class="w-full lg:w-3/12 px-4">
      <div class="relative w-full mb-3">
        <label
          class="block uppercase text-gray-700 text-xs font-bold mb-2"
          for={`silver-level`}
        >
          Level Change By
        </label>
        <select
          class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
          bind:value={merchantFormFields.discounts.silver.increment_by}
        >
          <option value="amount">Amount</option>
          <option value="visits">Visits</option>
          <option value="points">Points</option>
        </select>
      </div>
    </div>
  </div>

  <div class="m-8" />
  <!-- Gold discounts-->
  <div class="flex flex-wrap p-3" style={'background: #FFD700;'}>
    <h6
      class=" text-black-500 text-sm w-full lg:w-full m-3 font-bold uppercase"
    >
      Gold Level Discount
    </h6>
    <div class="w-full lg:w-3/12 px-4">
      <div class="relative w-full mb-3">
        <label
          class="block uppercase text-gray-700 text-xs font-bold mb-2"
          for={`gold-discount`}
        >
          Type
        </label>
        <select
          class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
          bind:value={merchantFormFields.discounts.gold.type}
        >
          <option value="percentage">Percentage</option>
          <option value="flat">Flat</option>
        </select>
      </div>
    </div>

    <div class="w-full lg:w-3/12 px-4">
      <div class="relative w-full mb-3">
        <label
          class="block uppercase text-gray-700 text-xs font-bold mb-2"
          for={`gold-discount`}
        >
          <div class="tooltip right cashback">
            Cashback<i class="fas fa-info-circle ml-2" />
          </div>
        </label>
        <input
          class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
          type="number"
          min="1"
          bind:value={merchantFormFields.discounts.gold.cashback}
        />
      </div>
    </div>
    <div class="w-full lg:w-3/12 px-4">
      <div class="relative w-full mb-3">
        <label
          class="block uppercase text-gray-700 text-xs font-bold mb-2"
          for={`gold-discount`}
        >
          <div class="tooltip right thresold">
            Minimum Spend for Gold Level<i class="fas fa-info-circle ml-2" />
          </div>
        </label>
        <input
          class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
          type="number"
          min="1"
          bind:value={merchantFormFields.discounts.gold.thresold}
        />
      </div>
    </div>

    <div class="w-full lg:w-3/12 px-4">
      <div class="relative w-full mb-3">
        <label
          class="block uppercase text-gray-700 text-xs font-bold mb-2"
          for={`gold-level`}
        >
          Level Change By
        </label>
        <select
          class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
          bind:value={merchantFormFields.discounts.gold.increment_by}
        >
          <option value="amount">Amount</option>
          <option value="visits">Visits</option>
          <option value="points">Points</option>
        </select>
      </div>
    </div>
  </div>

  <div class="next-btn">
    <button
      class="bg-gray-700 text-white active:bg-gray-800 font-bold uppercase text-xs px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 h-8"
      type="button"
      on:click={handlePrev}
    >
      Prev
    </button>
    {#if showPrevComponent}
      <button
        class="bg-gray-700 text-white active:bg-gray-800 font-bold uppercase text-xs px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 h-8"
        type="button"
        on:click={handleUpdate}
      >
        Update & Next
      </button>
    {:else}
      <button
        class="bg-gray-700 text-white active:bg-gray-800 font-bold uppercase text-xs px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 h-8"
        type="button"
        on:click={handleSave}
      >
        Save & Next
      </button>
    {/if}
  </div>
</div>

<style>
  .title {
    justify-content: center;
    text-align: center;
    font-size: larger;
  }

  .next-btn {
    justify-content: center;
    text-align: center;
    margin: 20px auto;
  }
  .tooltip {
    position: relative;

    color: black;
    margin: 0 auto;
  }
  .tooltip::before {
    position: absolute;
    z-index: 2;
    width: 200px;
    max-width: 200px;
    padding: 10px;
    display: none;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
  }
  .tooltip::after {
    position: absolute;
    z-index: 1;
    display: none;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    content: '';
  }
  .tooltip:hover::before,
  .tooltip:hover::after {
    display: inline-block;
  }
  .tooltip.right:before {
    top: 50%;
    left: calc(20% + 5px);
    transform: translateY(-50%);
  }
  .tooltip.right:after {
    top: 50%;
    left: 20%;
    transform: translateY(-50%);
    border-left-width: 0;
    border-right-color: rgba(0, 0, 0, 0.8);
  }
  .tooltip.right.cashback:before {
    content: 'amount to be awarded to user per successful transaction.';
    top: 50%;
    left: calc(30% + 5px);
    transform: translateY(-50%);
  }
  .tooltip.right.cashback:after {
    top: 50%;
    left: 30%;
    transform: translateY(-50%);
    border-left-width: 0;
    border-right-color: rgba(0, 0, 0, 0.8);
  }
  .tooltip.right.thresold:before {
    content: 'max amount of points at which user level will be upgraded to next tier.';
    top: 50%;
    left: calc(65% + 5px);
    transform: translateY(-50%);
  }
  .tooltip.right.thresold:after {
    top: 50%;
    left: 65%;
    transform: translateY(-50%);
    border-left-width: 0;
    border-right-color: rgba(0, 0, 0, 0.8);
  }
</style>
