<script>
  import AddressCard from "./AddressCard.svelte";
  export let address = {};
  export let merchant_id;
  let AddressCardRef;
</script>
<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0"
>
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between">
      <h6 class="text-gray-800 text-xl font-bold">Update Address</h6>
    </div>
  </div>
  <AddressCard address={address} {merchant_id} bind:this={AddressCardRef} />
  <div class="next-btn">
    <button
      class="bg-gray-700 text-white active:bg-gray-800 font-bold uppercase text-xs px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 h-8"
      type="button"
      on:click={() => AddressCardRef.handleUpdate()}
    >
      Update Address
    </button>
  </div>

</div>

<style>
  .next-btn {
    justify-content: center;
    text-align: center;
    margin: 20px auto;
  }
</style>
