<script>
  import {form, bindClass} from 'svelte-forms';

  export let showAddGroup;
  export let callback;

  let groupFormField = {
    name: ''
  };

  const groupForm = form(
    () => ({
      name: {value: groupFormField.name, validators: ['required']}
    }),
    {
      initCheck: true,
      validateOnChange: true
    }
  );
</script>

<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-xl bg-gray-200 border-0"
>
  <div class="rounded-t mb-0 px-6 py-2">
    <h6 class="text-gray-800 text-lg font-bold">Add Groups</h6>
  </div>
  <div class="flex-auto px-2 lg:px-10 py-4 pt-0 my-2">
    <form>
      <div class="flex flex-wrap">
        <div class="w-full px-2">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              for="group-name"
            >
              Group Name
            </label>
            <input
              id="group-name"
              type="text"
              class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
              placeholder="Please enter group Name"
              bind:value={groupFormField.name}
              use:bindClass={{form: groupForm, name: 'name'}}
            />
          </div>
        </div>

        <hr class="mt-6 border-b-1 border-gray-400" />
        <div class="flex flex-row">
          <div class="w-12/12 px-4 mt-4 mx-auto">
            <button
              class="bg-gray-700 text-white active:bg-gray-800 font-bold uppercase text-xs px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 h-8"
              type="button"
              on:click|preventDefault={() => callback(groupFormField)}
            >
              Create Group
            </button>
            <button
              class="bg-gray-700 text-white active:bg-gray-800 font-bold uppercase text-xs px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 h-8"
              type="button"
              on:click|preventDefault={() => {
                showAddGroup = false;
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
