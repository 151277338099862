<script>
  import {onMount} from 'svelte';
  import axios from 'axios';

  import {authToken} from '../../../store/auth';
  import {showSuccessMessage} from '../../../utils/toast';
  import {parseAndShowErrorMessage} from '../../../utils/errorParser';

  export let address;
  export let merchant_id;
  export let callbackfn;

  let merchantMapContainerRef;
  let merchantMap
  let merchantMarker

  let place;


  let autocomplete;

  let googleAddressComponents = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'long_name',
    postal_code: 'short_name'
  };

  let addressFormFields = {
    locality: address ? address.city : ' ',
    country: address ? address.country : ' ',
    postal_code: address ? address.postal_code : ' ',
    streetAddress: address ? address.address : ' ',
    latitude: address?.latitude,
    longitude: address?.longitude,
    google_map_link: address.google_map_link
  };

  let geolocation = {
    lat: address?.latitude || 0,
    lng: address?.longitude || 0
  };

  let googleInput = address ? address.address : '';

  $: if (geolocation && merchantMarker){
    let newLatLng = new google.maps.LatLng(geolocation.lat, geolocation.lng);
    merchantMarker.setPosition(newLatLng);
    recenterMap(merchantMap, merchantMarker);
  }

  function postToUpdateAddressAPI(payload) {
    axios
      .put(
        `${morrDashboard.env.API_URL}/admin/merchants/update/${merchant_id}/address`,
        payload,
        {
          headers: {
            'X-Auth-Token': $authToken
          }
        }
      )
      .then(response => {
        showSuccessMessage('Address updated successfully');
        if (callbackfn){
          callbackfn()
        }
      })
      .catch(err => {
        parseAndShowErrorMessage(err);
        throw err;
      });
  }

  export function handleUpdate() {
    grecaptcha.ready(function () {
      grecaptcha
        .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
          action: 'AddressUpdate'
        })
        .then(function (token) {
          if (token) {
            let payload = {
              updated_address: {
                address: addressFormFields.streetAddress,
                city: addressFormFields.locality,
                country: addressFormFields.country,
                longitude: geolocation?.lng,
                latitude: geolocation?.lat,
                google_map_link: addressFormFields.google_map_link,
                postal_code: addressFormFields.postal_code
              },
              captcha: {
                captcha_token: token
              }
            };

            postToUpdateAddressAPI(payload);
          }
        });
    });
  }

  function initAutocomplete() {
    if (!(address?.latitude || address?.longitude)){
      geolocate();
    }

    setupGoogleMap()
    autocomplete = new google.maps.places.Autocomplete(googleInput, {
      types: ['establishment']
    });
    autocomplete.addListener('place_changed', fillInAddress);
  }

  function fillInAddress() {
    place = autocomplete.getPlace();
    addressFormFields.streetAddress = `${place.name}, ${place.formatted_address}`;
    addressFormFields.google_map_link = place.url;
    console.log("Place details", place)
    let latitude = place?.geometry?.location?.lat();
    let longitude = place?.geometry?.location?.lng()
    if (latitude && longitude){
      geolocation = {
        lat: latitude,
        lng: longitude
      }
    }

    for (let i = 0; i < place.address_components.length; i++) {
     let addressType = place.address_components[i].types[0];
      if (addressFormFields.hasOwnProperty(addressType)) {
       let val = place.address_components[i][googleAddressComponents[addressType]];
        addressFormFields[addressType] = val;
      }
    }
  }

  function geolocate() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        let latitude = position?.coords?.latitude;
        let longitude = position?.coords?.longitude
        if (latitude && longitude){
          geolocation = {
            lat: latitude,
            lng: longitude
          };
        }

        console.log("found geo", geolocation)

       let circle = new google.maps.Circle({
          center: geolocation,
          radius: position?.coords?.accuracy
        });
        autocomplete.setBounds(circle.getBounds());
      });
    }
  }

  function recenterMap(map, marker){
    map.setCenter(marker.position);
    marker.setMap(map);
    map.setZoom(15);

  }

  function setupGoogleMap(){
    merchantMap = new google.maps.Map(document.getElementById('merchantMapContainer'), {
      zoom: 1,
      center: new google.maps.LatLng(geolocation.lat, geolocation.lng, 9),
      mapTypeId: google.maps.MapTypeId.ROADMAP
    });

    merchantMarker = new google.maps.Marker({
        position: new google.maps.LatLng(geolocation.lat, geolocation.lng),
        draggable: true
    });

    google.maps.event.addListener(merchantMarker, 'dragend', function (evt) {

      let latitude = evt.latLng?.lat();
      let longitude = evt.latLng?.lng();

      if (latitude && longitude){
        geolocation = {
          lat: latitude,
          lng: longitude
        };
      }

    });

    merchantMap.setCenter(merchantMarker.position);
    merchantMarker.setMap(merchantMap);
  }


  onMount(() => {
    if (!window.hasOwnProperty("google")){
      setTimeout(function(){
        initAutocomplete();
      }, 1000)
    }else{
      initAutocomplete();
    }
  });
</script>

<div class="flex-auto px-4 lg:px-10 py-10 pt-0">
  <form>
    <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
      Address Information
    </h6>
    <div class="flex flex-wrap">
      <div class="w-full h-96 px-4" id="merchantMapContainer" bind:this={merchantMapContainerRef}>

      </div>

      <div class="w-full lg:w-full px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-gray-700 text-xs font-bold mb-2"
            for="address-text"
          >
            Address Search
          </label>
          <input
            type="text"
            class="form-control px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
            id="search_input"
            bind:this={googleInput}
            placeholder="Please start typing your store address...; e.g: Velvet Vintage, Kuala Lumpur"
          />
        </div>
      </div>
      <div class="w-full lg:w-full px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-gray-700 text-xs font-bold mb-2"
            for="street-address"
          >
            Street Address
          </label>
          <input
            id="street-address"
            type="street"
            class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
            placeholder="Street Address"
            bind:value={addressFormFields.streetAddress}
          />
        </div>
      </div>
      <div class="w-full lg:w-4/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-gray-700 text-xs font-bold mb-2"
            for="address-city"
          >
            City
          </label>
          <input
            id="address-city"
            type="city"
            class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
            placeholder="City where Merchant is located"
            bind:value={addressFormFields.locality}
          />
        </div>
      </div>
      <div class="w-full lg:w-4/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-gray-700 text-xs font-bold mb-2"
            for="address-country"
          >
            Country
          </label>
          <input
            id="address-country"
            type="text"
            class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
            placeholder="Country where Merchant is located"
            bind:value={addressFormFields.country}
          />
        </div>
      </div>
      <div class="w-full lg:w-4/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-gray-700 text-xs font-bold mb-2"
            for="address-postal-code"
          >
            Postal Code
          </label>
          <input
            id="address-postal-code"
            type="text"
            class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
            placeholder="Postal code"
            bind:value={addressFormFields.postal_code}
          />
        </div>
      </div>

      <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-gray-700 text-xs font-bold mb-2"
            for="address-postal-code"
          >
            Google Map link
          </label>
          <input
            id="address-postal-code"
            type="text"
            class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
            placeholder="Google Map link"
            bind:value={addressFormFields.google_map_link}
          />
        </div>
      </div>

      <div class="w-full lg:w-3/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-gray-700 text-xs font-bold mb-2"
            for="address-postal-code"
          >
            Latitude
          </label>
          <input
            id="address-postal-code"
            type="text"
            class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
            placeholder="Postal code"
            bind:value={geolocation.lat}
          />
        </div>
      </div>

      <div class="w-full lg:w-3/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-gray-700 text-xs font-bold mb-2"
            for="address-postal-code"
          >
            Longitude
          </label>
          <input
            id="address-postal-code"
            type="text"
            class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
            placeholder="Postal code"
            bind:value={geolocation.lng}
          />
        </div>
      </div>

    </div>
  </form>
</div>

<style>
  :global(input.invalid) {
    border: 1px solid rgb(241, 48, 48);
  }
</style>
