<script>
  import {Datatable, rows} from 'svelte-simple-datatables';
  import axios from 'axios';
  import {authToken} from '../../../store/auth';
  import {onMount} from 'svelte';
  import {toast} from '@zerodevx/svelte-toast';
  import {Confirm} from 'svelte-confirm';
  import {parseAndShowErrorMessage} from '../../../utils/errorParser';
  import Pagination from '@fouita/pagination';
  import {recaptchaWrapper} from '../../../utils/google-captcha';
  import StoreAddonCrudModal from './StoreAddonCrudModal.svelte';
  import {showSuccessMessage} from '../../../utils/toast';

  export let store_id;
  let validationError = null;

  let baseApiURL = morrDashboard.env.API_V2_URL;
  const settings = {
    sortable: true,
    pagination: false,
    rowPerPage: 10,
    scrollY: false,
    columnFilter: true,
    noRows: 'No entries to found',
    css: true,
    blocks: {
      searchInput: false
    }
  };

  const limit = 10;
  let addon_id;
  let addonCrudCallback = () => {};
  let color = 'light';
  let operationType;
  let showModal = false;
  let addonData;
  let current = 1;
  let skip = 0;
  let totalItems = 0;
  let allAddons = [];
  let addonsToShow = [];
  let showAddons = false;
  let noAddons = 'loading addons ...';
  function getAddonListApi(skip) {
    let headers = {
      'X-Auth-Token': $authToken
    };
    axios
      .get(
        `${baseApiURL}/admin/store/${store_id}/addons?skip=${skip}&limit=${limit}`,
        {
          headers: headers
        }
      )
      .then(res => {
        console.log('ALL_ADDONS_LISTING', res);
        allAddons = res.data.data;
        addonsToShow = [...allAddons];
        totalItems = res.data.paging.total;
        showAddons = true;
      })
      .catch(err => {
        console.error('ALL_ADDONS_LISTING_ERROR', err);
        showAddons = false;
        noAddons = 'Addons not found';
        parseAndShowErrorMessage(err);
      });
  }

  function createAddon(payload) {
    recaptchaWrapper('StoreAddonCreate', postToCreateAddon, [payload]);
  }
  function postToCreateAddon(token, payload) {
    axios
      .post(`${baseApiURL}/admin/store/${store_id}/addons/`, payload, {
        headers: {
          'X-auth-token': $authToken,
          'x-google-token': token
        }
      })
      .then(response => {
        showSuccessMessage('New Addon Created');
        showModal = !showModal;
        getAddonListApi(skip);
      })
      .catch(err => {
        parseAndShowErrorMessage(err);
      });
  }

  function updateAddon(payload) {
    recaptchaWrapper('StoreAddonUpdate', updateAddonApi, [payload]);
  }

  function updateAddonApi(token, payload) {
    axios
      .put(
        `${baseApiURL}/admin/store/${store_id}/addons/${addon_id}`,
        payload,
        {
          headers: {
            'X-auth-token': $authToken,
            'x-google-token': token
          }
        }
      )
      .then(response => {
        showSuccessMessage('Addon updated succesfully');
        showModal = !showModal;
        getAddonListApi(skip);
      })
      .catch(err => {
        parseAndShowErrorMessage(err);
      });
  }

  function deleteAddon(addon_id) {
    recaptchaWrapper('StoreAddonDelete', deleteAddonApi, [addon_id]);
  }
  function deleteAddonApi(token, addon_id) {
    axios
      .delete(`${baseApiURL}/admin/store/${store_id}/addons/${addon_id}`, {
        headers: {
          'X-auth-token': $authToken,
          'x-google-token': token
        }
      })
      .then(response => {
        showSuccessMessage('Addon delete successfully');
        getAddonListApi(skip);
      })
      .catch(err => {
        parseAndShowErrorMessage(err);
      });
  }

  onMount(() => {
    getAddonListApi(0);
  });

  // Todo: Pagination Left
  function paginate(e) {
    if (current === 1) {
      getAddonListApi(0);
    } else if (current > 1) {
      skip = limit * (current - 1);
      getAddonListApi(skip);
    }
  }

  function visitUpdate(id, addon, index) {
    addon_id = id;
    addonData = addon;
    showModal = !showModal;
    addonCrudCallback = updateAddon;
  }
</script>

{#if showModal}
  <div
    class="background overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
  >
    <div class="relative w-full my-6 mx-auto max-w-3xl">
      <div class="relative p-6 flex-auto">
        <StoreAddonCrudModal
          bind:showModal
          bind:store_id
          bind:callback={addonCrudCallback}
          bind:operationType
          bind:addonData
        />
      </div>
    </div>
  </div>
  <div class="opacity-25 fixed inset-0 z-40 bg-black" />
{/if}

<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg border-b bg-gray-100"
>
  <div class="rounded-t bg-white mb-0 px-6 py-6 border-b border-gray-100">
    <div class="header flex justify-between">
      <h6 class="text-gray-800 text-xl font-bold">Addons</h6>
      <div class="flex justify-end">
        <button
          class="z-1 bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
          on:click={() => {
            showModal = !showModal;
            addonCrudCallback = createAddon;
            operationType = 'Create';
            addonData = null;
          }}>Add Addon</button
        >
      </div>
    </div>
  </div>
  <div class="merchant-table block w-full overflow-x-auto">
    {#if showAddons}
      <Datatable
        {settings}
        data={addonsToShow}
        class="items-center w-full bg-transparent border-collapse my-4"
      >
        <thead>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="name"
          >
            Addon Name
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="price"
          >
            Price
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="update"
          />
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="delete"
          />
        </thead>
        <tbody>
          {#each $rows as row, index}
            <tr>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4"
                >{row.name}</td
              >
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4"
                >{row.price}</td
              >
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4"
                ><button on:click={() => visitUpdate(row.addon_id, row, index)}>
                  <i class="fas fa-pen text-yellow-500" />
                </button></td
              >
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4"
              >
                <Confirm
                  themeColor="410"
                  confirmTitle="Delete"
                  cancelTitle="Cancel"
                  let:confirm={confirmThis}
                >
                  <button
                    on:click={() => {
                      confirmThis(deleteAddon, row.addon_id);
                    }}
                  >
                    <i class="fas fa-trash text-red-500" />
                  </button>

                  <span slot="title">
                    Are you sure, you want to delete <span class="font-semibold"
                      >{row.name}</span
                    >?
                  </span>
                  <span slot="description" class="text-sm">
                    You won't be able to revert this.
                  </span>
                </Confirm>
              </td>
            </tr>
          {/each}
        </tbody>
      </Datatable>
      <Pagination
        small
        bind:current
        per_page={limit}
        num_items={totalItems}
        on:navigate={paginate}
      />
    {:else}
      <div class="block w-full overflow-x-auto m-4">
        <div>{noAddons}</div>
      </div>
    {/if}
  </div>
</div>

<style>
  :global(.confirm-dialog) {
    padding: 30px 30px !important;
    position: fixed !important;
  }
  :global(.actions) {
    margin: 25px -30px -30px !important;
  }

  .merchant-table {
    height: 65vh;
    background-color: white;
  }
  :global(.svelte-tabs li.svelte-tabs__selected) {
    border-bottom: 2px solid #ef4544;
    color: #ef4544;
    font-weight: 600;
  }
  :global(.svelte-tabs) {
    padding: 10px;
  }
</style>
