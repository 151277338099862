<script>
  import {onMount} from 'svelte';
  import {form, bindClass} from 'svelte-forms';
  import {authToken} from '../../store/auth';
  import axios from 'axios';
  import {toast} from '@zerodevx/svelte-toast';
  import Select from 'svelte-select';
  import 'intl-tel-input/build/css/intlTelInput.css';
  import intlTelInput from 'intl-tel-input';
  import passwordValidator from 'password-validator';
  import {showSuccessMessage, showErrorMessage} from '../../utils/toast';
  import {parseAndShowErrorMessage} from '../../utils/errorParser';

  const passwordValidatorSchema = new passwordValidator();

  passwordValidatorSchema.is().min(8).is().max(50).has().not().spaces();

  let userProfileContactRef;
  let mobileNumberFormatter;
  let validationError;
  let userPrincipalsList = [];
  let userPrincipalsItems = [];
  let selectedPrincipal = [];

  let headers = {
    'X-Auth-Token': $authToken
  };

  let userProfile = {
    email: '',
    first_name: '',
    last_name: '',
    country_code: '',
    mobile: '',
    mobile_number: ''
  };

  function initPhoneValidator(element) {
    let iti = intlTelInput(element, {
      initialCountry: 'auto',
      preferredCountries: ['in', 'my'],
      nationalMode: true,
      autoPlaceholder: 'off',
      geoIpLookup: async function (success, failure) {
        const request = await fetch(
          'https://ipinfo.io/json?token=4348641a1ea714'
        );
        const resp = await request.json();
        var countryCode = resp && resp.country ? resp.country : 'my';
        success(countryCode);
      },
      utilsScript:
        'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.min.js'
    });
    iti.setNumber(userProfile.mobile_number);
    return iti;
  }

  const userProfileForm = form(
    () => ({
      email: {value: userProfile.email, validators: ['required', 'email']},
      first_name: {value: userProfile.first_name, validators: ['required']},
      last_name: {value: userProfile.last_name, validators: ['required']}
    }),
    {
      initCheck: false,
      validateOnChange: true
    }
  );

  function requestUpdateUserProfileApi(googleRecaptchaToken) {
    let payload = {
      data: {
        email: userProfile.email,
        first_name: userProfile.first_name,
        last_name: userProfile.last_name
      },
      captcha: {
        captcha_token: googleRecaptchaToken
      }
    };

    axios
      .patch(`${morrDashboard.env.API_URL}/users/profile/update`, payload, {
        headers: headers
      })
      .then(res => {
        validationError = null;

        toast.push(res.data.message, {
          theme: {
            '--toastBackground': '#48BB78',
            '--toastProgressBackground': '#2F855A'
          }
        });
      })
      .catch(err => {
        parseAndShowErrorMessage(err);
      });
  }
  function updateProfile() {
    console.log('Prepare to update profile');
    userProfileForm.validate();

    grecaptcha.ready(function () {
      grecaptcha
        .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
          action: 'SignUp'
        })
        .then(function (token) {
          if (token) {
            requestUpdateUserProfileApi(token);
          }
        });
    });
  }

  function updatePrincipal(selectedPrincipal) {
    let payload = {
      user_email: {
        email: userProfile.email
      },
      principals: selectedPrincipalsList(selectedPrincipal)
    };
    axios
      .post(
        `${morrDashboard.env.API_URL}/admin/users/permission/update`,
        payload,
        {
          headers: headers
        }
      )
      .then(res => {
        validationError = null;

        showSuccessMessage('Principal updated');
      })
      .catch(err => {
        validationError = err.response ? err.response.data.detail : err;
        showErrorMessage(validationError);
      });
  }

  function selectedPrincipalsList(selectedPrincipal) {
    let principals = [];
    if (selectedPrincipal) {
      principals = selectedPrincipal.map(function (element) {
        return `role:${element.value}`;
      });
    }

    return principals;
  }

  function getUserDetails() {
    axios
      .get(`${morrDashboard.env.API_URL}/users/`, {
        headers: headers
      })
      .then(res => {
        let userDetails = res.data.data;

        for (var key in userDetails) {
          if (
            userDetails.hasOwnProperty(key) &&
            userProfile.hasOwnProperty(key)
          ) {
            userProfile[key] = userDetails[key];
          }
        }
        if (userDetails.country_code && userDetails.mobile) {
          let userContactNumber = `+${userProfile.country_code}${userProfile.mobile}`;
          userProfile.mobile_number = userContactNumber;
        }
        // now populate the existing number
        mobileNumberFormatter = initPhoneValidator(userProfileContactRef);
        mobileNumberFormatter.setNumber(userProfile.mobile_number);
        console.log(
          'Get number',
          mobileNumberFormatter.getSelectedCountryData()
        );
      })
      .catch(err => {
        console.error('USER_PROFILE', err);
      });
  }

  function showUserPrincipalListApi() {
    axios
      .get(`${morrDashboard.env.API_URL}/admin/users/permission`, {
        headers: headers
      })
      .then(res => {
        userPrincipalsList = res.data.data;
        let principalsList = [];
        userPrincipalsList.forEach(userPrincipal => {
          principalsList.push({
            label: userPrincipal.name,
            value: userPrincipal.name
          });
        });
        userPrincipalsItems = principalsList;
      })
      .catch(err => {
        parseAndShowErrorMessage(err);
      });
  }

  onMount(() => {
    getUserDetails();
    // showUserPrincipalListApi();
  });

  function handlePrincipalSelect(event) {
    selectedPrincipal = event.detail;
  }
</script>

<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0"
>
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between">
      <h6 class="text-gray-800 text-xl font-bold">My account</h6>
    </div>
  </div>
  <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
    <form>
      <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
        Personal Information
      </h6>
      <div class="flex flex-wrap">
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              for="grid-first-name"
            >
              First Name
            </label>
            <input
              id="grid-first-name"
              type="text"
              class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
              bind:value={userProfile.first_name}
              use:bindClass={{form: userProfileForm}}
            />
            {#if $userProfileForm && $userProfileForm.fields.first_name?.errors?.includes('required')}
              <p
                class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1"
              >
                The first name is required
              </p>
            {/if}
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              for="grid-last-name"
            >
              Last Name
            </label>
            <input
              id="grid-last-name"
              type="text"
              class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
              bind:value={userProfile.last_name}
              use:bindClass={{form: userProfileForm}}
            />
            {#if $userProfileForm && $userProfileForm.fields.last_name?.errors?.includes('required')}
              <p
                class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1"
              >
                The last name is required
              </p>
            {/if}
          </div>
        </div>
      </div>

      <hr class="mt-6 border-b-1 border-gray-400" />

      <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
        Login Information
      </h6>
      <div class="flex flex-wrap">
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              for="grid-email"
            >
              Email address
            </label>
            <input
              id="grid-email"
              type="email"
              class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
              bind:value={userProfile.email}
              use:bindClass={{form: userProfileForm}}
            />
            {#if $userProfileForm && $userProfileForm.fields.email?.errors?.includes('required')}
              <p
                class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1"
              >
                The email is required
              </p>
            {/if}
            {#if $userProfileForm && $userProfileForm.fields.email?.errors?.includes('invalid')}
              <p
                class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1"
              >
                The email is invalid
              </p>
            {/if}
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              for="grid-mobile"
            >
              Contact Number (readonly)
            </label>
            <input
              id="grid-mobile"
              type="tel"
              class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
              readonly
              disabled
              bind:value={userProfile.mobile_number}
              bind:this={userProfileContactRef}
            />
          </div>
        </div>
      </div>
      <!-- <hr class="mt-6 border-b-1 border-gray-400" />

      <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
        Security Information
      </h6>
      <div class="flex flex-wrap">
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              for="grid-current-password"
            >
              Current Password
            </label>
            <input
              id="grid-current-password"
              type="password"
              class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
              bind:value={userProfile.oldPassword}
              use:bindClass={{form: userProfileForm}}
            />
            {#if $userProfileForm && $userProfileForm.fields.oldPassword?.errors?.includes('isPasswordChanged')}
              <p
                class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1"
              >
                The current password is required
              </p>
            {/if}
          </div>
        </div>

        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              for="grid-new-password"
            >
              New Password
            </label>
            <input
              id="grid-new-password"
              type="password"
              class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
              bind:value={userProfile.password}
              use:bindClass={{form: userProfileForm}}
            />
            {#if $userProfileForm && $userProfileForm.fields.password?.errors?.includes('weakPasswordValidation')}
              <p
                class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1"
              >
                The password is weak, please chose a strong password
              </p>
            {/if}
          </div>
        </div>

        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              for="grid-confirm-password"
            >
              Confirm New Password
            </label>
            <input
              id="grid-confirm-password"
              type="password"
              class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
              bind:value={userProfile.confirmPassword}
              use:bindClass={{form: userProfileForm}}
            />
            {#if $userProfileForm && $userProfileForm.fields.confirmPassword?.errors?.includes('confirmPasswordMisMatch')}
              <p
                class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1"
              >
                The confirm password does not match
              </p>
            {/if}
          </div>
        </div>
      </div>

      <hr class="mt-6 border-b-1 border-gray-400" /> -->

      <!-- <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
        User's Permission
      </h6>
      <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-gray-700 text-xs font-bold mb-2"
            for="grid-email"
          >
            Principals
          </label>
          <Select
            containerStyles="min-height: 4rem; color: rgba(113, 128, 150, 1); background-color: rgba(247, 250, 252, 1); padding: 0.5rem 1.5rem; vertical-align:middle; margin:20px"
            inputAttributes={{
              class:
                'h-16 bg-gray-100 text-grey-darker text-grey-darkest border border-gray-100 font-bold w-full py-1 px-2 outline-none text-lg text-gray-600'
            }}
            inputStyles="font-size: 1.125rem;"
            items={userPrincipalsItems}
            isMulti={true}
            placeholder="Please select Principal"
            noOptionsMessage="No principals available"
            on:select={handlePrincipalSelect}
          />
        </div>
      </div> -->

      <div class="flex flex-wrap items-center">
        <div class="w-6/12 px-4 mt-4 mx-auto">
          <button
            class="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none w-full  ease-linear transition-all duration-150"
            type="submit"
            on:click|preventDefault={() => updateProfile()}
            disabled={!$userProfileForm.valid}
          >
            Update Profile
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
