import axios from 'axios';
import {isSuperAdmin} from '../store/admin';

export function checkUserPermissions(authToken) {
  axios
    .post(`${morrDashboard.env.API_URL}/users/perms`, null, {
      headers: {'X-Auth-Token': authToken}
    })
    .then(res => {
      isSuperAdmin.set(true);
    })
    .catch(err => {
      isSuperAdmin.set(false);
    });
}
