export let merchantCategories = [
	{
		"value":"restaurant",
		"label":"Restaurant"
	},
	{
		"value":"grocery",
		"label":"Grocery"
	},
	{
		"value":"supermarket",
		"label":"Supermarket"
	},
	{
		"value":"non-halal",
		"label":"Non-Halal"
	},
	{
		"value":"halal",
		"label":"Halal"
	},
	{
		"value":"fashion",
		"label":"Fashion"
	},
	{
		"value":"home",
		"label":"Home"
	},
	{
		"value":"art",
		"label":"Art"
	},
	{
		"value":"alcohol",
		"label":"Alcohol"
	},
	{
		"value":"vape",
		"label":"Vape"
	},
	{
		"value":"pharmacy",
		"label":"Pharmacy"
	},
	{
		"value":"bakery",
		"label":"Bakery"
	},
	{
		"value":"florist",
		"label":"Florist"
	},
	{
		"value":"gift_shop",
		"label":"Gift Shop"
	},
	{
		"value":"convenience_store",
		"label":"Convenience Store"
	},
	{
		"value":"beauty",
		"label":"Beauty"
	},
	{
		"value":"lifestyle",
		"label":"Lifestyle"
	},
	{
		"value":"speciality",
		"label":"Speciality"
	},
	{
		"value":"festive",
		"label":"Festive"
	},
	{
		"value":"services",
		"label":"Services"
	},
	{
		"value":"leisure",
		"label":"Leisure"
	},
	{
		"value":"health",
		"label":"Health"
	},
	{
		"value":"wholesale",
		"label":"Wholesale"
	}
];


export let defaultMerchantLabels = [
	"In-Store Prices",
	"Save 8%",
	"Free Delivery",
	"Optional",
	"Halal",
	"Non-Halal",
	"Malaysian",
	"Chinese",
	"Asian",
	"Indian",
	"Western",
	"Beverages",
	"Dessert",
	"Save 5%",
	"Save 10%"
]
