<script>
  import {Datatable, rows} from 'svelte-simple-datatables';
  import axios from 'axios';
  import {authToken} from '../../../store/auth';
  import {onMount} from 'svelte';
  import {parseAndShowErrorMessage} from '../../../utils/errorParser';
  import {showSuccessMessage} from '../../../utils/toast';
  import HomePageRowsCrudModal from './HomePageRowsCrudModal.svelte';
  import {Confirm} from 'svelte-confirm';
  import Toggle from 'svelte-toggle';
  import {recaptchaWrapper} from '../../../utils/google-captcha';

  const settings = {
    sortable: true,
    pagination: false,
    rowPerPage: 10,
    scrollY: false,
    columnFilter: true,
    noRows: 'No entries to found',
    css: true
  };
  let baseApiURL = morrDashboard.env.API_V2_URL;

  let data;
  let currentUpdateIndex = null;
  let showModal = false;
  let color = 'light';
  let showRows = true;
  let rowsLoadingStatus = 'Loading..';
  let row_id;
  let homePageRows = [];
  let operationType;
  let rowsCrudCallback = () => {};

  function createRow(payload) {
    recaptchaWrapper('HomePageRowCreate', createRowApi, [payload]);
  }
  function createRowApi(token, payload) {
    axios
      .post(`${baseApiURL}/admin/homepage/rows/`, payload, {
        headers: {
          'X-Auth-Token': $authToken,
          'x-google-token': token
        }
      })
      .then(response => {
        showSuccessMessage('Successfully added row');
        showModal = !showModal;
        getRowsApi();
      })
      .catch(err => {
        parseAndShowErrorMessage(err);
      });
  }

  function getRowsApi() {
    axios
      .get(`${baseApiURL}/admin/homepage/rows/`, {
        headers: {
          'X-Auth-Token': $authToken
        }
      })
      .then(response => {
        homePageRows = response.data.data;
        console.log(response);
      })
      .catch(err => {
        parseAndShowErrorMessage(err);
      });
  }

  function visitUpdate(id, row, index) {
    row_id = id;
    data = row;
    currentUpdateIndex = index;
    showModal = !showModal;
    rowsCrudCallback = updateRow;
  }

  $: if (data) {
    // After Updating a Product, update local copy as well without api call, but we need to call rows api becoz if we update and delete it will not shows up the update list
    if (
      currentUpdateIndex != null &&
      data != homePageRows[currentUpdateIndex]
    ) {
      homePageRows[currentUpdateIndex] = data;
    }
  }

  function deleteRow(rowId, index) {
    recaptchaWrapper('HomepageRowDelete', deleteRowApi, [rowId, index]);
  }

  function deleteRowApi(token, rowId, index) {
    axios
      .delete(`${baseApiURL}/admin/homepage/rows/${rowId}`, {
        headers: {
          'X-Auth-Token': $authToken,
          'x-google-token': token
        }
      })
      .then(response => {
        showSuccessMessage('Successfully Deleted.');
        homePageRows.splice(index, 1);
      })
      .catch(err => {
        parseAndShowErrorMessage(err);
      });
  }

  function updateRow(payload) {
    recaptchaWrapper('HomepageRowsUpdate', updateRowApi, [payload]);
  }
  function updateRowApi(token, payload) {
    axios
      .put(`${baseApiURL}/admin/homepage/rows/${row_id}`, payload, {
        headers: {
          'X-Auth-Token': $authToken,
          'x-google-token': token
        }
      })
      .then(response => {
        showSuccessMessage('Successfully update row');
        data = response.data.data;
        showModal = !showModal;
      })
      .catch(err => {
        parseAndShowErrorMessage(err);
      });
  }

  function updateStatusOfRow(event, rowData, index) {
    recaptchaWrapper('HomepageRowsUpdate', updateStatusOfRowApi, [
      event,
      rowData,
      index
    ]);
  }
  function updateStatusOfRowApi(token, event, rowData, index) {
    if (event.type != 'click' || event.target.id.indexOf('toggle') === -1)
      return;
    let activeStatus = rowData.is_active ? false : true;
    let payload = {
      title: rowData.title,
      order: rowData.order,
      is_active: activeStatus,
      row_name: rowData.row_name,
      orientation: rowData.orientation,
      layout: rowData.layout,
      api_url: rowData.api_url
    };
    axios
      .put(`${baseApiURL}/admin/homepage/rows/${rowData.row_id}`, payload, {
        headers: {
          'X-Auth-Token': $authToken,
          'x-google-token': token
        }
      })
      .then(response => {
        showSuccessMessage('Successfully update status');
        currentUpdateIndex = index;
        data = response.data.data;
      })
      .catch(err => {
        parseAndShowErrorMessage(err);
      });
  }

  onMount(() => {
    getRowsApi();
  });
</script>

{#if showModal}
  <div
    class="background overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
  >
    <div class="relative w-full my-6 mx-auto max-w-3xl">
      <div class="relative p-6 flex-auto">
        <HomePageRowsCrudModal
          bind:showModal
          bind:rowsData={data}
          bind:operationType
          bind:callback={rowsCrudCallback}
        />
      </div>
    </div>
  </div>
  <div class="opacity-25 fixed inset-0 z-40 bg-black" />
{/if}

<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0"
>
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between p-4">
      <h6 class="text-gray-800 text-xl font-bold">Home Page Rows</h6>
      <button
        class="z-10 bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        type="button"
        style="z-index: 1;"
        on:click={() => {
          showModal = !showModal;
          data = null;
          operationType = 'create';
          row_id = null;
          rowsCrudCallback = createRow;
        }}
      >
        Add New
      </button>
    </div>
  </div>
  <div class="block w-full overflow-x-auto h-screen bg-white">
    {#if showRows}
      <Datatable
        {settings}
        data={homePageRows}
        class="items-center w-full bg-transparent border-collapse my-4"
      >
        <thead>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="title"
          >
            Title
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="order"
          >
            Order
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="is-active"
          >
            Is Active
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="row-name"
          >
            Row Name
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="orientation"
          >
            Orientation
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="layout"
          >
            Layout
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="api-url"
          >
            Url
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="edit"
          >
            <i class="fas fa-pen text-yellow-500" title="Edit" />
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="delete"
          >
            <i class="fas fa-trash text-red-500" title="Delete" />
          </th>
        </thead>
        <tbody>
          {#each $rows as row, index}
            <tr>
              <td
                class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color ===
                'light'
                  ? 'btext-gray-700'
                  : 'text-white'}"
                >{row.title}
              </td>
              <td
                class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color ===
                'light'
                  ? 'btext-gray-700'
                  : 'text-white'}"
                >{row.order}
              </td>
              <td
                class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold"
              >
                <Toggle
                  switchColor="#828282"
                  toggledColor="#e8ad90"
                  untoggledColor="#fff"
                  hideLabel
                  toggled={row.is_active}
                  on:click={e => {
                    updateStatusOfRow(e, row, index);
                  }}
                />
              </td>
              <td
                class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color ===
                'light'
                  ? 'text-gray-700'
                  : 'text-white'}"
                >{row.row_name}
              </td>
              <td
                class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color ===
                'light'
                  ? 'text-gray-700'
                  : 'text-white'}"
                >{row.orientation.toUpperCase()}
              </td>
              <td
                class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color ===
                'light'
                  ? 'btext-gray-700'
                  : 'text-white'}"
              >
                {row.layout.replace('_', ' ').toUpperCase()}
              </td>
              <td
                class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color ===
                'light'
                  ? 'btext-gray-700'
                  : 'text-white'}"
              >
                {row.api_url}
              </td>
              <td
                class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color ===
                'light'
                  ? 'btext-gray-700'
                  : 'text-white'}"
              >
                <i
                  class="fas fa-pen text-yellow-500"
                  title="Edit"
                  on:click={() => visitUpdate(row.row_id, row, index)}
                />
              </td>
              <td
                class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color ===
                'light'
                  ? 'btext-gray-700'
                  : 'text-white'}"
              >
                <Confirm
                  themeColor="410"
                  confirmTitle="Delete"
                  cancelTitle="Cancel"
                  let:confirm={confirmThis}
                >
                  <button
                    on:click={() => confirmThis(deleteRow, row.row_id, index)}
                  >
                    <i class="fas fa-trash text-red-500" />
                  </button>

                  <span slot="title">
                    Are you sure, you want to delete <span class="font-semibold"
                      >{row.title}</span
                    >?
                  </span>
                  <span slot="description" class="text-sm">
                    You won't be able to revert this.
                  </span>
                </Confirm>
              </td>
            </tr>
          {/each}
        </tbody>
      </Datatable>
    {:else}
      <div class="block w-full overflow-x-auto m-4">
        <div>{rowsLoadingStatus}</div>
      </div>
    {/if}
  </div>
</div>

<style>
  :global(.confirm-dialog) {
    padding: 30px 30px !important;
    position: fixed !important;
  }
  :global(.actions) {
    margin: 25px -30px -30px !important;
  }

  tr:nth-child(2n) {
    background-color: rgb(243, 243, 243);
  }
</style>
