<script>
  import axios from 'axios';
  import {authToken} from '../../store/auth';
  import {showErrorMessage, showSuccessMessage} from '../../utils/toast';
  import {parseAndShowErrorMessage} from '../../utils/errorParser';

  let paymentCode = null;
  let isVerifying = false;
  let validationError = null;
  let paymentDetails = null;

  function getTokenDetailsAPI(code, googleRecaptchaToken) {
    let payload = {
      captcha: {
        captcha_token: googleRecaptchaToken
      },
      payment_data: {
        payment_otp: code
      }
    };

    let headers = {
      'X-Auth-Token': $authToken
    };
    axios
      .post(
        `${morrDashboard.env.API_URL}/admin/payments/direct/details`,
        payload,
        {
          headers: headers
        }
      )
      .then(res => {
        console.log('PAY_WITH_CASH', res);
        validationError = null;
        isVerifying = false;
        paymentDetails = res.data.data;
        console.log('payment data', paymentDetails);
      })
      .catch(err => {
        parseAndShowErrorMessage(err);
      });
  }

  function verifyPaymentCode(code) {
    isVerifying = true;

    if (!code || code.toString().length < 6) {
      validationError = 'Invalid code, please enter a valid code.';
      isVerifying = false;
      showErrorMessage(validationError);
      return;
    }
    grecaptcha.ready(function () {
      grecaptcha
        .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
          action: 'PayDirect'
        })
        .then(function (token) {
          if (token) {
            getTokenDetailsAPI(code, token);
          }
        });
    });
  }

  function commitTransactionApi(action, code, googleRecaptchaToken) {
    let payload = {
      captcha: {
        captcha_token: googleRecaptchaToken
      },
      payment_data: {
        payment_otp: code
      }
    };

    let headers = {
      'X-Auth-Token': $authToken
    };
    axios
      .post(
        `${morrDashboard.env.API_URL}/admin/payments/direct/approve?action=${action}`,
        payload,
        {
          headers: headers
        }
      )
      .then(res => {
        console.log('PAY_WITH_CASH_APPROVAL', res);
        let status =
          res.data.data.status === 0
            ? 'Payment successfully approved'
            : 'Payment rejected';

        if (status === 'Payment successfully approved') {
          showSuccessMessage(status);
        } else {
          showErrorMessage(status);
        }
        validationError = null;
        paymentDetails = null;
        paymentCode = null;
      })
      .catch(err => {
        parseAndShowErrorMessage(err);
      });
  }

  function acceptOrRejectPayment(action) {
    if (!action) {
      return;
    }

    grecaptcha.ready(function () {
      grecaptcha
        .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
          action: 'PayDirect'
        })
        .then(function (token) {
          if (token) {
            commitTransactionApi(action, paymentCode, token);
          }
        });
    });
  }
</script>

<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-xl bg-gray-200 border-0"
  style="background-color: rgb(252, 240, 235);"
>
  <div class="rounded-t mb-0 px-4 py-2">
    <div class="text-center flex justify-center items-center mt-4">
      <h6 class="text-black-800 text-2xl font-bold text-center">
        Enter Code To Approve Cash Payments
      </h6>
    </div>
  </div>
  <div class="flex-auto py-4 pt-0 m-4">
    <div class="flex flex-wrap">
      <div class="w-full">
        <div class="relative w-full">
          <div class="hero flex flex-col px-0">
            <div class="search-box mx-auto my-auto w-full px-12">
              <div class="flex flex-row">
                <input
                  class="h-16 text-grey-darker text-grey-darkest font-bold w-full border-b border-black py-1 px-2 outline-none text-lg text-gray-600"
                  style="background-color:rgb(252, 240, 235)"
                  type="number"
                  placeholder="Enter Verification Code"
                  minlength="6"
                  maxlength="10"
                  bind:value={paymentCode}
                  on:change={() => verifyPaymentCode(paymentCode)}
                />
                {#if isVerifying}
                  <span
                    class="flex items-center bg-gray-100 rounded rounded-l-none border-0 px-3 font-bold text-grey-100"
                  >
                    <button
                      class="bg-gredient-dark  hover:bg-gredient-light text-lg text-dark font-bold py-3 px-6 rounded"
                    >
                      <svg
                        class="loading h-5 w-5 mr-3 bg:dark"
                        viewBox="0 0 24 24"
                      />
                    </button>
                  </span>
                {:else}
                  <span
                    class="flex items-center rounded rounded-l-none border-0 px-3 font-bold text-grey-100"
                    on:click|preventDefault={() =>
                      verifyPaymentCode(paymentCode)}
                  >
                    <button
                      class="hover:bg-gredient-light text-lg text-dark font-bold py-3 px-6 rounded m-2"
                      style="background-color:rgb(252, 240, 235)"
                    >
                      <i class="fas fa-search" />
                    </button>
                  </span>
                {/if}
              </div>

              {#if validationError}
                <p
                  class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1"
                >
                  {validationError}
                </p>
              {/if}

              {#if paymentDetails}
                <div class="flex-auto mt-4">
                  <div class="flex flex-wrap mt-2">
                    <div
                      class="relative w-full pr-4 max-w-full flex-grow flex-1"
                    >
                      <h5 class="text-gray-500 uppercase font-bold text-xs">
                        {paymentDetails?.currency || 'RM'}
                      </h5>
                      <span class="font-semibold text-xl text-gray-800">
                        {paymentDetails?.amount || '0.0'}
                      </span>
                    </div>
                    <div
                      class="text-white p-3 text-center inline-flex items-center justify-center w-24 h-12 mb-5 shadow-lg rounded-lg"
                      style="background-color: rgb(252, 195, 170);"
                    >
                      <button
                        on:click|preventDefault={() =>
                          acceptOrRejectPayment('accept')}
                        class="accept border-none outline-none text-bold"
                        style="background-color: rgb(252, 195, 170);"
                        >Approve</button
                      >
                    </div>
                    <div
                      class="text-white p-3 text-center inline-flex items-center justify-center w-24 h-12 mb-5 shadow-lg rounded-lg ml-2 -mr-1"
                      style="background-color: rgb(252, 195, 170);"
                    >
                      <button
                        on:click|preventDefault={() =>
                          acceptOrRejectPayment('reject')}
                        class="reject border-none outine-none text-bold"
                        style="background-color: rgb(252, 195, 170);"
                        >Reject</button
                      >
                    </div>
                  </div>
                </div>
              {/if}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  .loading {
    display: inline-block;
    border: 3px solid rgba(132, 202, 243, 0.3);
    border-radius: 50%;
    border-top-color: rgb(56, 143, 201);
    animation: spin 0.5s linear infinite;
    -webkit-animation: spin 0.5s linear infinite;
  }

  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
</style>
