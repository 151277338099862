<script>
  import axios from 'axios';
  import {authToken} from '../../../store/auth';
  import {showSuccessMessage, showErrorMessage} from '../../../utils/toast';
  import {onMount} from 'svelte';
  import Toggle from 'svelte-toggle';
  import {parseAndShowErrorMessage} from "../../../utils/errorParser";

  export let merchant_data;
  let operationFormField = {
    is_operating: false,
    is_delivering: false,
    is_picking: false,
    is_dine_in: false
  };

  let message;

  function updateOperationFormField() {
    (operationFormField.is_operating = merchant_data.store.is_operating
      ? merchant_data.store.is_operating
      : false),
      (operationFormField.is_delivering = merchant_data.store.is_delivering
        ? merchant_data.store.is_delivering
        : false),
      (operationFormField.is_picking = merchant_data.store.is_picking_up
        ? merchant_data.store.is_picking_up
        : false),
      (operationFormField.is_dine_in = merchant_data.store.is_dine_in
        ? merchant_data.store.is_dine_in
        : false);
  }

  onMount(() => {
    updateOperationFormField();
  });

  function postToUpdateStoreOperation(payload) {
    axios
      .put(
        `${morrDashboard.env.API_URL}/admin/merchants/${merchant_data.merchant_id}/update/store`,
        payload,
        {
          headers: {
            'x-auth-token': $authToken
          }
        }
      )
      .then(response => {
        showSuccessMessage(`${message}`);
      })
      .catch(error => {
        parseAndShowErrorMessage(error);
      });
  }

  function payloadUploadStoreStatus(status) {
    let payload = {
      delivery_fee_per_km: merchant_data.store.delivery_fee_per_km,
      store_custom_tax_rate: merchant_data.store.store_custom_tax_rate,
      is_operating: buildFormOperatingPayload(status),
      is_delivering: buildFormDeliveryPayload(status),
      is_picking_up: buildFormPickupPayload(status),
      is_custom_tax_rate_enabled:
        merchant_data.store.is_custom_tax_rate_enabled,
      is_dine_in: buildFormDineinPayload(status),
      schedule: merchant_data.store.schedule
    };
    postToUpdateStoreOperation(payload);
  }

  function buildFormDeliveryPayload(status) {
    if (status === 'Delivery') {
      if (!operationFormField.is_delivering) {
        message = `${status} is Enabled`;
      } else {
        message = `${status} is Disabled`;
      }
      return !operationFormField.is_delivering;
    }
    return operationFormField.is_delivering;
  }

  function buildFormOperatingPayload(status) {
    if (status === 'Operating') {
      if (!operationFormField.is_operating) {
        message = `${status} is Enabled`;
      } else {
        message = `${status} is Disabled`;
      }
      return !operationFormField.is_operating;
    }
    return operationFormField.is_operating;
  }

  function buildFormPickupPayload(status) {
    if (status === 'Pickup') {
      if (!operationFormField.is_picking) {
        message = `${status} is Enabled`;
      } else {
        message = `${status} is Disabled`;
      }
      return !operationFormField.is_picking;
    }
    return operationFormField.is_picking;
  }

  function buildFormDineinPayload(status) {
    if (status === 'Dine In') {
      if (!operationFormField.is_dine_in) {
        message = `${status} is Enabled`;
      } else {
        message = `${status} is Disabled`;
      }
      return !operationFormField.is_dine_in;
    }
    return operationFormField.is_dine_in;
  }
</script>

<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg border-0"
  style="background-color: rgb(245,241,234);"
>
  <div
    class="w-full rounded-t  mb-0 px-6 py-6"
    style="background-color:rgb(232,173,145)"
  >
    <h6 class="text-center text-gray-800 text-xl font-bold uppercase">
      Store Status
    </h6>
  </div>
  <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
    <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
      Delivery Status
    </h6>

    <div class="flex flex-wrap">
      <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-gray-700 text-xs font-bold mb-2"
            for="store-is-operating"
            >Delivering
          </label>

          <Toggle
            switchColor="#828282"
            toggledColor="#e8ad90"
            untoggledColor="#fff"
            hideLabel
            bind:toggled={operationFormField.is_delivering}
            on:click={() => payloadUploadStoreStatus('Delivery')}
          />
        </div>
      </div>
    </div>

    <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
      Operation Status
    </h6>

    <div class="flex flex-wrap">
      <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-gray-700 text-xs font-bold mb-2"
            for="store-is-operating"
            >Operating
          </label>

          <Toggle
            switchColor="#828282"
            toggledColor="#e8ad90"
            untoggledColor="#fff"
            hideLabel
            bind:toggled={operationFormField.is_operating}
            on:click={() => payloadUploadStoreStatus('Operating')}
          />
        </div>
      </div>
    </div>

    <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
      Pickup Status
    </h6>

    <div class="flex flex-wrap">
      <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-gray-700 text-xs font-bold mb-2"
            for="store-is-operating"
            >Pickup
          </label>

          <Toggle
            switchColor="#828282"
            toggledColor="#e8ad90"
            untoggledColor="#fff"
            hideLabel
            bind:toggled={operationFormField.is_picking}
            on:click={() => payloadUploadStoreStatus('Pickup')}
          />
        </div>
      </div>
    </div>

    <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
      Dine In Status
    </h6>

    <div class="flex flex-wrap">
      <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-gray-700 text-xs font-bold mb-2"
            for="store-is-operating"
            >Dine In
          </label>

          <Toggle
            switchColor="#828282"
            toggledColor="#e8ad90"
            untoggledColor="#fff"
            hideLabel
            bind:toggled={operationFormField.is_dine_in}
            on:click={() => payloadUploadStoreStatus('Dine In')}
          />
        </div>
      </div>
    </div>
  </div>
</div>
