import App from './App.svelte';

function loadJsfile(filename) {
  var fileref = document.createElement('script');
  fileref.setAttribute('type', 'text/javascript');
  fileref.setAttribute('src', filename);
  if (typeof fileref != 'undefined')
    document.getElementsByTagName('head')[0].appendChild(fileref);
}

let googleMapsApiUrl = `https://maps.googleapis.com/maps/api/js?key=${morrDashboard.env.GOOGLE_PLACES_API_KEY}&libraries=places&v=weekly`;

loadJsfile(googleMapsApiUrl);

const app = new App({
  target: document.getElementById('app')
});

export default app;
