<script>
  import {form, bindClass} from 'svelte-forms';
  import axios from 'axios';
  import {registerPlugin} from 'svelte-filepond';
  import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
  import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
  import {authToken} from '../../store/auth';
  import {navigateTo} from 'svelte-router-spa';
  import {showErrorMessage, showSuccessMessage} from '../../utils/toast';

  registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview
  );

  let baseApiURl = morrDashboard.env.API_URL;

  let validationError;
  let successMessage;

  let brandFormFields = {
    name: ''
  };

  const createBrandForm = form(
    () => ({
      name: {value: brandFormFields.name, validators: ['required']}
    }),
    {
      initCheck: true,
      validateOnChange: true
    }
  );

  function buildFormPayload() {
    return {
      name: brandFormFields.name
    };
  }

  function postToCreateBrandApi(payload) {
    axios
      .post(`${baseApiURl}/admin/brand/`, payload, {
        headers: {
          'X-Auth-Token': $authToken
        }
      })
      .then(response => {
        successMessage = 'Successfully created your outlet brand profile';
        showSuccessMessage(successMessage);
        navigateTo(`merchants/brands/list`);
      })
      .catch(err => {
        validationError = err.response ? err.response.data.detail : err;
        showErrorMessage(validationError);
      });
  }

  function createBrand() {
    validationError = null;

    if (!brandFormFields.name || brandFormFields.name.length < 2) {
      validationError = 'Please add valid brand name';
    }

    if (validationError) {
      showErrorMessage(validationError);
      return;
    }

    let payload = buildFormPayload();
    postToCreateBrandApi(payload);
  }
</script>

<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0"
>
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between">
      <h6 class="text-gray-800 text-xl font-bold">Add Brand</h6>
    </div>
  </div>
  <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
    <form>
      <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
        Brand Information
      </h6>

      <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-gray-700 text-xs font-bold mb-2"
            for="name"
          >
            Brand Name
          </label>
          <input
            id="name"
            type="text"
            class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
            placeholder="Please enter your Brand Name"
            bind:value={brandFormFields.name}
            use:bindClass={{form: createBrandForm, name: 'name'}}
          />
        </div>
      </div>

      <hr class="mt-6 border-b-1 border-gray-400" />
      <div class="flex flex-wrap items-center">
        <div class="w-6/12 px-4 mt-4 mx-auto">
          <button
            class="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none w-full  ease-linear transition-all duration-150"
            type="submit"
            disabled={!$createBrandForm.valid}
            on:click|preventDefault={() => createBrand()}
          >
            Add Brand
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<style>
  :global(input.invalid) {
    border: 1px solid rgb(241, 48, 48);
  }
</style>
