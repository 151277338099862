<script>
  import axios from 'axios';
  import {authToken} from '../../../store/auth';
  import {onMount} from 'svelte';
  import {parseAndShowErrorMessage} from '../../../utils/errorParser';
  import {showSuccessMessage} from '../../../utils/toast';
  import StoreAddonGroupCrud from './StoreAddonGroupCrud.svelte';
  import {recaptchaWrapper} from '../../../utils/google-captcha';

  export let store_id;

  let baseApiURL = morrDashboard.env.API_V2_URL;
  let color = 'light';
  let groups = [];
  let showGroups;
  let showModal;
  let groupsCrudCallback = () => {};
  function getGroups() {
    axios
      .get(`${baseApiURL}/admin/store/${store_id}/addons/groups/`, {
        headers: {
          'X-auth-token': $authToken
        }
      })
      .then(response => {
        groups = response.data.data;
        showGroups = true;
      })
      .catch(err => {
        console.log(err);
        // parseAndShowErrorMessage(err);
      });
  }

  function addGroup(payload) {
    recaptchaWrapper('StoreAddonGroupCreate', postToAddGroup, [payload]);
  }
  function postToAddGroup(token, payload) {
    if (!payload.name) {
      showErrorMessage("Group name can't be empty");
    } else {
      axios
        .post(`${baseApiURL}/admin/store/${store_id}/addons/group`, payload, {
          headers: {
            'X-auth-token': $authToken,
            'x-google-token': token
          }
        })
        .then(response => {
          showSuccessMessage('New Group Created');
          showModal = !showModal;
          groups = [...groups, response.data.data];
        })
        .catch(err => {
          parseAndShowErrorMessage(err);
        });
    }
  }

  onMount(() => {
    getGroups();
  });
</script>

{#if showModal}
  <div class="opacity-25 fixed inset-0 z-40 bg-black" />
  <div
    class="background overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
  >
    <div class="relative w-auto my-6 mx-auto max-w-3xl">
      <div class="relative p-6 flex-auto">
        <StoreAddonGroupCrud
          bind:showModal
          bind:callback={groupsCrudCallback}
        />
      </div>
    </div>
  </div>
  <div class="opacity-25 fixed inset-0 z-40 bg-black" />
{/if}

<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg bg-white border-0"
>
  <div class="rounded-t bg-white mb-0 px-6 py-6 border-b border-gray-100">
    <div class="header flex justify-between">
      <h6 class="text-gray-800 text-xl font-bold">Addon Groups</h6>
      <div class="flex justify-end">
        <button
          class="z-1 bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
          on:click={() => {
            showModal = true;
            groupsCrudCallback = addGroup;
          }}
        >
          Add Group
        </button>
      </div>
    </div>
  </div>
  {#if showGroups}
    {#each groups as group}
      <h6 class="text-gray-800 text-xl font-bold m-4 py-2 px-4" id={group}>
        {group.name}
      </h6>
    {/each}
  {:else}
    <div class="block w-full overflow-x-auto m-4">
      <div>{'No groups found'}</div>
    </div>
  {/if}
</div>

<style>
</style>
