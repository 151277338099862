<script>
  import axios from 'axios';
  import {authToken} from '../../store/auth';
  import {onMount} from 'svelte';

  let transactionStats = null;
  let noTransactions = 'Loading stats ...';

  function loadStats() {
    let headers = {
      'X-Auth-Token': $authToken
    };
    axios
      .get(`${morrDashboard.env.API_URL}/admin/payments/transactions/stats`, {
        headers: headers
      })
      .then(res => {
        console.log('TRANSACTION_STATS', res);
        transactionStats = res.data.data;
      })
      .catch(err => {
        console.error('TRANSACTION_STATS_ERROR', err);
        noTransactions = 'Transaction data not found';
      });
  }

  onMount(() => {
    loadStats();
  });
</script>

<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0"
>
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between">
      <h6 class="text-gray-800 text-xl font-bold">Transaction Stats</h6>
    </div>
  </div>
  <div class="flex-auto p-8 space-x-4">
    {#if transactionStats}
      <div
        class="flex columns-5xs bg-white flex-wrap w-full text-center justify-between my-2"
      >
        <div class="flex">
          <div
            class="relative flex flex-col min-w-0 break-words rounded mb-6 xl:mb-0"
          >
            <div class="flex-auto p-4">
              <div class="flex flex-wrap">
                <div class="relative w-full pr-4 max-w-full flex-grow flex-1">
                  <h5 class="text-gray-500 uppercase font-bold text-xs">
                    Total
                  </h5>
                  <span class="font-semibold text-xl text-gray-800"
                    >{transactionStats.total}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex">
          <div class="relative flex flex-col min-w-0 break-words  mb-6 xl:mb-0">
            <div class="flex-auto p-4 mr-2">
              <div class="flex flex-wrap">
                <div class="relative w-full pr-4 max-w-full flex-grow flex-1">
                  <h5 class="text-gray-500 uppercase font-bold text-xs">
                    Success
                  </h5>
                  <span class="font-semibold text-xl text-gray-800"
                    >{transactionStats.success}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex">
          <div class="relative flex flex-col min-w-0 break-words  mb-6 xl:mb-0">
            <div class="flex-auto p-4">
              <div class="flex flex-wrap">
                <div class="relative w-full pr-4 max-w-full flex-grow flex-1">
                  <h5 class="text-gray-500 uppercase font-bold text-xs">
                    Fail
                  </h5>
                  <span class="font-semibold text-xl text-gray-800"
                    >{transactionStats.failed}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex">
          <div class="relative flex flex-col min-w-0 break-words  mb-6 xl:mb-0">
            <div class="flex-auto p-4">
              <div class="flex flex-wrap">
                <div class="relative w-full pr-4 max-w-full flex-grow flex-1">
                  <h5 class="text-gray-500 uppercase font-bold text-xs">
                    Pending/ Expired
                  </h5>
                  <span class="font-semibold text-xl text-gray-800"
                    >{transactionStats.other}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex">
          <div class="relative flex flex-col min-w-0 break-words  mb-6 xl:mb-0">
            <div class="flex-auto p-4">
              <div class="flex flex-wrap">
                <div class="relative w-full pr-4 max-w-full flex-grow flex-1">
                  <h5 class="text-gray-500 uppercase font-bold text-xs">
                    Amount
                  </h5>
                  <span class="font-semibold text-xl text-gray-800"
                    >RM {transactionStats.amount_earned}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    {:else}
      <p>{noTransactions}</p>
    {/if}
  </div>
</div>
