<script>
  import {createEventDispatcher} from 'svelte';
  import UploadCSV from 'upload-csv-svelte';
  import CardFilePondUploader from '../Cards/CardFilePondUploader.svelte';

  export let store_id;
  export let showBulkUpload;

  let baseApiURL_V2 = morrDashboard.env.API_V2_URL;
  let dispatch = createEventDispatcher();
  let products;

  let ProductFileContainer;

  function uploadCallback(products) {
    dispatch('data', products);
    showBulkUpload = !showBulkUpload;
  }
</script>

<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-xl bg-gray-200 border-0"
>
  <div class="main flex-auto px-4 lg:px-4 py-2 pt-0">
    <form>
      <div class="main1 flex justify-between">
        <h6 class="text-gray-500 text-sm mt-3 mb-3 font-bold uppercase">
          Upload Products <br />(only in .csv format)
        </h6>
      </div>
      <div class="main1">
        <CardFilePondUploader
          bind:ProductFileContainer
          customFieldName="product"
          baseEndPoint={`${baseApiURL_V2}/admin/store/${store_id}/products`}
          uploadPath="/upload/bulk/validate"
          requestTimeout="100000"
          {uploadCallback}
        />
      </div>

      <div class="px-2">
        <div class="relative flex items-center justify-center w-full m-4">
          <button
            class="m-2 bg-blue-500 text-white active:bg-gray-700 text-sm font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
            type="submit"
            on:click|preventDefault={() => {
              showBulkUpload = !showBulkUpload;
            }}
          >
            Close
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<style>
  :global(input.invalid) {
    border: 1px solid rgb(241, 48, 48);
  }
</style>
