<!-- App.svelte -->
<script>
  import {Router} from 'svelte-router-spa';
  import {routes} from './routes';
  import {
    authToken,
    isLoggedIn,
    loadRouter,
    refreshToken,
    userMobileCountryCode,
    userMobileCountryCodeVerified
  } from './store/auth';

  import {serializeError} from 'serialize-error';
  import axios from 'axios';
  import {onMount} from 'svelte';
  import axiosRetry from 'axios-retry';
  import {SvelteToast} from '@zerodevx/svelte-toast';
  import {navigateTo} from 'svelte-router-spa';

  axios.defaults.withCredentials = true;

  let invalidTokens = new Set();
  let invalidAuthStatus = [401, 403, 422];
  let retryingWithRefreshToken = false;
  let stopRetries = false;

  axiosRetry(axios, {
    retries: 3, // number of retries
    retryDelay: retryCount => {
      console.log(`retry attempt: ${retryCount}`);
      if (retryCount === 1 && !stopRetries) {
        retryingWithRefreshToken = false;
      } else if (retryCount >= 3) {
        navigateTo('/auth/login');
        stopRetries = true;
      }
      return retryCount * 1000; // time interval between retries
    },
    retryCondition: error => {
      if (error.config?.url.indexOf('perms') > 0) {
        console.log('Perms api issue');
        // navigateTo('/auth/login');
       // authToken.update(() => null);
        // isLoggedIn.update(() => false);
        return;
      }
      if (stopRetries || !$isLoggedIn) {
        navigateTo('/auth/login');
        authToken.update(() => null);
        isLoggedIn.update(() => false);
        return false;
      }
      const _error = error.response ? error.response.data : error;
      const statusCode = error.response ? error.response.status : null;
      let result =
        invalidAuthStatus.indexOf(statusCode) !== -1 &&
        _error?.detail === 'Invalid credentials or expired token.';
      if (result) {
        invalidTokens.add(error.config.headers['X-Auth-Token']);
        globalApiErrorHandler(error);
      }
      error.config.headers['X-Auth-Token'] = $authToken;
      return result;
    }
  });

  const globalApiErrorHandler = error => {
    const _error = error.response ? error.response.data : error;
    const statusCode = error.response ? error.response.status : null;

    if (
      !retryingWithRefreshToken &&
      invalidAuthStatus.indexOf(statusCode) !== -1 &&
      _error.detail === 'Invalid credentials or expired token.'
    ) {
      retryingWithRefreshToken = true;
      if (!invalidTokens.has($authToken)) {
        return;
      }
      axios
        .post(`${morrDashboard.env.API_URL}/users/refresh`, null, {
          headers: {'X-Auth-Token': $refreshToken}
        })
        .then(res => {
          retryingWithRefreshToken = false;
          stopRetries = false;
          authToken.update(() => res.data.access_token);
          isLoggedIn.update(() => true);
          error.config.headers['X-Auth-Token'] = res.data.access_token;
          navigateTo('/merchants/dashboard');
        })
        .catch(err => {
          authToken.update(() => '');
          refreshToken.update(() => '');
          isLoggedIn.update(() => false);
          navigateTo('/auth/login');
        });
    }
  };

  onMount(() => {
    (async function getCountrycode() {
      let userCountryCode;
      console.log(
        'Initial Country code',
        $userMobileCountryCode,
        userCountryCode,
        $userMobileCountryCodeVerified
      );
      if (!$userMobileCountryCodeVerified) {
        console.log('Setting Country code');
        try {
          const request = await fetch(
            'https://ipinfo.io/json?token=4348641a1ea714'
          );
          const resp = await request.json();
          userCountryCode = resp && resp.country ? resp.country : 'MY';
          if (!userCountryCode) {
            userCountryCode = 'MY';
          }
          userMobileCountryCode.update(() => userCountryCode);
          userMobileCountryCodeVerified.update(() => true);
        } catch (err) {
          console.log('Unable to fetch current country code', err);
          userCountryCode = 'MY';
          userMobileCountryCode.update(() => userCountryCode);
          userMobileCountryCodeVerified.update(() => false);
        }
      }
      console.log(
        'Done setting up Country code',
        $userMobileCountryCode,
        userCountryCode,
        $userMobileCountryCodeVerified
      );
    })();

    function revalidate() {
      axios
        .post(`${morrDashboard.env.API_URL}/users/re-validate`, null, {
          headers: {'X-Auth-Token': $authToken}
        })
        .then(res => res)
        .catch(err => err);
    }
    revalidate();
    loadRouter.set(true);
  });

  let isProduction = morrDashboard.env.APP_MODE === 'production';
  if (isProduction) {
    if (!window.console) window.console = {};
    var methods = ['log', 'debug', 'warn', 'info', 'table'];
    for (var i = 0; i < methods.length; i++) {
      console[methods[i]] = function () {};
    }
  }

  let toast_options = {
    duration: 3000, // duration of progress bar tween to the `next` value
    pausable: true, // pause progress bar tween on mouse hover
    dismissable: true // allow dismiss with close button
  };
</script>

{#if $loadRouter}
  <Router {routes} />
{/if}

<SvelteToast options={toast_options} />

<style global windi:global windi:preflights:global windi:safelist:global>
  :global(.morr-background) {
    background-color: #d1193e !important;
  }
  :global(.morr-background-light) {
    background-color: rgba(232, 173, 144, 0.411) !important;
  }
  :global(.morr-background-white) {
    background-color: #f5f1ea !important;
    color: black;
  }
  :global(.iti__flag) {
    background-image: url('/img/flags.png') !important;
  }

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    :global(.iti__flag) {
      background-image: url('/img/flags@2x.png') !important;
    }
  }

  input:focus ~ label,
  input:not(:placeholder-shown) ~ label,
  textarea:focus ~ label,
  textarea:not(:placeholder-shown) ~ label,
  select:focus ~ label,
  select:not([value='']):valid ~ label,
  .iti ~ label {
    @apply transform;
    /* @apply scale-75; */
    @apply -translate-y-6;
  }

  input:focus ~ label,
  select:focus ~ label,
  .iti ~ label {
    /* font-size: 0.8rem; */
    @apply text-black;
    @apply left-0;
    @apply -translate-x-0;
  }
  input:-webkit-autofill {
    background-color: transparent !important;
  }
  :global(.selectContainer) {
    max-height: 3rem !important;
    min-height: 3rem !important;
  }
</style>
