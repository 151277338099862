<script>
  import axios from 'axios';
  import {authToken} from '../../store/auth';
  import {onMount} from 'svelte';
  import {Confirm} from 'svelte-confirm';
  import {navigateTo} from 'svelte-router-spa';
  import {showSuccessMessage, showErrorMessage} from '../../utils/toast';
  import CardProductTemplateCreate from './CardProductTemplateCreate.svelte';
  import {fade, scale} from 'svelte/transition';
  import UpdateProductTemplate from '../../views/merchant/UpdateProductTemplate.svelte';
  import {parseAndShowErrorMessage} from '../../utils/errorParser';

  export let brand_id;
  let product_template_id;
  let profileInformation;
  let recentlyAddedTemplates = [];

  let validationError = null;

  let allProductTemplate = [];
  let showProductTemplate = false;
  let isShowCreateModal = false;
  let isShowProfileModal = false;
  let isShowUpdateModal = false;
  let isShowRecentTemplates = false;
  let noProductTemplate = 'loading product template ...';
  let skip = 0;
  const LIMIT = 9;
  let total = 0;
  let next = 0;
  let templateLoading = true;

  function getProductTemplateListApi(skip) {
    let headers = {
      'X-Auth-Token': $authToken
    };
    axios
      .get(
        `${morrDashboard.env.API_URL}/admin/brand/${brand_id}/product/template?skip=${skip}&limit=${LIMIT}`,
        {
          headers: headers
        }
      )
      .then(res => {
        total = res.data.paging.total;
        next = res.data.paging.next;
        allProductTemplate = [...allProductTemplate, ...res.data.data];
        showProductTemplate = true;
        if (skip === 0) {
          showSuccessMessage('Available Product Templates');
        }
        templateLoading = false;
      })
      .catch(err => {
        console.error('ALL_Product_Template_LISTING_ERROR', err);
        showProductTemplate = false;
        noProductTemplate = 'No Product Template Found';
        parseAndShowErrorMessage(err);
      });
  }
  const handleChange = e => {
    skip = next;
    templateLoading = true;
    if (next > 0) {
      getProductTemplateListApi(skip);
    }
  };

  onMount(() => {
    getProductTemplateListApi(skip);
  });

  function addRecentlyAddedTemplates(e) {
    isShowRecentTemplates = true;
    recentlyAddedTemplates = [...recentlyAddedTemplates, e.detail];
  }

  function deleteProductTemplateAPI(
    ProductTemplateIndex,
    brand_id,
    product_template_id,
    googleRecaptchaToken
  ) {
    let headers = {
      'X-Auth-Token': $authToken
    };

    axios
      .delete(
        `${morrDashboard.env.API_URL}/admin/brand/${brand_id}/product/template/${product_template_id}`,
        {
          headers: headers,
          data: {
            captcha_token: googleRecaptchaToken
          }
        }
      )
      .then(res => {
        allProductTemplate.splice(ProductTemplateIndex, 1);
        showProductTemplate = true;

        showSuccessMessage('Successfully deleted product template');
        getProductTemplateListApi(skip);
      })
      .catch(err => {
        parseAndShowErrorMessage(err);
      });
  }

  function deleteProductTemplate(
    ProductTemplateIndex,
    brand_id,
    product_template_id
  ) {
    grecaptcha.ready(function () {
      grecaptcha
        .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
          action: 'QuickList'
        })
        .then(function (token) {
          if (token) {
            deleteProductTemplateAPI(
              ProductTemplateIndex,
              brand_id,
              product_template_id,
              token
            );
          }
        });
    });
  }

  function visitAddProductTemplate() {
    isShowCreateModal = !isShowCreateModal;
  }

  function updateModal(id, information) {
    isShowUpdateModal = !isShowUpdateModal;
    product_template_id = id;
    profileInformation = information;
  }

  function deleteRecentlyProductTemplate(productIndex) {
    recentlyAddedTemplates.splice(productIndex, 1);
    recentlyAddedTemplates = [...recentlyAddedTemplates];
  }

  function postToCreateProductTemplateApi() {
    axios
      .post(
        `${morrDashboard.env.API_URL}/admin/brand/${brand_id}/product/template`,
        recentlyAddedTemplates,
        {
          headers: {
            'X-Auth-Token': $authToken
          }
        }
      )
      .then(response => {
        showSuccessMessage('Successfully Saved product templates');
        isShowRecentTemplates = !isShowRecentTemplates;
        recentlyAddedTemplates = [];
        getProductTemplateListApi(skip);
        navigateTo(`/merchants/brands/product/template/list/${brand_id}`);
      })
      .catch(err => {
        validationError = err.response ? err.response.data.detail : err;
        showErrorMessage(validationError);
        console.error('Product_Template_CREATE_ERROR', err);
      });
  }
</script>

{#if isShowCreateModal}
  <div
    class="background overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
  >
    <div class="relative w-auto my-6 mx-auto max-w-3xl">
      <div class="relative p-6 flex-auto">
        <CardProductTemplateCreate
          on:data={addRecentlyAddedTemplates}
          {brand_id}
          bind:isShowCreateModal
        />
      </div>
    </div>
  </div>
  <div class="opacity-25 fixed inset-0 z-40 bg-black" />
{/if}

{#if isShowUpdateModal}
  <div
    class="background overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
  >
    <div class="relative w-auto my-6 mx-auto max-w-3xl">
      <div class="relative p-6 flex-auto">
        <UpdateProductTemplate
          {brand_id}
          {product_template_id}
          bind:isShowUpdateModal
        />
      </div>
    </div>
  </div>
  <div class="opacity-25 fixed inset-0 z-40 bg-black" />
{/if}

<div
  class=" flex-col min-w-0 break-words w-full mb-6 p-6 shadow-lg rounded-lg bg-white border-0"
>
  <div class="bg-white mb-4 py-6 px-4  border-b border-gray-200">
    <div class="header text-center flex justify-between">
      <h6 class="text-gray-600 text-xl font-bold">PRODUCT TEMPLATES</h6>
      <div>
        <button
          class="m-2 z-8 bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow-lg hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
          on:click={visitAddProductTemplate}
        >
          Add Product Template
        </button>
      </div>
    </div>
  </div>

  {#if isShowRecentTemplates}
    <div class="recentProducts">
      <div class="recent-header flex justify-between p-2">
        <h6 class="text-gray-800 text-xl font-bold">Recently Added products</h6>
        <div>
          <button
            class="z-10 bg-blue-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="button"
            on:click={() => postToCreateProductTemplateApi()}
          >
            Save Templates
          </button>
          <button
            class="z-10 bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="button"
            on:click={() => {
              isShowRecentTemplates = !isShowRecentTemplates;
            }}
          >
            Close
          </button>
        </div>
      </div>
      <div class="card-main">
        {#each recentlyAddedTemplates as row, index}
          <div in:scale out:fade>
            <div class="card  p-4">
              <div class="image">
                <img
                  class="inset-0 w-full object-fit"
                  src={row.image_url}
                  alt="Product_Image"
                />
              </div>
              <div class="p-4">
                <h2 class="mt-2 mb-2  font-bold">
                  {row.name}
                </h2>
                <p class="text-sm">
                  {row.description}
                </p>
                <div class="mt-3 flex items-center">
                  <span class="text-sm font-semibold">RM</span>&nbsp;<span
                    class="font-bold text-xl">{row.price}</span
                  >
                </div>
              </div>
              <div
                class="p-4 flex items-center justify-evenly text-sm text-gray-600"
              >
                <Confirm
                  themeColor="410"
                  confirmTitle="Delete"
                  cancelTitle="Cancel"
                  let:confirm={confirmThis}
                >
                  <button
                    on:click={() =>
                      confirmThis(deleteRecentlyProductTemplate, index)}
                  >
                    <i class="fas fa-trash text-red-500" />
                  </button>

                  <span slot="title">
                    Are you sure, you want to delete <span class="font-semibold"
                      >{row.name}</span
                    >?
                  </span>
                  <span slot="description" class="text-sm">
                    You won't be able to revert this.
                  </span>
                </Confirm>
              </div>
            </div>
          </div>
        {/each}
      </div>
    </div>
  {/if}

  {#if showProductTemplate}
    <div class="card-main">
      {#each allProductTemplate as row, index}
        <div class="card  p-4">
          <div class="image">
            <img
              class="inset-0 w-full object-fit"
              src={row.image_url}
              alt="Product_Image"
            />
          </div>
          <div class="p-4">
            <h2 class="mt-2 mb-2  font-bold">
              {row.name}
            </h2>
            <p class="text-sm">
              {row.description}
            </p>
            <div class="mt-3 flex items-center">
              <span class="text-sm font-semibold">RM</span>&nbsp;<span
                class="font-bold text-xl">{row.price}</span
              >
            </div>
          </div>
          <div
            class="p-4 flex items-center justify-evenly border-t border-b text-sm text-gray-600"
          >
            <span class="ml-2">
              <button
                class="active:border-0"
                on:click={() => updateModal(row.id, row)}
              >
                Update
              </button>
            </span>
            <Confirm
              themeColor="410"
              confirmTitle="Delete"
              cancelTitle="Cancel"
              let:confirm={confirmThis}
            >
              <button
                on:click={() =>
                  confirmThis(
                    deleteProductTemplate,
                    index,
                    row.brand_id,
                    row.id
                  )}
              >
                <i class="fas fa-trash text-red-500" />
              </button>

              <span slot="title">
                Are you sure, you want to delete <span class="font-semibold"
                  >{row.name}</span
                >?
              </span>
              <span slot="description" class="text-sm">
                You won't be able to revert this.
              </span>
            </Confirm>
          </div>
        </div>
      {/each}
    </div>
    {#if next > 0}
      <div class="flex flex-col justify-center items-center">
        <button
          class="m-2 bg-blue-500 text-white active:bg-gray-700 text-sm font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
          on:click={handleChange}
        >
          {#if templateLoading}Loading....
          {:else}Load More...
          {/if}</button
        >
      </div>
    {/if}
    {#if next === total || next == -1}
      <div
        class="flex flex-col justify-center items-center error block w-full overflow-x-auto m-4"
      >
        <div>No More Product Templates....</div>
      </div>
    {/if}
  {:else}
    <div class="visible block w-full overflow-x-auto m-4">
      <div><h2>{noProductTemplate}</h2></div>
    </div>
  {/if}
</div>

<style>
  :global(.confirm-dialog) {
    padding: 30px 30px !important;
    position: fixed !important;
  }
  :global(.actions) {
    margin: 25px -30px -30px !important;
  }
  .card {
    background-color: rgb(231, 221, 207);
    margin: 7px;
    border-radius: 20px;
  }
  .recentProducts {
    box-shadow: 1px 1px 1px 1px gray;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
  }

  .card-main {
    display: grid;
    grid-template-columns: 32% 32% 32%;
    align-items: center;
    justify-content: center;
  }
  .image img {
    object-fit: contain;
  }
  .image {
    width: 100%;
  }

  @media (max-width: 1100px) {
    .card-main {
      display: grid;
      grid-template-columns: 42% 42%;
      margin: 7px auto;
      align-items: center;
      justify-content: center;
    }
  }

  @media (max-width: 988px) {
    .card-main {
      display: grid;
      grid-template-columns: auto;
      margin: 7px;
      align-items: center;
      justify-content: center;
    }
  }

  @media (max-width: 768px) {
    .card-main {
      display: grid;
      grid-template-columns: 43% 43%;
      margin: 7px auto;
      align-items: center;
      justify-content: center;
    }
  }

  @media (max-width: 515px) {
    .card-main {
      display: grid;
      grid-template-columns: auto;
      margin: 7px auto;
      align-items: center;
      justify-content: center;
    }
  }

  @media (max-width: 830px) {
    .header {
      display: flex;
      flex-direction: column;
    }
  }

  @media (max-width: 940px) {
    .recent-header {
      display: flex;
      flex-direction: column;
    }
  }
</style>
