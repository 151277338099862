<script>
  import {onMount} from 'svelte';
  import {authToken} from '../../../store/auth';
  import axios from 'axios';

  import {form, bindClass} from 'svelte-forms';
  import Select from 'svelte-select';
  import {showSuccessMessage, showErrorMessage} from '../../../utils/toast';
  import {parseAndShowErrorMessage} from '../../../utils/errorParser';

  export let callback;
  export let showModal;
  export let store_id;
  export let operationType;
  export let addonData;

  let baseApiURL = morrDashboard.env.API_V2_URL;
  let groupItems = [];
  let groupList = [];
  let selectedGroupDetail = null;
  let addonFormField = {
    addon: {
      name: '',
      price: '',
      group_ids: []
    }
  };

  const addonForm = form(
    () => ({
      name: {value: addonFormField.addon.name, validators: ['required']},
      price: {value: addonFormField.addon.price, validators: ['required']}
    }),
    {
      initCheck: true,
      validateOnChange: true
    }
  );
  function getGroups() {
    axios
      .get(`${baseApiURL}/admin/store/${store_id}/addons/groups/`, {
        headers: {
          'X-auth-token': $authToken
        }
      })
      .then(response => {
        groupItems = response.data.data;
        console.log(groupItems);
        groupItems.forEach(group => {
          if (group.store_id === store_id) {
            groupList.push({label: group.name, value: group.group_id});
          }
        });
        groupList = groupList; //Due to svelte render
      })
      .catch(err => {
        console.log(err);
        // parseAndShowErrorMessage(err);
      });
  }

  function buildFormPayload() {
    return {
      name: addonFormField.addon.name,
      price: addonFormField.addon.price,
      group_ids: [...new Set(addonFormField.addon.group_ids)]
    };
  }
  function callbackToCreateAddon() {
    const payload = buildFormPayload();
    if (!payload.name) {
      showErrorMessage("Name can't be empty");
    } else if (!payload.price) {
      showErrorMessage("Price can't be empty");
    } else {
      console.log(payload);
      callback(payload);
    }
  }
  function handleGroups(e) {
    selectedGroupDetail = e.detail || [];
    selectedGroupDetail.forEach(selectedGroup => {
      addonFormField.addon.group_ids.push(selectedGroup.value);
    });
  }

  function resetGroups(e) {
    addonFormField.addon.group_ids = [];
  }

  onMount(() => {
    if (addonData) {
      let group_ids = [];
      if (addonData.groups.length > 0) {
        selectedGroupDetail = [];
      }
      addonData.groups.forEach(group => {
        selectedGroupDetail.push({label: group.name, value: group.group_id});
        group_ids.push(group.group_id);
      });
      addonFormField.addon = {
        name: addonData.name,
        price: addonData.price,
        group_ids: group_ids
      };
    }
    getGroups();
  });
</script>

<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-xl bg-gray-200 border-0"
>
  <div class="rounded-t mb-0 px-6 py-2">
    <h6 class="text-gray-800 text-lg font-bold">Addon Information</h6>
  </div>
  <div class="flex-auto px-2 lg:px-10 py-4 pt-0 my-2">
    <form>
      <div class="flex flex-col">
        <div class="flex">
          <div class="w-full px-2">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                for="addon-name"
              >
                Name
              </label>
              <input
                id="addon-name"
                type="text"
                class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                placeholder="Please enter addon"
                bind:value={addonFormField.addon.name}
                use:bindClass={{form: addonForm, name: 'name'}}
              />
            </div>
          </div>

          <div class="w-full px-2">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                for="addon-price"
              >
                Price
              </label>
              <input
                id="addon-price"
                type="text"
                class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                placeholder="Please enter price"
                bind:value={addonFormField.addon.price}
                use:bindClass={{form: addonForm, name: 'price'}}
              />
            </div>
          </div>
        </div>
        {#if groupList}
          <div class="px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                for="groups"
              >
                Groups
              </label>
              <Select
                containerStyles="min-height: 4rem; color: rgba(113, 128, 150, 1); background-color: rgba(247, 250, 252, 1); padding: 0.1rem 0.1rem; vertical-align:middle"
                inputAttributes={{
                  class:
                    'h-16 bg-gray-100 text-grey-darker text-grey-darkest border border-gray-100 font-bold w-full py-1 px-1 outline-none text-lg text-gray-600'
                }}
                inputStyles="font-size: 1.1rem;"
                value={selectedGroupDetail}
                items={groupList}
                isMulti={true}
                placeholder="Please select group"
                noOptionsMessage="No groups available"
                on:select={handleGroups}
                on:clear={resetGroups}
              />
            </div>
          </div>
        {/if}
        <div class="flex flex-row">
          <div class="w-12/12 px-4 mt-4 mx-auto">
            <button
              class="bg-gray-700 text-white active:bg-gray-800 font-bold uppercase text-xs px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 h-8"
              type="button"
              on:click|preventDefault={callbackToCreateAddon}
            >
              {operationType === 'Create' ? 'Create' : 'Update'}
            </button>
            <button
              class="bg-gray-700 text-white active:bg-gray-800 font-bold uppercase text-xs px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 h-8"
              type="button"
              on:click|preventDefault={() => {
                showModal = false;
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
