<script>
  import axios from 'axios';
  import {onMount} from 'svelte';
  import {Circle} from 'svelte-loading-spinners';
  import {authToken} from '../../store/auth';
  import Store from '../../components/Cards/Store/Store.svelte';
  import CardUpdateStore from '../../components/Cards/Store/CardUpdateStore.svelte';
  import CardUpdateOperations from '../../components/Cards/Store/CardUpdateOperations.svelte';

  export let currentRoute;

  let baseApiURl = morrDashboard.env.API_URL;
  let apiCallStatus = 'Loading...';
  let isStore;

  let merchant_id = null;
  let merchant_data = null;

  function getMerchantProfile() {
    axios
      .get(`${baseApiURl}/merchants/${merchant_id}`, {
        headers: {
          'X-Auth-Token': $authToken
        }
      })
      .then(response => {
        merchant_data = response.data.data;
        isStore = merchant_data.store_id !== null;
        console.log(merchant_data);
      })
      .catch(err => {
        apiCallStatus = 'Something went wrong.';
        console.log('ERROR OCCURED');
        console.log(err);
      });
  }

  onMount(() => {
    merchant_id = currentRoute.namedParams.merchant_id;
    getMerchantProfile();
  });
</script>

{#if merchant_data !== null}
  <div class="flex flex-wrap">
    <div class="w-full px-4">
      {#if isStore}
        <CardUpdateStore {merchant_data} />
        <CardUpdateOperations {merchant_data} />
      {:else}
        <Store bind:merchant_data bind:isStore />
      {/if}
    </div>
  </div>
{:else}
  <div class="w-full flex flex-wrap content-center justify-center">
    <div class="px-4">
      {#if apiCallStatus === 'Loading...'}
        <Circle size="60" color="#2d3748" unit="px" duration="1s" />
      {/if}
      {apiCallStatus}
    </div>
  </div>
{/if}
