<script>
  import {onMount} from 'svelte';
  import CardStoreCategory from '../../components/Cards/Store/CardStoreCategory.svelte';
  import axios from 'axios';
  import {authToken} from '../../store/auth';
  import {parseAndShowErrorMessage} from '../../utils/errorParser';

  let categories = [];
  let showCategories = false;
  let store_id;

  export let currentRoute;

  onMount(() => {
    store_id = currentRoute.namedParams.store_id;
    getCategories();
  });

  function getCategories() {
    axios
      .get(
        `${morrDashboard.env.API_URL}/admin/store/category?store_id=${store_id}`,
        {
          headers: {
            'x-auth-token': $authToken
          }
        }
      )
      .then(response => {
        categories = response.data.data;
        if (categories.length > 0) showCategories = true;
      })
      .catch(error => {
        parseAndShowErrorMessage(error);
      });
  }
</script>

{#if store_id}
  <CardStoreCategory bind:categories showCategories {store_id} />
{/if}
