<script>
  import {form, bindClass} from 'svelte-forms';
  import axios from 'axios';
  import {onMount} from 'svelte';
  import FilePond, {registerPlugin} from 'svelte-filepond';
  import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
  import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
  import {authToken} from '../../store/auth';
  import {showSuccessMessage, showErrorMessage} from '../../utils/toast';
  import {navigateTo} from 'svelte-router-spa';

  export let isShowUpdateModal;
  export let data;
  export let brand_id;
  export let product_template_id;

  registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview
  );

  let baseApiURl = morrDashboard.env.API_URL;

  let filepond_input_name = 'filepond';
  let cover_pond;

  let validationError;
  let successMessage;

  let productTemplateFormFields = {
    productTemplate: {
      product_name: '',
      image_url: [],
      description: '',
      price: 0.0,
      id: product_template_id
    }
  };

  let product_templates_images = [data.image_url]?.map(url => ({
    source: url,
    options: {type: 'local'}
  }));

  function updateProductTemplateDetails() {
    productTemplateFormFields.productTemplate.product_name = data.name
      ? data.name
      : '';
    productTemplateFormFields.productTemplate.price =
      data.price || data.price === 0
        ? data.price
        : productTemplateFormFields.productTemplate.price;
    productTemplateFormFields.productTemplate.description = data.description
      ? data.description
      : '';
    productTemplateFormFields.productTemplate.image_url =
      data.image_url?.length === 0
        ? productTemplateFormFields.productTemplate.image_url[0]
        : [data.image_url];
    productTemplateFormFields.productTemplate.id = product_template_id;
  }

  const updateProductTemplateForm = form(
    () => ({
      ProductTemplate_price: {
        value: productTemplateFormFields.productTemplate.price,
        validators: ['required']
      },
      ProductTemplate_product_name: {
        value: productTemplateFormFields.productTemplate.product_name,
        validators: ['required']
      },
      ProductTemplate_description: {
        value: productTemplateFormFields.productTemplate.description,
        validators: ['required']
      }
    }),
    {
      initCheck: true,
      validateOnChange: true
    }
  );

  function buildFormPayload() {
    return [
      {
        name: productTemplateFormFields.productTemplate.product_name,
        image_url: productTemplateFormFields.productTemplate.image_url[0],
        price: productTemplateFormFields.productTemplate.price,
        description: productTemplateFormFields.productTemplate.description,
        id: product_template_id
      }
    ];
  }

  function postToUpdateProductTemplateApi(payload) {
    axios
      .put(`${baseApiURl}/admin/brand/${brand_id}/product/template`, payload, {
        headers: {
          'X-Auth-Token': $authToken
        }
      })
      .then(response => {
        successMessage = 'Successfully updated.';
        showSuccessMessage(successMessage);
        isShowUpdateModal = !isShowUpdateModal;
        navigateTo(`/merchants/brands/product/template/list/${brand_id}`);
      })
      .catch(err => {
        validationError = err.response ? err.response.data : err;
        showErrorMessage(validationError);
      });
  }

  function updateProductTemplate() {
    let payload = buildFormPayload();
    postToUpdateProductTemplateApi(payload);
  }

  onMount(() => {
    updateProductTemplateDetails();
  });

  function getFilePondServerConfig(image_for, image_type) {
    return {
      url: `${baseApiURl}/admin/images`,
      load: async (source, load, error, progress, abort, headers) => {
        fetch(source, {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            Origin: window.location.origin
          }
        })
          .then(function (response) {
            response.blob().then(function (imageBlob) {
              load(imageBlob);
            });
          })
          .catch(function (_error) {
            error(_error);
          });
      },
      process: {
        url: '/upload',
        method: 'POST',
        withCredentials: false,
        headers: {
          'X-Auth-Token': $authToken
        },
        timeout: 7000,
        ondata: formdata => {
          formdata.append('image_for', image_for);
          formdata.append('image_type', image_type);
          return formdata;
        },
        onload: response => {
          response = JSON.parse(response);
          let file_url = response.data.file_url;
          productTemplateFormFields.productTemplate.image_url[0] = file_url;
          return file_url;
        },
        onerror: response => {
          let err = JSON.parse(response);
          validationError = (err.hasOwnProperty('detail') && err.detail) || err;
          showErrorMessage(validationError);
          return err.detail;
        }
      },
      revert: (uniqueFileId, load, error) => {
        axios
          .delete(`${baseApiURl}/admin/images/${uniqueFileId}`, {
            headers: {
              'X-Auth-Token': $authToken
            }
          })
          .then(res => {
            let elementIndex =
              productTemplateFormFields.productTemplate.image_url.indexOf(
                uniqueFileId
              );
            productTemplateFormFields.productTemplate.image_url.splice(
              elementIndex,
              1
            );
            load();
          })
          .catch(err => {
            validationError =
              (err.hasOwnProperty('detail') && err.detail) || err;
            showErrorMessage(validationError);
          });
      }
    };
  }
</script>

<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0"
>
  <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
    <form>
      <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
        Update Product Template
      </h6>

      <div class="flex flex-wrap">
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              for="productTemplate-name"
            >
              Name
            </label>
            <input
              id="productTemplate-name"
              type="text"
              class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
              placeholder="product Template Name"
              bind:value={productTemplateFormFields.productTemplate
                .product_name}
              use:bindClass={{
                form: updateProductTemplateForm,
                name: 'ProductTemplate_product_name'
              }}
            />
          </div>
        </div>

        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              for="productTemplate-price"
            >
              Price
            </label>
            <input
              id="productTemplate-price"
              type="text"
              class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
              placeholder="Product Template's price"
              bind:value={productTemplateFormFields.productTemplate.price}
              use:bindClass={{
                form: updateProductTemplateForm,
                name: 'ProductTemplate_price'
              }}
            />
          </div>
        </div>

        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              for="productTemplate-description"
            >
              Description
            </label>
            <textarea
              id="productTemplate-price"
              type="text"
              class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
              placeholder="Product Template's Description"
              bind:value={productTemplateFormFields.productTemplate.description}
              use:bindClass={{
                form: updateProductTemplateForm,
                name: 'ProductTemplate_description'
              }}
            />
          </div>
        </div>

        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              for="images"
            >
              If you want to update images, add it here
            </label>
            <FilePond
              required={true}
              bind:this={cover_pond}
              {filepond_input_name}
              server={getFilePondServerConfig('productTemplate', 'cover')}
              allowMultiple={false}
              files={product_templates_images}
            />
          </div>
        </div>
      </div>

      <button
        class="m-2 bg-blue-500 text-white active:bg-gray-700 text-sm font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
        type="submit"
        disabled={!$updateProductTemplateForm.valid}
        on:click|preventDefault={() => {
          updateProductTemplate();
        }}
      >
        Save
      </button>
      <button
        class="m-2 bg-blue-500 text-white active:bg-gray-700 text-sm font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
        type="submit"
        on:click|preventDefault={() => {
          isShowUpdateModal = !isShowUpdateModal;
        }}
      >
        Close
      </button>
    </form>
  </div>
</div>

<style>
  :global(input.invalid) {
    border: 1px solid rgb(241, 48, 48);
  }
</style>
