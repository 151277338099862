<script>
  import axios from 'axios';
  import {authToken} from '../../store/auth';
  import isEmail from 'validator/lib/isEmail';
  import Select from 'svelte-select';
  import {form, bindClass} from 'svelte-forms';
  import {toast} from '@zerodevx/svelte-toast';

  let validationError, isVerifying, managerEmail, userCheck, merchantCheck;
  let merchantList = [];
  let selectedMerchants = [];

  const managerAccessForm = form(
    () => ({
      email: {value: managerEmail, validators: ['required', 'email']},
      merchants: {value: merchantList}
    }),
    {
      initCheck: false,
      validateOnChange: true
    }
  );

  function showErrorMessage() {
    toast.push(validationError, {
      theme: {
        '--toastBackground': '#F56565',
        '--toastProgressBackground': '#C53030'
      }
    });
  }

  function listAllMerchants() {
    let headers = {
      'X-Auth-Token': $authToken
    };
    axios
      .get(`${morrDashboard.env.API_URL}/admin/merchants/merchant/list`, {
        headers: headers
      })
      .then(res => {
        validationError = null;
        isVerifying = false;
        res.data.data.forEach(merchant => {
          merchantList.push({
            label: merchant.name,
            value: merchant.merchant_id,
            group: 'default'
          });
        });
        merchantCheck = true;
        console.log('Merchat list updated', merchantList);
      })
      .catch(err => {
        const _error = err.response ? err.response.data : err;
        console.log('MERCHANT_LISTING_ERROR', _error);
        isVerifying = false;
      });
  }

  function verifyUserExistsApi(email, googleRecaptchaToken) {
    validationError = null;
    isVerifying = true;

    let payload = {
      user: {
        email: email
      },
      captcha: {
        captcha_token: googleRecaptchaToken
      }
    };

    let headers = {
      'X-Auth-Token': $authToken
    };
    axios
      .post(`${morrDashboard.env.API_URL}/admin/users/exists`, payload, {
        headers: headers
      })
      .then(res => {
        console.log('USER_CHECK', res);
        validationError = null;
        isVerifying = false;
        userCheck = res.data.message === 'user exists';
        if (userCheck && merchantList.length === 0) {
          listAllMerchants();
        }
      })
      .catch(err => {
        const _error = err.response ? err.response.data : err;
        console.log('USER_CHECK_ERROR', _error);
        isVerifying = false;
        userCheck = false;
        if (err.response && err.response.status === 422) {
          validationError = 'Unable to verify user email.';
        } else if (err.response && err.response.data) {
          validationError = err.response.data.detail;
        } else {
          validationError = 'Ooops. Something went wrong!';
        }
        showErrorMessage();
      });
  }

  function verifyManagerEmail(email) {
    if (!isEmail(email)) {
      validationError = 'Please enter a valid email';
      showErrorMessage();
      return;
    }

    grecaptcha.ready(function () {
      grecaptcha
        .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
          action: 'UserCheck'
        })
        .then(function (token) {
          if (token) {
            verifyUserExistsApi(email, token);
          }
        });
    });
  }

  function handleSelect(event) {
    selectedMerchants = event.detail || [];
  }

  function grantMerchantAccessAPI(selectedMerchants, googleRecaptchaToken) {
    validationError = null;
    isVerifying = true;

    let payload = {
      merchant_data: {
        ids: selectedMerchants.map(e => e.value),
        user_email: managerEmail
      },
      captcha: {
        captcha_token: googleRecaptchaToken
      }
    };

    let headers = {
      'X-Auth-Token': $authToken
    };
    axios
      .post(
        `${morrDashboard.env.API_URL}/admin/merchants/managers/grant-access`,
        payload,
        {
          headers: headers
        }
      )
      .then(res => {
        console.log('GRANT_ACCESS', res.data);
        validationError = null;
        isVerifying = false;
        let allowed_ids = res.data.data;

        let access_list = selectedMerchants.filter(merchant => {
          return allowed_ids.indexOf(merchant.value) !== -1;
        });
        let merchantNames = access_list
          .map(merchant => merchant.label)
          .join(', ');

        if (merchantNames) {
          merchantNames = ' to ' + merchantNames;
        }

        toast.push(`Access granted: ${merchantNames}`, {
          theme: {
            '--toastBackground': '#48BB78',
            '--toastProgressBackground': '#2F855A'
          }
        });

        managerEmail = null;
        selectedMerchants = [];
        document.querySelector('.clearSelect').click();
        document.querySelector('.selectContainer').click();
      })
      .catch(err => {
        const _error = err.response ? err.response.data : err;
        console.log('GRANT_ACCESS_ERROR', _error);
        isVerifying = false;
        if (err.response && err.response.status === 422) {
          validationError = 'Unable to verify user email.';
        } else if (err.response && err.response.data) {
          validationError = err.response.data.detail;
        } else {
          validationError = 'Ooops. Something went wrong!';
        }
        showErrorMessage();
      });
  }

  function grantMerchantAccess() {
    if (selectedMerchants.length < 1) {
      validationError = 'Please select one or more merchant outlet';
      showErrorMessage();
      return;
    }

    grecaptcha.ready(function () {
      grecaptcha
        .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
          action: 'merchantAccess'
        })
        .then(function (token) {
          if (token) {
            grantMerchantAccessAPI(selectedMerchants, token);
          }
        });
    });
  }
</script>

<div
  class="relative flex flex-col min-w-0 break-words w-full xl:w-6/12 mb-6 shadow-lg rounded-lg bg-gray-200 border-0"
>
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between">
      <h6 class="text-gray-800 text-xl font-bold">Add Manager</h6>
    </div>
  </div>
  <div class="flex-auto lg:px-4 py-10 pt-0">
    <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase" />
    <div class="flex flex-wrap">
      <div class="w-full">
        <div class="relative w-full mb-3">
          <div class="hero bg-gredient-dark h-20 flex flex-col px-0">
            <div class="search-box mx-auto my-auto w-full px-10">
              <div class="flex flex-row">
                <span
                  class="flex items-center bg-gray-100 rounded rounded-r-none border-0 px-3 font-bold text-grey-100"
                >
                  <i class="fas fa-key" />
                </span>
                <input
                  class="manager-email h-16 bg-gray-100 text-grey-darker text-grey-darkest border border-gray-100 font-bold w-full py-1 px-2 outline-none text-lg text-gray-600"
                  type="email"
                  placeholder="Enter manager's registered email with Morr"
                  bind:value={managerEmail}
                  on:change={() => verifyManagerEmail(managerEmail)}
                  use:bindClass={{form: managerAccessForm}}
                />
                {#if isVerifying}
                  <span
                    class="flex items-center bg-gray-100 rounded rounded-l-none border-0 px-3 font-bold text-grey-100"
                  >
                    <button
                      class="bg-gredient-dark  hover:bg-gredient-light text-lg text-dark font-bold py-3 px-6 rounded"
                    >
                      <svg
                        class="loading h-5 w-5 mr-3 bg:dark"
                        viewBox="0 0 24 24"
                      />
                    </button>
                  </span>
                {:else}
                  <span
                    class="flex items-center bg-gray-100 rounded rounded-l-none border-0 px-3 font-bold text-grey-100"
                    on:click|preventDefault={() =>
                      verifyManagerEmail(managerEmail)}
                  >
                    <button
                      class="bg-gredient-dark  hover:bg-gredient-light text-lg text-dark font-bold py-3 px-6 rounded"
                    >
                      <i class="fas fa-search" />
                    </button>
                  </span>
                {/if}
              </div>

              {#if validationError}
                <p
                  class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1"
                >
                  {validationError}
                </p>
              {/if}
            </div>
          </div>
          {#if merchantCheck}
            <div class="hero bg-gredient-dark h-20 flex flex-col px-0 mt-8">
              <div class="w-full">
                <Select
                  containerStyles="min-height: 4rem; color: rgba(113, 128, 150, 1); background-color: rgba(247, 250, 252, 1); padding: 0.5rem 1.5rem; vertical-align:middle"
                  inputAttributes={{
                    class:
                      'h-16 bg-gray-100 text-grey-darker text-grey-darkest border border-gray-100 font-bold w-full py-1 px-2 outline-none text-lg text-gray-600'
                  }}
                  inputStyles="font-size: 1.125rem;"
                  items={merchantList}
                  isMulti={true}
                  placeholder="Please select one or more merchant outlet"
                  noOptionsMessage="No merchants available"
                  on:select={handleSelect}
                />
              </div>
            </div>

            {#if selectedMerchants.length > 0}
              <div class="flex flex-wrap items-center">
                <div class="w-6/12 px-4 mt-4 mx-auto">
                  <button
                    class=" bg-gray-900 text-white active:bg-gray-100 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none w-full  ease-linear transition-all duration-150"
                    type="submit"
                    on:click|preventDefault={() => grantMerchantAccess()}
                  >
                    Grant Access
                  </button>
                </div>
              </div>
            {/if}
          {/if}
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  .loading {
    display: inline-block;
    border: 3px solid rgba(132, 202, 243, 0.3);
    border-radius: 50%;
    border-top-color: rgb(56, 143, 201);
    animation: spin 0.5s linear infinite;
    -webkit-animation: spin 0.5s linear infinite;
  }

  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
</style>
