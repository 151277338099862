<script>
  import CardProductTemplateCreate from 'components/Cards/CardProductTemplateCreate.svelte';
  export let currentRoute;
</script>

<div class="flex flex-wrap">
  <div class="w-full px-4">
    <CardProductTemplateCreate brand_id={currentRoute.namedParams.brand_id} />
  </div>
</div>
