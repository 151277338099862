<script>
  // core components
  import CardProductTemplateProfile from 'components/Cards/CardProductTemplateProfile.svelte';
  import {authToken} from '../../store/auth';
  import {onMount} from 'svelte';
  import {Circle} from 'svelte-loading-spinners';
  import axios from 'axios';
  import {navigateTo} from 'svelte-router-spa';
  import {showErrorMessage} from '../../utils/toast';

  let baseApiURl = morrDashboard.env.API_URL;

  let productTemplateInfo = null;
  export let product_template_id;
  export let brand_id;
  export let isShowProfileModal;

  function getProductTemplateProfile() {
    axios
      .get(
        `${baseApiURl}/brand/${brand_id}/product/template/${product_template_id}`,
        {
          headers: {
            'X-Auth-Token': $authToken
          }
        }
      )
      .then(response => {
        productTemplateInfo = response.data.data;
      })
      .catch(err => {
        console.log('ERROR OCCURED', err);
        showErrorMessage('Unable to fetch product profile');
      });
  }

  onMount(() => {
    getProductTemplateProfile();
  });

  function visitEditProductPage() {
    navigateTo(
      `merchants/brands/product/template/update/${brand_id}/${product_template_id}`
    );
  }
</script>

<main class="merchant-profile-page">
  {#if productTemplateInfo}
    <section class="relative block h-500-px">
      <button
        class="absolute top-2 right-2 z-10 bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        type="button"
        on:click={visitEditProductPage}
      >
        Edit Product Template
      </button>
      <div
        class="absolute top-0 w-full h-full bg-center bg-cover"
        style={`background-image: url("${productTemplateInfo.image_url}");`}
      >
        <span
          id="blackOverlay"
          class="w-full h-full absolute opacity-50 bg-black"
        />
      </div>
      <div
        class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
        style="transform: translateZ(0);"
      >
        <svg
          class="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            class="text-gray-300 fill-current"
            points="2560 0 2560 100 0 100"
          />
        </svg>
      </div>
    </section>
    {#if productTemplateInfo}
      <section class="information relative py-16 -mt-48">
        <div class="flex flex-wrap">
          <div class="w-full">
            <CardProductTemplateProfile
              {productTemplateInfo}
              bind:isShowUpdateModal={isShowProfileModal}
            />
          </div>
        </div>
      </section>
    {:else}
      <div
        class="w-full flex flex-wrap content-center justify-center text-center"
      >
        <Circle size="60" color="#2d3748" unit="px" duration="1s" />
      </div>
    {/if}
  {/if}
</main>
