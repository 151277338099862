import {toast} from '@zerodevx/svelte-toast';

let lastMessage = '';
let lastErrorMessage = '';

function clearMessage(_type="message"){
  if (_type === "message") {
    setTimeout(function(){
      lastMessage = '';
    },1000)
  }else if (_type === "error"){
    setTimeout(function(){
      lastErrorMessage = '';
    },1000)
  }
  
}
export const showSuccessMessage = successMessage => {
  if (lastMessage === successMessage) return
  toast.push(successMessage, {
    theme: {
      '--toastBackground': '#48BB78',
      '--toastProgressBackground': '#2F855A',
      '--toastBorderRadius': '10px'
    },

  });
  lastMessage = successMessage;
  clearMessage()
};

export const showErrorMessage = validationError => {
  if (lastErrorMessage === validationError) return
  toast.push(validationError, {
    theme: {
      '--toastBackground': '#F56565',
      '--toastProgressBackground': '#C53030',
      '--toastBorderRadius': '10px'
    },
  });
  lastErrorMessage = validationError;
  clearMessage("error")
};
