<script>
  import CardPayDirect from 'components/Cards/CardPayDirect.svelte';
  import {navigateTo} from 'svelte-router-spa';
</script>

<div>
  <div
    class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
    on:click|self={() => navigateTo('/')}
  >
    <div class="relative w-auto my-6 mx-auto max-w-3xl">
      <div class="relative p-6 flex-auto">
        <CardPayDirect />
      </div>
    </div>
  </div>
  <div class="opacity-25 fixed inset-0 z-40 bg-black" />
</div>
