<script>
  import {authToken} from '../../store/auth';
  import FilePond, {registerPlugin} from 'svelte-filepond';
  import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
  import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
  import {parseAndShowErrorMessage} from '../../utils/errorParser';
  import axios from 'axios';

  registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview
  );

  let baseApiURL = morrDashboard.env.API_URL;

  let filepondInputFieldName = 'filepond';

  let imageElementContainer;
  export let existingFiles;
  export let imagePreviewHeight = '200';
  export let allowMultiple = false;
  export let imageMultiUrlContainer = [];
  export let imageUrlContainer = '';
  export let image_for;
  export let image_type;

  export let baseEndPoint;
  export let uploadPath;
  export let customFieldName;
  export let uploadCallback;

  export let requestTimeout;

  if (!requestTimeout) {
    requestTimeout = 10000;
  }

  if (!baseEndPoint) {
    baseEndPoint = `${baseApiURL}/admin/images`;
  }
  if (!uploadPath) {
    uploadPath = `/upload`;
  }

  if (customFieldName) {
    filepondInputFieldName = customFieldName;
  }

  /*
   * @param {string} image_type
   * image_type_max_width_mapping = {
   *     "banner": (1200, 600),
   *     "cover": (1080, 500),
   *     "portrait": (600, 600),
   *     "profile": (500, 500),
   * }
   *  or custom_maxwidth_maxheight,
   *  e.g: custom_1600_900,
   *  if image width is greather than 1600, it will restrict width at 1600
   *  if image height is greather than 900 and width less than 1600, it will restrict height at 900
   *  and process image accordingly with applicable aspect ratio
   */

  let mappedFiles = existingFiles?.map(link => {
    return {
      source: link,
      options: {
        type: 'local'
      }
    };
  });

  function getFilePondServerConfig(image_for, image_type) {
    return {
      url: baseEndPoint,
      load: async (source, load, error, progress, abort, headers) => {
        console.log('Loading files', source);
        fetch(source, {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            Origin: window.location.origin
          }
        }).then(function (response) {
          response
            .blob()
            .then(function (imageBlob) {
              load(imageBlob);
            })
            .catch(function (_error) {
              error(_error);
            });
        });
      },
      process: {
        url: uploadPath,
        method: 'POST',
        withCredentials: false,
        headers: {
          'X-Auth-Token': $authToken
        },
        timeout: requestTimeout,
        ondata: formdata => {
          if (image_for && image_type) {
            formdata.append('image_for', image_for);
            formdata.append('image_type', image_type);
          }

          return formdata;
        },
        onload: response => {
          response = JSON.parse(response);
          let file_url = response.data.file_url;
          let image_id = response.data.image_id;
          let image_data = {file_url: file_url, image_id: image_id};
          imageMultiUrlContainer.push(image_data);
          imageUrlContainer = image_data;
          if (uploadCallback) {
            uploadCallback.apply(null, [response.data]);
          }

          return image_id;
        },
        onerror: response => {
          let err = JSON.parse(response);
          console.error('FAILED_TO_UPLOAD_IMAGE', err, response);
          parseAndShowErrorMessage(err);
          return err.detail;
        }
      },
      revert: async (uniqueFileId, load, error) => {
        axios
          .delete(`${morrDashboard.env.API_URL}/admin/images/${uniqueFileId}`, {
            headers: {
              'X-Auth-Token': $authToken
            }
          })
          .then(res => {
            console.log('Revert response', res);
            imageMultiUrlContainer = imageMultiUrlContainer.filter(
              item => item.image_id !== uniqueFileId
            );
            imageUrlContainer = '';
            load();
          })
          .catch(err => {
            parseAndShowErrorMessage(err);
            console.error('FAILED_TO_REMOVE_IMAGE', err);
            error(err);
          });
      }
    };
  }
</script>

<div class="image_full">
  <FilePond
    class="image_full"
    required={true}
    {allowMultiple}
    files={mappedFiles}
    bind:this={imageElementContainer}
    name={filepondInputFieldName}
    server={getFilePondServerConfig(image_for, image_type)}
    {imagePreviewHeight}
  />
</div>
