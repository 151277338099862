import {tick} from 'svelte';
import {isLoggedIn, authToken, refreshToken} from '../store/auth';

/* eslint-disable require-jsdoc */
async function setAuthCreds(
  isLoggedInValue,
  authTokenValue,
  refreshTokenValue
) {
  isLoggedIn.update(() => isLoggedInValue);
  authToken.update(() => authTokenValue);
  refreshToken.update(() => refreshTokenValue);
  await tick;
}

export default setAuthCreds;
