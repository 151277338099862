<script>
  import axios from 'axios';
  import {showSuccessMessage} from '../../../utils/toast';
  import TinyEditor from '@tinymce/tinymce-svelte';
  import {authToken} from '../../../store/auth';
  import {parseAndShowErrorMessage} from '../../../utils/errorParser';
  import {navigateTo} from 'svelte-router-spa';
  import Select from 'svelte-select';
  import minifyHTML from '../../../utils/minifyHtml';

  let name = '';
  let serviceProvider = '';
  let subject = '';
  let textBody = '';
  let htmlBody;
  let providers = ['ses'];

  function createEmailTemplateApi() {
    let payload = {
      name,
      service_provider: serviceProvider,
      subject,
      html_body: minifyHTML(htmlBody),
      text_body: textBody
    };
    axios
      .post(`${morrDashboard.env.API_URL}/admin/email-templates/`, payload, {
        headers: {
          'x-auth-token': $authToken
        }
      })
      .then(res => {
        let successMessage = 'Successfully Added Email Template';
        showSuccessMessage(successMessage);
        navigateTo('/admin/email-template/list');
      })
      .catch(err => {
        console.log('Email Template Create Err', err);
        parseAndShowErrorMessage(err);
      });
  }

  function handleSelect(e) {
    serviceProvider = e.detail.value;
  }

  let tinyConf = {
    plugins: [
      'advlist autolink lists link image charmap print preview anchor fullpage',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table paste emoticons autoresize'
    ],
    toolbar:
      'styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image emoticons | code',
    skin: 'small'
  };
</script>

<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0"
>
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between">
      <h6 class="text-gray-800 text-xl font-bold">New EmailTemplate</h6>
    </div>
  </div>

  <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
    <form>
      <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
        Template Information
      </h6>
      <div class="flex flex-wrap justify-between">
        <div class="w-8/12">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              for="merchant-title"
            >
              Template Name
            </label>
            <input
              class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
              id="name"
              type="text"
              placeholder="EmailTemplate Name"
              bind:value={name}
            />
          </div>
        </div>

        <div class="w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              for="merchant-website-button-text"
            >
              Email Service Provider
            </label>
            <Select
              containerStyles="min-height: 2.6rem; color: rgba(113, 128, 150, 1); background-color: rgba(247, 250, 252, 1); padding: 0.5rem 1.5rem; vertical-align:middle"
              inputAttributes={{
                class:
                  'h-11 bg-gray-100 text-grey-darker text-grey-darkest border border-gray-100 font-bold w-full py-1 px-2 outline-none text-lg text-gray-600'
              }}
              inputStyles="font-size: 1.125rem;"
              items={providers}
              isMulti={false}
              placeholder="Select a service provider"
              noOptionsMessage="No service providers available"
              on:select={handleSelect}
            />
          </div>
        </div>
        <div class="w-full">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              for="merchant-website-button-text"
            >
              Subject
            </label>
            <input
              class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
              id="subject"
              type="text"
              placeholder="Subject"
              bind:value={subject}
            />
          </div>
        </div>

        <div class="w-full">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              for="merchant-website-button-text"
            >
              Email Plain Text
            </label>
            <textarea
              id="text-body"
              class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
              type="text"
              rows="5"
              placeholder="Text Body"
              bind:value={textBody}
            />
          </div>
        </div>

        <div class="w-full flex flex-col items-center mb-4 p-3">
          <div class="w-full">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                for="merchant-website-button-text"
              >
                Email Html Template Source
              </label>
              <div
                class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                style="font-size: 0.8rem;"
              >
                <TinyEditor
                  apiKey={morrDashboard.env.TINYMCE_API_KEY}
                  conf={tinyConf}
                  bind:value={htmlBody}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center w-full my-3">
        <button
          class="btn w-10/12 morr-background rounded-3xl uppercase py-0 h-10 font-bold outline-none"
          type="button"
          on:click={createEmailTemplateApi}
        >
          Create Template</button
        >
      </div>
    </form>
  </div>
</div>
