const minifyHTML = function (htmlstring) {
  return window.minify(htmlstring, {
    collapseWhitespace: true,
    minifyCSS: true,
    minifyJS: true,
    keepClosingSlash: true,
    processConditionalComments: true
  });
};

export const HTMLStringParser = function (htmlString) {
  try {
    return JSON.parse(htmlString);
  } catch {
    return htmlString;
  }
};
export default minifyHTML;
