<script>
  import {onMount} from 'svelte';
  import {form, bindClass} from 'svelte-forms';
  import {authToken} from '../../../store/auth';
  import axios from 'axios';
  import 'intl-tel-input/build/css/intlTelInput.css';

  import passwordValidator from 'password-validator';
  import {showSuccessMessage, showErrorMessage} from '../../../utils/toast';
  import {parseAndShowErrorMessage} from '../../../utils/errorParser';

  const passwordValidatorSchema = new passwordValidator();

  passwordValidatorSchema.is().min(8).is().max(50).has().not().spaces();

  let passwordInputType = 'password';

  let headers = {
    'X-Auth-Token': $authToken
  };

  let userProfile = {
    password: '',
    confirmPassword: '',
    oldPassword: ''
  };

  function confirmPasswordMisMatch(value) {
    if (!userProfile.password) {
      return {
        name: 'confirmPasswordMisMatch',
        valid: true
      };
    }

    return {
      name: 'confirmPasswordMisMatch',
      valid: value === userProfile.password
    };
  }

  const weakPasswordValidation = function (value) {
    if (!value) {
      return {
        name: 'weakPasswordValidation',
        valid: true
      };
    }

    return {
      name: 'weakPasswordValidation',
      valid: passwordValidatorSchema.validate(value)
    };
  };

  const isPasswordChanged = function (value) {
    return {
      name: 'isPasswordChanged',
      valid: !(userProfile.password.length > 0 && value.length < 1)
    };
  };

  const userProfileForm = form(
    () => ({
      email: {value: userProfile.email, validators: ['required', 'email']},
      first_name: {value: userProfile.first_name, validators: ['required']},
      last_name: {value: userProfile.last_name, validators: ['required']},
      password: {
        value: userProfile.password,
        validators: [weakPasswordValidation]
      },
      confirmPassword: {
        value: userProfile.confirmPassword,
        validators: [confirmPasswordMisMatch]
      },
      oldPassword: {
        value: userProfile.oldPassword,
        validators: [isPasswordChanged]
      }
    }),
    {
      initCheck: false,
      validateOnChange: true
    }
  );

  function requestUpdateUserProfileApi(googleRecaptchaToken) {
    let payload = {
      data: {
        old_password: userProfile.oldPassword,
        new_password: userProfile.password
      },
      captcha: {
        captcha_token: googleRecaptchaToken
      }
    };

    axios
      .patch(`${morrDashboard.env.API_URL}/users/profile/update`, payload, {
        headers: headers
      })
      .then(res => {
        showSuccessMessage('Password changed successfully');
      })
      .catch(err => {
        parseAndShowErrorMessage(err);
      });
  }
  function updateProfile() {
    console.log('Prepare to update profile');
    userProfileForm.validate();

    grecaptcha.ready(function () {
      grecaptcha
        .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
          action: 'SignUp'
        })
        .then(function (token) {
          if (token) {
            requestUpdateUserProfileApi(token);
          }
        });
    });
  }

  function getUserDetails() {
    axios
      .get(`${morrDashboard.env.API_URL}/users/`, {
        headers: headers
      })
      .then(res => {
        let userDetails = res.data.data;

        for (var key in userDetails) {
          if (
            userDetails.hasOwnProperty(key) &&
            userProfile.hasOwnProperty(key)
          ) {
            userProfile[key] = userDetails[key];
          }
        }
      })
      .catch(err => {
        console.error('USER_PROFILE', err);
      });
  }

  onMount(() => {
    getUserDetails();
  });

  function showPassword() {
    passwordInputType = 'text';
  }

  function hidePassword() {
    passwordInputType = 'password';
  }
</script>

<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0"
>
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between">
      <h6 class="text-gray-800 text-xl font-bold">My account</h6>
    </div>
  </div>
  <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
    <form>
      <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
        Security Information
      </h6>
      <div class="flex flex-wrap">
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              for="grid-current-password"
            >
              Current Password
            </label>
            <input
              id="grid-current-password"
              type="password"
              class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
              bind:value={userProfile.oldPassword}
              use:bindClass={{form: userProfileForm}}
            />
            {#if $userProfileForm && $userProfileForm.fields.oldPassword?.errors?.includes('isPasswordChanged')}
              <p
                class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1"
              >
                The current password is required
              </p>
            {/if}
          </div>
        </div>

        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              for="grid-new-password"
            >
              New Password
            </label>
            <input
              id="grid-new-password"
              type="password"
              class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
              bind:value={userProfile.password}
              use:bindClass={{form: userProfileForm}}
            />
            {#if $userProfileForm && $userProfileForm.fields.password?.errors?.includes('weakPasswordValidation')}
              <p
                class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1"
              >
                The password is weak, please chose a strong password
              </p>
            {/if}
          </div>
        </div>

        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              for="grid-confirm-password"
            >
              Confirm New Password
            </label>
            <input
              id="grid-confirm-password"
              type="password"
              class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
              bind:value={userProfile.confirmPassword}
              use:bindClass={{form: userProfileForm}}
            />
            {#if $userProfileForm && $userProfileForm.fields.confirmPassword?.errors?.includes('confirmPasswordMisMatch')}
              <p
                class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1"
              >
                The confirm password does not match
              </p>
            {/if}
          </div>
        </div>
      </div>

      <hr class="mt-6 border-b-1 border-gray-400" />

      <div class="flex flex-wrap items-center">
        <div class="w-6/12 px-4 mt-4 mx-auto">
          <button
            class="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none w-full  ease-linear transition-all duration-150"
            type="submit"
            on:click|preventDefault={() => updateProfile()}
            disabled={!$userProfileForm.valid}
          >
            Change Password
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
