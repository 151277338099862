<script>
  import {Route} from 'svelte-router-spa';

  // components for this layout
  import MerchantNavbar from 'components/Navbars/MerchantNavbar.svelte';
  import MerchantSidebar from 'components/Sidebar/MerchantSidebar.svelte';
  import FooterAdmin from 'components/Footers/FooterAdmin.svelte';

  export let currentRoute;
  const params = {};
</script>

<div>
  <MerchantSidebar location={currentRoute} />
  <div class="relative md:ml-64" style="background-color:#f5f1ea">
    <MerchantNavbar />
    <div class="px-4 mt-0 pt-24 md:px-10 mx-auto w-full -m-24">
      <Route {currentRoute} {params} />
      <FooterAdmin />
    </div>
  </div>
</div>
