<script>
  import {onMount} from 'svelte';
  import {Navigate, navigateTo} from 'svelte-router-spa';
  import {form, bindClass} from 'svelte-forms';
  import axios from 'axios';
  import {showSuccessMessage} from '../../utils/toast';
  import {parseAndShowErrorMessage} from '../../utils/errorParser';
  import initPhoneValidator from '../../utils/phone-number';
  import setAuthCreds from '../../utils/auth';
  import {Circle} from 'svelte-loading-spinners';

  let code = null;
  let mobile = '';
  let otp = null;
  let counter = 120;
  let interval = null;
  let isOTPSent = false;
  let isOTPRequested = false;

  let contactHandlerElement;
  let contactHandlerInstance;
  let isMounted = false;

  $: if (mobile && isMounted) {
    let selectedCountryCode = code
      ? code
      : contactHandlerInstance.getSelectedCountryData().dialCode;
    contactHandlerInstance.setNumber(`+${selectedCountryCode}${mobile}`);
    code = selectedCountryCode;
  }

  onMount(() => {
    contactHandlerInstance = initPhoneValidator(contactHandlerElement);
    if (mobile && code) {
      contactHandlerInstance.setNumber(`+${code}${mobile}`);
    }
    isMounted = true;
    contactHandlerElement.addEventListener('countrychange', function () {
      let selectedCountryCode =
        contactHandlerInstance.getSelectedCountryData().dialCode;
      code = selectedCountryCode;
    });
  });

  function isNumeric(str) {
    if (typeof str != 'string')
      return {
        name: 'isNumeric',
        valid: false
      };
    if (!isNaN(str) && !isNaN(parseFloat(str)) && str.length == 10) {
      return {
        name: 'isNumeric',
        valid: true
      };
    }
    return {
      name: 'isNumeric',
      valid: false
    };
  }

  const otpLoginForm = form(
    () => ({
      mobile: {value: mobile, validators: ['required', isNumeric]}
    }),
    {
      initCheck: false,
      validateOnChange: true
    }
  );

  function requestOTP(googleToken) {
    isOTPRequested = true;
    let payload = {
      code,
      mobile
    };

    axios
      .post(`${morrDashboard.env.API_URL}/users/login`, payload)
      .then(res => {
        isOTPSent = true;

        // Begin the OTP countdown
        interval = setInterval(() => {
          if (counter <= 0) {
            clearInterval(interval);
          }
          counter--;
        }, 1000);

        console.log('OTP LOGIN LOG :: OTP SENT ON MAIL AND MOBILE', res);
        showSuccessMessage(res.data.message);
      })
      .catch(err => {
        console.log('OTP LOGIN LOG :: OTP NOT SENT', err.response);
        parseAndShowErrorMessage(err);
      });
  }

  function requestLogin() {
    let payload = {
      code,
      mobile,
      otp
    };

    axios
      .post(`${morrDashboard.env.API_URL}/users/verify`, payload, {
        headers: {'x-platform': 'dashboard'}
      })
      .then(res => {
        console.log('OTP LOGIN LOG :: LOGIN SUCCESSFUL', res);
        setAuthCreds(
          true,
          res.data.access_token || '',
          res.data.refresh_token || ''
        );
        showSuccessMessage('Successfully logged in');

        navigateTo('/merchants/dashboard');
      })
      .catch(err => {
        console.log('OTP LOGIN LOG :: LOGIN FAILED', err.response);
        parseAndShowErrorMessage(err);
      });
  }

  function sendOTP() {
    otpLoginForm.validate();

    if (
      $otpLoginForm &&
      $otpLoginForm.fields.mobile?.errors?.includes('required')
    ) {
      parseAndShowErrorMessage('Mobile number is required');
      return;
    }

    if (
      $otpLoginForm &&
      $otpLoginForm.fields.mobile?.errors?.includes('isNumeric')
    ) {
      parseAndShowErrorMessage('Enter valid mobile number');
      return;
    }

    grecaptcha.ready(function () {
      grecaptcha
        .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
          action: 'OTPLogin'
        })
        .then(function (token) {
          if (token) {
            requestOTP(token);
          }
        });
    });
  }

  function submitOTP() {
    if (!mobile) {
      parseAndShowErrorMessage('Enter Mobile Number');
      return;
    }

    if (!code) {
      parseAndShowErrorMessage('Enter Country Code');
      return;
    }

    if (!otp) {
      parseAndShowErrorMessage('Enter OTP');
      return;
    }
    requestLogin();
  }
</script>

<div class="main-container">
  <div class="w-11/12 sm:10/12 md:w-9/12 lg:w-7/12 xl:w-6/12">
    <form
      class="forms-container form w-full p-8 leading-10 rounded-xl filter drop-shadow-md"
    >
      <img
        class="w-16 my-6"
        src="https://morr-app.s3.ap-south-1.amazonaws.com/assets/logo/icon_logo_transparent.png"
        alt="Welcome to Morr"
        title="Welcome to Morr"
      />
      <h1 class="text-center font-bold text-xl">OTP Login</h1>
      <input
        type="hidden"
        id="g-recaptcha-response"
        name="g-recaptcha-response"
      />
      <div class="w-10/12">
        <label
          class="block uppercase text-gray-700 text-xs font-bold mb-2"
          for={`${mobile}-contact-number`}
        >
          Mobile Number
        </label>

        <input
          id={`${mobile}-contact-number`}
          type="tel"
          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
          class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
          placeholder="Please enter a valid mobile number"
          bind:value={mobile}
          on:input={() => {
            isOTPSent = false;
            isOTPRequested = false;
            otp = null;
          }}
          on:change={() => {
            code = contactHandlerInstance.getSelectedCountryData().dialCode;
          }}
          bind:this={contactHandlerElement}
        />
      </div>
      {#if $otpLoginForm && $otpLoginForm.fields.mobile?.errors?.includes('required')}
        <div class="messages w-10/12">
          <p
            class="flex font-medium tracking-wide text-red-500 text-xs mt-1 ml-1"
          >
            * The mobile is required
          </p>
        </div>
      {/if}
      {#if $otpLoginForm && $otpLoginForm.fields.mobile?.errors?.includes('isNumeric')}
        <div class="messages w-10/12">
          <p
            class="flex font-medium tracking-wide text-red-500 text-xs mt-1 ml-1"
          >
            * The mobile is invalid!
          </p>
        </div>
      {/if}
      {#if isOTPSent}
        <div class="w-10/12 my-6">
          <label
            class="block uppercase text-gray-700 text-xs font-bold mb-2"
            for={'otp-number'}
          >
            OTP
          </label>

          <input
            id={'otp-number'}
            type="number"
            pattern="[0-9]{6}"
            class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
            placeholder="Please enter an otp"
            bind:value={otp}
          />
        </div>
      {/if}

      {#if !isOTPSent}
        <div class="text-center w-full my-3">
          <button
            class="btn w-10/12 morr-background rounded-3xl uppercase py-0 h-10 font-bold outline-none"
            type="button"
            on:click={() => sendOTP()}
            disabled={isOTPRequested}
          >
            {#if isOTPRequested}
              <div class="spinner-progress">
                <Circle size="18" color="#2d3748" unit="px" duration="1s" />
                &nbsp; Sending OTP
              </div>
            {:else if !isOTPRequested}
              Send OTP
            {/if}
          </button>
        </div>
      {:else}
        <div class="text-center w-full my-3">
          <button
            class="btn w-10/12 morr-background rounded-3xl uppercase py-0 h-10 font-bold outline-none"
            type="button"
            on:click={() => {
              submitOTP();
            }}
          >
            Login
          </button>
        </div>
        <div class="text-center w-full my-3">
          <button
            class="btn w-10/12 morr-background rounded-3xl uppercase py-0 h-10 font-bold outline-none"
            type="button"
            on:click={() => {
              counter = 120;
              sendOTP();
            }}
            disabled={counter > 0}
          >
            Resend OTP {counter <= 0 ? '' : 'In ' + counter + ' seconds'}
          </button>
        </div>
      {/if}
      <div
        class="flex relative mt-4 w-full z-0 justify-between px-16 text-lg font-normal text-black"
      >
        <div class="space-x-2 inline-block">
          <Navigate to="/auth/login">
            <small>Login with password?</small>
          </Navigate>
        </div>
        <div class="space-x-2 inline-block">
          <Navigate to="/auth/register">
            <small>Create new account</small>
          </Navigate>
        </div>
      </div>
    </form>
  </div>
</div>

<style>
  .messages {
    display: flex;
    justify-content: flex-start;
  }

  .main-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .check {
    display: flex;
    justify-content: flex-start;
  }
  .form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }

  .forms-container {
    background-color: #f5f1ea;
    border-radius: 10px;
    overflow: hidden;
    z-index: 2;
  }

  .form input {
    outline: none;
    border: none;
    border-bottom: 1px solid #b3b1b140;
    background: transparent;
  }

  .form-group {
    display: block;
  }

  .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  .form-group label {
    position: relative;
    cursor: pointer;
  }

  .form-group label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #d1193e;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
  }

  .form-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid #d1193e;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  :global(.spinner-progress div) {
    display: inline-block;
    vertical-align: middle;
  }
</style>
