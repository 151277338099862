<script>
  import {Datatable, rows} from 'svelte-simple-datatables';
  import axios from 'axios';
  import {authToken} from '../../../store/auth';
  import {onMount} from 'svelte';
  import {parseAndShowErrorMessage} from '../../../utils/errorParser';
  import {showSuccessMessage} from '../../../utils/toast';
  import {recaptchaWrapper} from '../../../utils/google-captcha';
  import PromoBannerCrudModal from './PromoBannerCrudModal.svelte';
  import {Confirm} from 'svelte-confirm';
  import Toggle from 'svelte-toggle';

  const settings = {
    sortable: true,
    pagination: false,
    rowPerPage: 10,
    scrollY: false,
    columnFilter: true,
    noRows: 'No entries to found',
    css: true
  };
  let baseApiURL = morrDashboard.env.API_V2_URL;

  let data;
  let currentUpdateIndex = null;
  let showModal = false;
  let color = 'light';
  let showPromoBanners = true;
  let promoBannersLoadingStatus = 'Loading..';
  let promoBannerId;
  let promoBanners = [];
  let operationType;
  let promoBannerCrudCallback = () => {};

  function getPromoBannersApi() {
    axios
      .get(`${baseApiURL}/admin/homepage/promo-banners/`, {
        headers: {
          'X-Auth-Token': $authToken
        }
      })
      .then(response => {
        promoBanners = response.data.data;
        console.log('promoBanners listing', promoBanners);
      })
      .catch(err => {
        parseAndShowErrorMessage(err);
      });
  }

  function createPromoBanner(payload) {
    recaptchaWrapper('promoBanner', createPromoBannerApi, [payload]);
  }

  function createPromoBannerApi(token, payload) {
    axios
      .post(`${baseApiURL}/admin/homepage/promo-banners/`, payload, {
        headers: {
          'X-Auth-Token': $authToken,
          'x-google-token': token
        }
      })
      .then(response => {
        showSuccessMessage('Successfully added promo banner');
        showModal = !showModal;
        data = response.data.data;
        getPromoBannersApi();
      })
      .catch(err => {
        parseAndShowErrorMessage(err);
      });
  }

  function updatePromoBanner(payload) {
    recaptchaWrapper('promoBanner', updatePromoBannerApi, [payload]);
  }

  function updatePromoBannerApi(token, payload) {
    axios
      .patch(
        `${baseApiURL}/admin/homepage/promo-banners/${promoBannerId}`,
        payload,
        {
          headers: {
            'X-Auth-Token': $authToken,
            'x-google-token': token
          }
        }
      )
      .then(response => {
        showSuccessMessage('Successfully update promo-banner');
        data = response.data.data;
        showModal = !showModal;
      })
      .catch(err => {
        parseAndShowErrorMessage(err);
      });
  }

  function visitUpdate(id, promoBanner, index) {
    promoBannerId = id;
    data = promoBanner;
    currentUpdateIndex = index;
    showModal = !showModal;
    operationType = 'Update';
    promoBannerCrudCallback = updatePromoBanner;
  }

  $: if (data) {
    // After Updating a promo-banner, update local copy as well without api call, but we need to call promo-banners api because if we update and delete it will not shows up the update list
    if (
      currentUpdateIndex != null &&
      data != promoBanners[currentUpdateIndex]
    ) {
      promoBanners[currentUpdateIndex] = data;
    }
  }

  function updateStatusOfPromoBanner(event, promoBanner, index) {
    recaptchaWrapper('promoBanner', updateStatusOfPromoBannerApi, [
      event,
      promoBanner,
      index
    ]);
  }

  function updateStatusOfPromoBannerApi(token, event, promoBanner, index) {
    if (event.type != 'click' || event.target.id.indexOf('toggle') === -1)
      return;
    let activeStatus = promoBanner.is_listed ? false : true;
    let payload = {
      title: promoBanner.title,
      description: promoBanner.description,
      thumbnail: promoBanner.thumbnail,
      order: promoBanner.order,
      link_type: promoBanner.link_type,
      link_destination: promoBanner.link_destination,
      is_listed: activeStatus
    };
    axios
      .patch(
        `${baseApiURL}/admin/homepage/promo-banners/${promoBanner.promo_id}`,
        payload,
        {
          headers: {
            'X-Auth-Token': $authToken,
            'x-google-token': token
          }
        }
      )
      .then(response => {
        showSuccessMessage('Successfully update status');
        currentUpdateIndex = index;
        data = response.data.data;
      })
      .catch(err => {
        parseAndShowErrorMessage(err);
      });
  }

  function deletePromoBanner(promoBannerId, index) {
    recaptchaWrapper('promoBanner', deletePromoBannerApi, [
      promoBannerId,
      index
    ]);
  }

  function deletePromoBannerApi(token, promoBannerId, index) {
    axios
      .delete(`${baseApiURL}/admin/homepage/promo-banners/${promoBannerId}`, {
        headers: {
          'X-Auth-Token': $authToken,
          'x-google-token': token
        }
      })
      .then(response => {
        showSuccessMessage('Successfully Deleted.');
        promoBanners.splice(index, 1);
      })
      .catch(err => {
        parseAndShowErrorMessage(err);
      });
  }

  onMount(() => {
    getPromoBannersApi();
  });
</script>

{#if showModal}
  <div
    class="background overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
  >
    <div class="relative w-full my-6 mx-auto max-w-3xl">
      <div class="relative p-6 flex-auto">
        <PromoBannerCrudModal
          bind:showModal
          bind:operationType
          bind:promoBannerData={data}
          callback={promoBannerCrudCallback}
        />
      </div>
    </div>
  </div>
  <div class="opacity-25 fixed inset-0 z-40 bg-black" />
{/if}

<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0"
>
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between p-4">
      <h6 class="text-gray-800 text-xl font-bold">Promo Banners</h6>
      <button
        class="z-10 bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        type="button"
        style="z-index: 1;"
        on:click={() => {
          showModal = !showModal;
          operationType = 'Create';
          data = null;
          promoBannerId = null;
          promoBannerCrudCallback = createPromoBanner;
        }}
      >
        Add New
      </button>
    </div>
  </div>

  <div class="block w-full overflow-x-auto h-screen bg-white">
    {#if showPromoBanners}
      <Datatable
        {settings}
        data={promoBanners}
        class="items-center w-full bg-transparent border-collapse my-4"
      >
        <thead>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="title"
          >
            Title
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="order"
          >
            Order
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="is-listed"
          >
            Is Listed
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="description"
          >
            Description
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="link-type"
          >
            Link Type
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="link-destination"
          >
            Link Destination
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="link-type"
          >
            Active Tab
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="edit"
          >
            <i class="fas fa-pen text-yellow-500" title="Edit" />
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="delete"
          >
            <i class="fas fa-trash text-red-500" title="Delete" />
          </th>
        </thead>
        <tbody>
          {#each $rows as promoBanner, index}
            <tr>
              <td
                class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color ===
                'light'
                  ? 'btext-gray-700'
                  : 'text-white'}"
              >
                <span class="flex flex-col items-center">
                  <img
                    src={promoBanner.thumbnail
                      ? promoBanner.thumbnail
                      : '../assets/img/res-default.ico'}
                    class="h-20 w-26 bg-white border inline m-2"
                    alt={promoBanner.title}
                  />
                  <span
                    class="font-bold {color === 'light'
                      ? 'text-gray-700'
                      : 'text-white'}"
                  >
                    {promoBanner.title}
                  </span>
                </span>
              </td>
              <td
                class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color ===
                'light'
                  ? 'btext-gray-700'
                  : 'text-white'}"
                >{promoBanner.order}
              </td>
              <td
                class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold"
              >
                <Toggle
                  switchColor="#828282"
                  toggledColor="#e8ad90"
                  untoggledColor="#fff"
                  hideLabel
                  toggled={promoBanner.is_listed}
                  on:click={e => {
                    updateStatusOfPromoBanner(e, promoBanner, index);
                  }}
                />
              </td>
              <td
                class="border-t-0 w-1/4 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color ===
                'light'
                  ? 'text-gray-700'
                  : 'text-white'}"
                >{promoBanner.description}
              </td>
              <td
                class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color ===
                'light'
                  ? 'btext-gray-700'
                  : 'text-white'}"
              >
                {promoBanner.link_type ? promoBanner.link_type : 'N/A'}
              </td>
              <td
                class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color ===
                'light'
                  ? 'btext-gray-700'
                  : 'text-white'}"
              >
                {promoBanner.link_destination
                  ? promoBanner.link_destination
                  : 'N/A'}
              </td>
              <td
                class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color ===
                'light'
                  ? 'btext-gray-700'
                  : 'text-white'}"
              >
                {promoBanner.active_tab ? promoBanner.active_tab : 'N/A'}
              </td>
              <td
                class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color ===
                'light'
                  ? 'btext-gray-700'
                  : 'text-white'}"
              >
                <i
                  class="fas fa-pen text-yellow-500"
                  title="Edit"
                  on:click={() =>
                    visitUpdate(promoBanner.promo_id, promoBanner, index)}
                />
              </td>
              <td
                class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color ===
                'light'
                  ? 'btext-gray-700'
                  : 'text-white'}"
              >
                <Confirm
                  themeColor="410"
                  confirmTitle="Delete"
                  cancelTitle="Cancel"
                  let:confirm={confirmThis}
                >
                  <button
                    on:click={() =>
                      confirmThis(
                        deletePromoBanner,
                        promoBanner.promo_id,
                        index
                      )}
                  >
                    <i class="fas fa-trash text-red-500" />
                  </button>

                  <span slot="title">
                    Are you sure, you want to delete <span class="font-semibold"
                      >{promoBanner.title}</span
                    >?
                  </span>
                  <span slot="description" class="text-sm">
                    You won't be able to revert this.
                  </span>
                </Confirm>
              </td>
            </tr>
          {/each}
        </tbody>
      </Datatable>
    {:else}
      <div class="block w-full overflow-x-auto m-4">
        <div>{promoBannersLoadingStatus}</div>
      </div>
    {/if}
  </div>
</div>

<style>
  :global(.confirm-dialog) {
    padding: 30px 30px !important;
    position: fixed !important;
  }
  :global(.actions) {
    margin: 25px -30px -30px !important;
  }

  tr:nth-child(2n) {
    background-color: rgb(243, 243, 243);
  }
</style>
