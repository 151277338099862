export const orderStatus = {
  IN_PROGRESS: 'IN_PROGRESS',
  READY: 'READY',
  OUT_FOR_DELIVERY: 'OUT_FOR_DELIVERY',
  MERCHANT_RESOLVED: 'MERCHANT_RESOLVED',
  CUSTOMER_RESOLVED: 'CUSTOMER_RESOLVED',
  ORDER_PLACED: 'ORDER_PLACED',
  PAYMENT_PENDING: 'PAYMENT_PENDING',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  REJECTED: 'REJECTED',
  DELIVERY_FAILED: 'DELIVERY_FAILED'
};
