<script>
  import {Datatable, rows} from 'svelte-simple-datatables';
  import axios from 'axios';
  import {authToken} from '../../store/auth';
  import {onMount} from 'svelte';
  import {Confirm} from 'svelte-confirm';
  import {Navigate} from 'svelte-router-spa';
  import {parseAndShowErrorMessage} from '../../utils/errorParser';
  import Pagination from '@fouita/pagination';

  const settings = {
    sortable: true,
    pagination: false,
    rowPerPage: 10,
    scrollY: false,
    columnFilter: true,
    noRows: 'No entries to found',
    css: true
  };
  const limit = 10;

  let current = 1;
  let skip = 0;
  let totalItems = 0;
  let allBrands = [];
  let showBrands = false;
  let noBrands = 'loading Brands ...';


  function getBrandListApi(skip) {
    let headers = {
      'X-Auth-Token': $authToken
    };
    axios

      .get(`${morrDashboard.env.API_URL}/admin/brand/?skip=${skip}&limit=${limit}`, {

        headers: headers
      })
      .then(res => {
        console.log('ALL_BRANDS_LISTING', res);
        allBrands = res.data.data;
        totalItems = res.data.paging.total;
        showBrands = true;
      })
      .catch(err => {
        console.error('ALL_BRANDS_LISTING_ERROR', err);
        showBrands = false;
        noBrands = 'Brands not found';
        parseAndShowErrorMessage(err);
      });
  }

  function paginate(e) {
    if (current === 1) {
     getBrandListApi(0);
    } else if (current > 1) {
      skip = limit * (current - 1);
      getBrandListApi(skip);
    }
  }


  onMount(() => {
    getBrandListApi(0);
  });

  let color = 'light';
</script>

<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0"
>
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between">
      <h6 class="text-gray-800 text-xl font-bold">Brands</h6>
    </div>
  </div>
  <div class="brand-table block w-full overflow-x-auto">
    {#if showBrands}
      <Datatable
        {settings}
        data={allBrands}
        class="items-center w-full bg-transparent border-collapse my-4"
      >
        <thead>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="name"
          >
            Brand Name
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="name"
          >
            Product Template
          </th>
        </thead>
        <tbody>
          {#each $rows as row, index}
            <tr>
              <td
                class="border-t-0 px-6 border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-center flex flex-col items-center align-middle font-thin"
              >
                <span class="flex flex-row">
                  <span
                    class="ml-3 font-bold {color === 'light'
                      ? 'btext-gray-700'
                      : 'text-whit'}"
                  >
                    {row.name}
                  </span>
                </span>
              </td>
              <td>
                <span
                  class="ml-3 font-bold {color === 'light'
                    ? 'btext-gray-700'
                    : 'text-whit'}"
                >
                  <Navigate
                    to={`/merchants/brands/product/template/list/${row.id}`}
                    styles="text-xs uppercase  font-bold block text-center"
                  >
                    <i class="fas fa-folder-open text-blue-1000" />
                  </Navigate>
                </span>
              </td>
            </tr>
          {/each}
        </tbody>
      </Datatable>
      <Pagination
        small
        bind:current
        per_page={limit}
        num_items={totalItems}
        on:navigate={paginate}
      />
    {:else}
      <div class="block w-full overflow-x-auto m-4">
        <div>{noBrands}</div>
      </div>
    {/if}
  </div>
</div>

<style>
  :global(.confirm-dialog) {
    padding: 30px 30px !important;
    position: fixed !important;
  }
  :global(.actions) {
    margin: 25px -30px -30px !important;
  }

  .brand-table {
    height: 65vh;
    background-color: white;
  }

  tr:nth-child(2n) {
    background-color: rgb(243, 243, 243);
  }
</style>
