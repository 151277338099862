export let linkType = [
    {
      label: 'Merchant Profile',
      value: 'merchant_profile'
    },
    {
      label: 'Store Product Listing',
      value: 'store_product_listing'
    },
    {
      label: 'Merchant Listing by Category',
      value: 'merchant_listing_by_category'
    },
    {
      label: 'Merchant Listing by Labels',
      value: 'merchant_listing_by_labels'
    },
    {
      label: 'Restaurant Listing',
      value: 'restaurant_listing'
    },
    {
        label: 'Essentials',
        value: 'essentials'
    },
  ];