<script>
  import axios from 'axios';
  import {authToken} from '../../store/auth';
  import Select from 'svelte-select';
  import {onMount} from 'svelte';
  import {showErrorMessage, showSuccessMessage} from '../../utils/toast';
  import {parseAndShowErrorMessage} from '../../utils/errorParser';

  let merchantItems = [];
  let brandItems = [];
  let merchantList = [];
  let brandList = [];
  let selectedMerchant = [];
  let selectedBrand = [];
  let revokeSelectedBrand = [];
  let revokeSelectedMerchant = [];
  let merchant_id;
  let brand_id;
  let revoke_brand_id;
  let revoke_merchant_id;
  let validationError;
  let successMessage;

  function grantMerchantToBrandAccessApi() {
    validationError = null;
    if (!brand_id) {
      showErrorMessage('Please Select Brand!');
    } else if (!merchant_id) {
      showErrorMessage('Please Select Merchant!');
    } else {
      let payload = {
        brand_id: brand_id,
        merchant_id: merchant_id
      };
      let headers = {
        'X-Auth-Token': $authToken
      };
      axios
        .post(
          `${morrDashboard.env.API_URL}/admin/brand/${brand_id}/grant/access/merchant/${merchant_id}`,
          payload,
          {
            headers: headers
          }
        )
        .then(res => {
          successMessage = `Access granted: ${selectedMerchant.label} to ${selectedBrand.label}`;
          showSuccessMessage(successMessage);
        })
        .catch(err => {
          console.log(err);
          parseAndShowErrorMessage(err);
        });
    }
  }

  function revokeMerchantToBrandAccessApi() {
    validationError = null;
    if (!revoke_brand_id) {
      showErrorMessage('Please Select Brand!');
    } else if (!revoke_merchant_id) {
      showErrorMessage('Please Select Merchant!');
    } else {
      let headers = {
        'X-Auth-Token': $authToken
      };
      axios
        .delete(
          `${morrDashboard.env.API_URL}/admin/brand/${revoke_brand_id}/revoke/access/merchant/${revoke_merchant_id}`,
          {
            headers: headers
          }
        )
        .then(res => {
          successMessage = `Access revoked: ${revokeSelectedMerchant.label} from ${revokeSelectedBrand.label}`;
          showSuccessMessage(successMessage);
        })
        .catch(err => {
          parseAndShowErrorMessage(err);
        });
    }
  }

  function showMerchantListApi() {
    let headers = {
      'X-Auth-Token': $authToken
    };
    axios
      .get(`${morrDashboard.env.API_URL}/admin/merchants/merchant/list`, {
        headers: headers
      })
      .then(res => {
        merchantList = res.data.data;
        let merchantItemsList = [];
        merchantList.forEach(merchant => {
          merchantItemsList.push({
            label: merchant.name,
            value: merchant.merchant_id
          });
        });
        merchantItems = merchantItemsList;
      })
      .catch(err => {
        parseAndShowErrorMessage(err);
      });
  }

  function showBrandListApi() {
    let headers = {
      'X-Auth-Token': $authToken
    };
    axios
      .get(`${morrDashboard.env.API_URL}/admin/merchants/brand/list`, {
        headers: headers
      })
      .then(res => {
        brandList = res.data.data;
        let brandItemsList = [];
        brandList.forEach(brand => {
          brandItemsList.push({
            label: brand.name,
            value: brand.brand_id
          });
        });
        brandItems = brandItemsList;
      })
      .catch(err => {
        parseAndShowErrorMessage(err);
      });
  }

  onMount(() => {
    showBrandListApi();
    showMerchantListApi();
  });

  function handleMerchantSelect(event) {
    selectedMerchant = event.detail || [];
    merchant_id = selectedMerchant.value;
  }

  function handleMerchantReset(event) {
    merchant_id = null;
  }

  function handleBrandSelect(event) {
    selectedBrand = event.detail || [];
    brand_id = selectedBrand.value;
  }

  function handleBrandReset(event) {
    brand_id = null;
  }

  function handleRevokeBrandSelect(event) {
    revokeSelectedBrand = event.detail || [];
    revoke_brand_id = revokeSelectedBrand.value;
  }

  function handleRevokeMerchantReset(event) {
    revoke_merchant_id = null;
  }

  function handleRevokeMerchantSelect(event) {
    revokeSelectedMerchant = event.detail || [];
    revoke_merchant_id = revokeSelectedMerchant.value;
  }

  function handleRevokeBrandReset(event) {
    revoke_brand_id = null;
  }

  function handleGrantAccess() {
    grantMerchantToBrandAccessApi();
  }

  function handleRevokeAccess() {
    revokeMerchantToBrandAccessApi();
  }
</script>

<div class="flex flex-col justify-center m-2">
  <div
    class="relative flex flex-col justify-center m-4 min-w-0 break-words w-full lg:w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-gray-800 text-xl font-bold">Add Merchant Access</h6>
      </div>
    </div>
    <div class="flex flex-col m-4 px-4 lg:px-10 py-10 pt-0">
      <div class="w-full flex flex-col justify-center items-center">
        <div class="w-full">
          <div class="w-full mb-3">
            <div class="flex flex-col bg-gradient-dark px-0 xl:flex-row">
              <div
                class="search-box m-4 mx-auto my-auto w-full sm:w-full md:w-full lg:w-full xl:w-full"
              >
                <div class="w-full">
                  <Select
                    containerStyles="min-height: 4rem; color: rgba(113, 128, 150, 1); background-color: rgba(247, 250, 252, 1); padding: 0.5rem 1.5rem; vertical-align:middle; margin:20px"
                    inputAttributes={{
                      class:
                        'h-16 bg-gray-100 text-grey-darker text-grey-darkest border border-gray-100 font-bold w-full py-1 px-2 outline-none text-lg text-gray-600'
                    }}
                    inputStyles="font-size: 1.125rem;"
                    items={brandItems}
                    isMulti={false}
                    placeholder="Please select one Brand outlet"
                    noOptionsMessage="No brands available"
                    on:select={handleBrandSelect}
                    on:clear={handleBrandReset}
                  />
                </div>
              </div>

              <div
                class="search-box m-4 mx-auto my-auto w-full sm:w-full md:w-full lg:w-full xl:w-full"
              >
                <div class="w-full">
                  <Select
                    containerStyles="min-height: 4rem; color: rgba(113, 128, 150, 1); background-color: rgba(247, 250, 252, 1); padding: 0.5rem 1.5rem; vertical-align:middle; margin:20px"
                    inputAttributes={{
                      class:
                        'h-16 bg-gray-100 text-grey-darker text-grey-darkest border border-gray-100 font-bold w-full py-1 px-2 outline-none text-lg text-gray-600'
                    }}
                    inputStyles="font-size: 1.125rem;"
                    items={merchantItems}
                    isMulti={false}
                    placeholder="Please select one merchant outlet"
                    noOptionsMessage="No merchants available"
                    on:select={handleMerchantSelect}
                    on:clear={handleMerchantReset}
                  />
                </div>
              </div>
            </div>

            <div class="flex items-center">
              <div class="w-6/12 mx-auto">
                <button
                  class=" bg-gray-900 text-white active:bg-gray-100 text-xs font-bold uppercase py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none w-full  ease-linear transition-all duration-150"
                  type="submit"
                  on:click={handleGrantAccess}
                >
                  Grant
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <hr class="mt-6 border-b-1 border-gray-400" />

  <div
    class="relative flex flex-col justify-center m-4 min-w-0 break-words w-full lg:w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-gray-800 text-xl font-bold">Revoke Merchant Access</h6>
      </div>
    </div>
    <div class="flex flex-col m-4 px-4 lg:px-10 py-10 pt-0">
      <div class="w-full flex flex-col justify-center items-center">
        <div class="w-full">
          <div class="w-full">
            <div class="flex flex-col bg-gradient-dark xl:flex-row">
              <div
                class="search-box m-4 mx-auto my-auto w-full sm:w-full md:w-full lg:w-full xl:w-full"
              >
                <div class="w-full">
                  <Select
                    containerStyles="min-height: 4rem; color: rgba(113, 128, 150, 1); background-color: rgba(247, 250, 252, 1); padding: 0.5rem 1.5rem; vertical-align:middle; margin:10px"
                    inputAttributes={{
                      class:
                        'h-16 bg-gray-100 text-grey-darker text-grey-darkest border border-gray-100 font-bold w-full py-1 px-2 outline-none text-lg text-gray-600'
                    }}
                    inputStyles="font-size: 1.125rem;"
                    items={brandItems}
                    isMulti={false}
                    placeholder="Please select one Brand outlet"
                    noOptionsMessage="No brands available"
                    on:select={handleRevokeBrandSelect}
                    on:clear={handleRevokeBrandReset}
                  />
                </div>
              </div>

              <div
                class="search-box m-4 mx-auto my-auto w-full sm:w-full md:w-full lg:w-full xl:w-full"
              >
                <div class="w-full">
                  <Select
                    containerStyles="min-height: 4rem; color: rgba(113, 128, 150, 1); background-color: rgba(247, 250, 252, 1); padding: 0.5rem 1.5rem; vertical-align:middle; margin:20px"
                    inputAttributes={{
                      class:
                        'h-16 bg-gray-100 text-grey-darker text-grey-darkest border border-gray-100 font-bold w-full py-1 px-2 outline-none text-lg text-gray-600'
                    }}
                    inputStyles="font-size: 1.125rem;"
                    items={merchantItems}
                    isMulti={false}
                    placeholder="Please select one merchant outlet"
                    noOptionsMessage="No merchants available"
                    on:select={handleRevokeMerchantSelect}
                    on:clear={handleRevokeMerchantReset}
                  />
                </div>
              </div>
            </div>

            <div class="flex items-center">
              <div class="w-6/12 mx-auto">
                <button
                  class="bg-gray-900 text-white active:bg-gray-100 text-xs font-bold uppercase  py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none w-full ease-linear transition-all duration-150"
                  type="submit"
                  on:click={handleRevokeAccess}
                >
                  Revoke
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
</style>
