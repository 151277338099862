<script>
  // make dynamic date to be added to footer
  let date = new Date().getFullYear();

  export let absolute = false;
</script>

<footer
  class="pb-6 {absolute ? 'absolute w-full bottom-0 bg-gray-900' : 'relative'}"
  style="background-color: #3c3c3c; color:#f5f1ea !important"
>
  <div class="container mx-auto px-4">
    <hr class="mb-6 border-b-1 border-gray-100" />
    <div class="flex flex-wrap items-center md:justify-between justify-center">
      <div class="w-full md:w-4/12 px-4">
        <div class="text-sm font-semibold py-1 text-center md:text-left">
          Copyright © {date}
          Morr
        </div>
      </div>
      <div class="w-full md:w-8/12 px-4">
        <ul class="flex flex-wrap list-none md:justify-end justify-center" />
      </div>
    </div>
  </div>
</footer>
