<script>
  import Select from 'svelte-select';

  export let variationInfo;
  let variationByList = [
    {label: 'Size', value: 'size'},
    {label: 'Weight', value: 'weight'},
    {label: 'Volume', value: 'volume'}
  ];

  function handleVariationBy(e) {
    let selectedOption = e.detail.value;
    variationInfo.variation_by = selectedOption;
  }
</script>

<div class="flex">
  <div class="px-4">
    <div class="relative w-full mb-3">
      <label
        class="block uppercase text-gray-700 text-xs font-bold mb-2"
        for="variation-name"
      >
        Title
      </label>
      <input
        id="variation-name"
        type="text"
        class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
        bind:value={variationInfo.title}
      />
    </div>
  </div>
  <div class="px-4">
    <div class="relative w-full mb-3">
      <label
        class="block uppercase text-gray-700 text-xs font-bold mb-2"
        for="price"
      >
        Price
      </label>
      <input
        id="price"
        type="text"
        class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
        bind:value={variationInfo.price}
      />
    </div>
  </div>
  <div class="px-4 w-3/12">
    <div class="relative w-full mb-3">
      <label
        class="block uppercase text-gray-700 text-xs font-bold mb-2"
        for="variation-by"
      >
        Variation By
      </label>
      <Select
        containerStyles="min-height: 4rem; color: rgba(113, 128, 150, 1); background-color: rgba(247, 250, 252, 1); padding: 0.1rem 0.1rem; vertical-align:middle"
        inputAttributes={{
          class:
            'h-16 bg-gray-100 text-grey-darker text-grey-darkest border border-gray-100 font-bold w-full py-1 outline-none text-lg text-gray-600'
        }}
        inputStyles="font-size: 0.5rem;"
        items={variationByList}
        value={variationInfo.variation_by}
        isMulti={false}
        placeholder="Please select one"
        noOptionsMessage="No options available"
        on:select={handleVariationBy}
      />
    </div>
  </div>
  <div class="px-4 w-3/12">
    <div class="relative w-full mb-3">
      <label
        class="block uppercase text-gray-700 text-xs font-bold mb-2"
        for="quantity"
      >
        Quantity
      </label>
      <input
        id="quantity"
        type="text"
        class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
        placeholder="enter quantity with units"
        bind:value={variationInfo.quantity}
      />
    </div>
  </div>

  <div class="px-2">
    <div class="relative w-full mb-3  flex flex-col">
      <label
        class="uppercase text-gray-700 text-xs font-bold mb-2"
        for="available"
      >
        Available?
      </label>

      <input
        type="checkbox"
        id="available"
        class="form-checkbox text-gray-800 ml-1  w-12 h-12 ease-linear transition-all duration-150"
        bind:checked={variationInfo.is_available}
      />
    </div>
  </div>
  <div class="px-2">
    <div class="relative w-full mb-3  flex flex-col">
      <label
        class="uppercase text-gray-700 text-xs font-bold mb-2"
        for="available"
      >
        Default?
      </label>

      <input
        type="checkbox"
        id="available"
        class="form-checkbox text-gray-800 ml-1  w-12 h-12 ease-linear transition-all duration-150"
        bind:checked={variationInfo.is_default}
      />
    </div>
  </div>
</div>
