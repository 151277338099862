<script>
  import algolia from '../../lib/Algolia';
  export let merchantsToShow;
  export let allMerchants;
  export let searchOn;
  let query = '';
  async function search(e) {
    if (query == '') {
      merchantsToShow = allMerchants;
      return;
    }
    console.log(`ALGOLIA SEARCH :: QUERY ${query}`);
    merchantsToShow = (await algolia.search(query)) || [];

    merchantsToShow.map(merchant => {
      merchant[searchOn + '_id'] = merchant.objectID;
    });
  }
</script>

<div
  class="bg-white flex flex-row mb-2 mx-6 w-1/5 min-w-1/2 md:min-w-0 border-1 rounded"
>
  <div class="text-center w-full rounded">
    <input
      type="text"
      name="search"
      on:change={search}
      bind:value={query}
      class="outline-none p-2 w-full rounded"
      placeholder="Search"
    />
  </div>
  <div class="p-2 rounded py-2">
    <i class="fas fa-search" />
  </div>
</div>
