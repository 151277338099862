<script>
  import ProductVariationsCrud from './ProductVariationsCrud.svelte';
  import {Confirm} from 'svelte-confirm';
  export let variationInfoList = [];
  export let deletedVariationIds = [];
  function addMoreVariation() {
    let emptyVariation = {
      title: '',
      price: '',
      variation_by: '',
      quantity: '',
      is_available: true,
      is_default: false
    };
    variationInfoList = [...variationInfoList, emptyVariation];
  }

  function removeVariation(variation, event) {
    event.preventDefault();
    if (variation.variation_id) {
      deletedVariationIds.push(variation.variation_id);
    }
    variationInfoList.splice(variationInfoList.indexOf(variation), 1);
    variationInfoList = [...variationInfoList];
  }
</script>

<div class="flex">
  <h6 class="text-gray-500 text-sm mt-3 mx-3 mb-3 font-bold uppercase">
    Variations (Atleast one)
  </h6>
  <button on:click|preventDefault={addMoreVariation}>
    <i class="fa fa-plus" />
  </button>
</div>
{#if variationInfoList}
  {#each variationInfoList as variation, index}
    <div class="flex">
      <ProductVariationsCrud bind:variationInfo={variation} />
      <Confirm
        themeColor="410"
        confirmTitle="Delete"
        cancelTitle="Cancel"
        let:confirm={confirmThis}
      >
        <button
          class="flex-shrink align-text-bottom"
          on:click={event => {
            event.preventDefault();
            confirmThis(removeVariation, variation, event);
          }}
        >
          <i class="fas fa-trash text-red-500" />
        </button>

        <span slot="title">
          Are you sure, you want to delete <span class="font-semibold"
            >{variation.title}</span
          >?
        </span>
      </Confirm>
    </div>
  {/each}
{/if}
