<script>
  import {form, bindClass} from 'svelte-forms';
  import axios from 'axios';
  import FilePond, {registerPlugin} from 'svelte-filepond';
  import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
  import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
  import {authToken} from '../../store/auth';
  import {showErrorMessage} from '../../utils/toast';
  import {createEventDispatcher} from 'svelte';

  let dispatch = createEventDispatcher();

  registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview
  );

  let baseApiURl = morrDashboard.env.API_URL;
  let filepond_input_name = 'filepond';
  let cover_pond;
  export let isShowCreateModal;
  let validationError;

  let productTemplateFormFields = {
    productTemplate: {
      product_name: '',
      price: 0.0,
      image_url: [],
      description: ''
    }
  };

  const createProductTemplateForm = form(
    () => ({
      ProductTemplate_product_name: {
        value: productTemplateFormFields.productTemplate.product_name,
        validators: ['required']
      },
      ProductTemplate_price: {
        value: productTemplateFormFields.productTemplate.price,
        validators: ['required']
      },
      ProductTemplate_image_url: {
        value: productTemplateFormFields.productTemplate.image_url,
        validators: ['required']
      },
      ProductTemplate_description: {
        value: productTemplateFormFields.productTemplate.description,
        validators: ['required']
      }
    }),
    {
      initCheck: true,
      validateOnChange: true
    }
  );

  function getProductTemplate() {
    return {
      name: productTemplateFormFields.productTemplate.product_name,
      price: productTemplateFormFields.productTemplate.price,
      image_url: getUploadimage_urls(),
      description: productTemplateFormFields.productTemplate.description
    };
  }

  function getUploadimage_urls() {
    return productTemplateFormFields.productTemplate.image_url[0];
  }

  function buildFormPayload() {
    return getProductTemplate();
  }

  function createProductTemplateFunc() {
    validationError = null;

    if (!productTemplateFormFields.productTemplate.product_name) {
      validationError = "Name can't be Empty";
    } else if (productTemplateFormFields.productTemplate.price < 1) {
      validationError = "Price can't be Empty";
    } else if (
      productTemplateFormFields.productTemplate.description.length < 3
    ) {
      validationError = 'Add more description of product';
    }

    if (validationError) {
      showErrorMessage(validationError);
      return;
    }
    let payload = buildFormPayload();
    dispatch('data', payload);
    isShowCreateModal = !isShowCreateModal;
  }

  function getFilePondServerConfig(image_for, image_type) {
    return {
      url: `${baseApiURl}/admin/images`,
      process: {
        url: '/upload',
        method: 'POST',
        withCredentials: false,
        headers: {
          'X-Auth-Token': $authToken
        },
        timeout: 7000,
        ondata: formdata => {
          formdata.append('image_for', image_for);
          formdata.append('image_type', image_type);
          return formdata;
        },
        onload: response => {
          console.log(typeof response);
          response = JSON.parse(response);
          let file_url = response.data.file_url;
          productTemplateFormFields.productTemplate.image_url.push(file_url);
          return file_url;
        },
        onerror: response => {
          let err = JSON.parse(response);
          validationError = (err.hasOwnProperty('detail') && err.detail) || err;
          showErrorMessage(validationError);
          return err.detail;
        }
      },
      revert: (uniqueFileId, load, error) => {
        axios
          .delete(`${baseApiURl}/admin/images/${uniqueFileId}`, {
            headers: {
              'X-Auth-Token': $authToken
            }
          })
          .then(res => {
            let elementIndex =
              productTemplateFormFields.productTemplate.image_url.indexOf(
                uniqueFileId
              );
            productTemplateFormFields.productTemplate.image_url.splice(
              elementIndex,
              1
            );
            load();
          })
          .catch(err => {
            validationError =
              (err.hasOwnProperty('detail') && err.detail) || err;
            showErrorMessage(validationError);
            console.error('FAILED_TO_REMOVE_IMAGE', err);
            error(err);
          });
      }
    };
  }
</script>

<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-2xl bg-gray-200 border-0"
>
  <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
    <form>
      <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
        Product Template Information
      </h6>
      <div class="flex flex-wrap">
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              for="product-template-name"
            >
              Product Template Name
            </label>
            <input
              id="product-template-name"
              type="text"
              class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
              placeholder="Please enter your product Name"
              bind:value={productTemplateFormFields.productTemplate
                .product_name}
              use:bindClass={{
                form: createProductTemplateForm,
                name: 'ProductTemplate_product_name'
              }}
            />
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              for="price"
            >
              Price
            </label>
            <input
              id="price"
              type="text"
              class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
              placeholder="Please enter your product's price"
              bind:value={productTemplateFormFields.productTemplate.price}
              use:bindClass={{
                form: createProductTemplateForm,
                name: 'ProductTemplate_price'
              }}
            />
          </div>
        </div>

        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              for="images"
            >
              Images
            </label>
            <FilePond
              required={true}
              bind:this={cover_pond}
              {filepond_input_name}
              server={getFilePondServerConfig('ProductTemplate', 'cover')}
              allowMultiple={false}
            />
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              for="description"
            >
              Description
            </label>
            <textarea
              id="description"
              class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
              placeholder="Please enter Product's Description"
              bind:value={productTemplateFormFields.productTemplate.description}
              use:bindClass={{
                form: createProductTemplateForm,
                name: 'ProductTemplate_description'
              }}
            />
          </div>
        </div>
        <div class="lg:w-6/12 px-2">
          <div class="relative flex items-center justify-center w-full m-4">
            <button
              class="m-2 bg-blue-500 text-white active:bg-gray-700 text-sm font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
              type="submit"
              on:click|preventDefault={() => {
                createProductTemplateFunc();
              }}
            >
              Save
            </button>
            <button
              class="m-2 bg-blue-500 text-white active:bg-gray-700 text-sm font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
              type="submit"
              on:click|preventDefault={() => {
                isShowCreateModal = !isShowCreateModal;
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<style>
  :global(input.invalid) {
    border: 1px solid rgb(241, 48, 48);
  }
</style>
