<script>
  import CardProductTemplateUpdate from 'components/Cards/CardProductTemplateUpdate.svelte';
  import {Circle} from 'svelte-loading-spinners';
  import {onMount} from 'svelte';
  import {authToken} from '../../store/auth';
  import axios from 'axios';

  export let brand_id;
  export let product_template_id;
  let product_template_data = null;
  export let isShowUpdateModal;

  let baseApiURl = morrDashboard.env.API_URL;
  let apiCallStatus = 'Loading';

  function getProductTemplateProfile() {
    axios
      .get(
        `${baseApiURl}/admin/brand/${brand_id}/product/template/${product_template_id}`,
        {
          headers: {
            'X-Auth-Token': $authToken
          }
        }
      )
      .then(response => {
        product_template_data = response.data.data;
      })
      .catch(err => {
        apiCallStatus = 'Something went wrong.';
        console.log('ERROR OCCURED');
        console.log(err);
      });
  }

  onMount(() => {
    getProductTemplateProfile();
  });
</script>

{#if product_template_data}
  <div class="flex flex-wrap">
    <div class="w-full px-4">
      <CardProductTemplateUpdate
        {brand_id}
        {product_template_id}
        data={product_template_data}
        bind:isShowUpdateModal
      />
    </div>
  </div>
{:else}
  <div class="w-full flex flex-wrap content-center justify-center">
    <div class="px-4">
      {#if apiCallStatus !== 'Loading'}
        <Circle size="60" color="#2d3748" unit="px" duration="1s" />
        {apiCallStatus}
      {/if}
    </div>
  </div>
{/if}
