import intlTelInput from 'intl-tel-input';
import {userMobileCountryCode} from '../store/auth';

let defaultCountryCode = 'MY';

userMobileCountryCode.subscribe(value => {
  if (value) {
    defaultCountryCode = value;
  }
});

const initPhoneValidator = function (
  element,
  initialCountryCode = defaultCountryCode
) {
  const iti = intlTelInput(element, {
    initialCountry: initialCountryCode,
    preferredCountries: ['IN', 'MY'],
    nationalMode: true,
    separateDialCode: true,
    autoPlaceholder: 'off',
    utilsScript:
      'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.min.js'
  });
  return iti;
};

export default initPhoneValidator;
