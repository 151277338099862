<script>
  import CardProductUpdate from 'components/Cards/CardProductUpdate.svelte';
  import {Circle} from 'svelte-loading-spinners';
  import {onMount} from 'svelte';
  import {authToken} from '../../store/auth';
  import axios from 'axios';

  export let currentRoute;
  let product_data = null;
  let product_id;
  let store_id;

  let baseApiURl = morrDashboard.env.API_URL;
  let apiCallStatus = 'Loading';

  function getProductProfile() {
    axios
      .get(`${baseApiURl}/product/${product_id}`, {
        headers: {
          'X-Auth-Token': $authToken
        }
      })
      .then(response => {
        product_data = response.data.data;
      })
      .catch(err => {
        apiCallStatus = 'Something went wrong.';
        console.log('ERROR OCCURED');
        console.log(err);
      });
  }

  onMount(() => {
    store_id = currentRoute.namedParams.store_id;
    product_id = currentRoute.namedParams.product_id;
    getProductProfile();
  });
</script>

{#if product_data !== null}
  <div class="flex flex-wrap">
    <div class="w-full px-4">
      <CardProductUpdate />
    </div>
  </div>
{:else}
  <div class="w-full flex flex-wrap content-center justify-center">
    <div class="px-4">
      {#if apiCallStatus === 'Loading'}
        <Circle size="60" color="#2d3748" unit="px" duration="1s" />
      {/if}
      {apiCallStatus}
    </div>
  </div>
{/if}
