<script>
  import {Accordion, AccordionItem} from 'svelte-collapsible';
  import CardItems from './CardItems.svelte';
  import CardInvoice from './CardInvoice.svelte';
  import {CollapsibleCard} from 'svelte-collapsible';
  import axios from 'axios';
  import {authToken} from '../../../store/auth';
  import {showSuccessMessage, showErrorMessage} from '../../../utils/toast';
  import {orderStatus} from '../../../utils/orderStatus';
  import {onMount} from 'svelte';
  import {parseAndShowErrorMessage} from '../../../utils/errorParser';
  import CardRiderDetails from './CardRiderDetails.svelte';

  let orders = [];
  export let merchant_id;

  let showOrders = true;
  let noOrders = 'loading orders ...';
  let baseApiURL = morrDashboard.env.API_URL;
  let skip = 0;
  let LIMIT = 5;
  let total = 0;
  let next = 0;
  let headers = {
    'X-Auth-Token': $authToken
  };

  let orderDetails = {};

  function updateOrderTrackStatus(index, order_id, currentStatus, nextStatus) {
    if (
      orders[index].order_detail.order_track_status.hasOwnProperty(nextStatus)
    ) {
      return;
    }
    axios
      .put(
        `${baseApiURL}/admin/merchants/${merchant_id}/order/${order_id}/update/track/status?current_status=${currentStatus}&next_status=${nextStatus}`,
        {},
        {
          headers: headers
        }
      )
      .then(res => {
        orders[index].order_detail.order_track_status[nextStatus] = true;
        orders[index].order_detail.current_track_status = nextStatus;
        orders = orders;
        showSuccessMessage('Succesfully Updated the order track status');
      })
      .catch(err => {
        console.error('UPDATE_ORDER_TRACK_STATUS_ERR', err);
        parseAndShowErrorMessage(err);
      });
  }

  function getOrderListApi(skip) {
    let headers = {
      'X-Auth-Token': $authToken
    };
    axios
      .get(
        `${baseApiURL}/admin/merchants/${merchant_id}/orders?skip=${skip}&limit=${LIMIT}`,
        {
          headers: headers
        }
      )
      .then(res => {
        total = res.data.paging.total;
        next = res.data.paging.next;
        if (res.data.orders.length > 0) {
          orders = [...orders, ...res.data.orders];

          setTimeout(() => {
            orders = orders;
            orders.forEach((order, index) => {
              getOrderDetails(order.order_detail.order_id);
            });
          }, 500);
        }
      })
      .catch(err => {
        console.error('ALL_Orders_LISTING_ERROR', err);
        parseAndShowErrorMessage(err);
      });
  }

  const handleChange = e => {
    skip = next + 1;
    if (next > 0) {
      getOrderListApi(skip);
    }
  };

  function getOrderDetails(order_id) {
    let headers = {
      'X-Auth-Token': $authToken
    };
    axios
      .get(`${baseApiURL}/admin/merchants/${merchant_id}/order/${order_id}`, {
        headers: headers
      })
      .then(res => {
        let orderInfo = res.data.data;
        orderDetails[order_id] = orderInfo;
      })
      .catch(err => {
        console.error('Unable to fetch Order details', err, order_id);
        showErrorMessage((err.hasOwnProperty('detail') && err.detail) || err);
      });
  }

  onMount(() => {
    getOrderListApi(skip);
  });
</script>

<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0 text-md"
>
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between">
      <h6 class="text-gray-800 text-xl font-bold">Orders</h6>
    </div>
  </div>
  {#if showOrders}
    <Accordion>
      <div class="mt-8">
        {#each orders as order, index}
          <div
            id={order.order_detail.order_id}
            class="flex bg-gray-300 text-gray-600 leading-10 justify-between rounded-md border-2 border-indigo-200 p-8 m-2 lg:m-10"
          >
            <div
              class="flex flex-col lg:flex-row w-full"
              class:inactive={order.order_detail.current_track_status ===
                orderStatus.DELIVERY_FAILED ||
                order.order_detail.current_track_status ===
                  orderStatus.REJECTED}
            >
              <div class="order-details w-full lg:w-8/12">
                <p class="font-semibold text-sm">
                  <i class="fas fa-clock pr-2" />
                  {new Date(order.order_detail.created_at).toLocaleString()}
                </p>
                <h2 class="heading">
                  <i class="fas fa-file-invoice pr-2" /> #<span class="order-id"
                    >{order.order_detail.order_id}</span
                  >
                </h2>

                {#if order.order_detail.status === 3}
                  <h2 class="text-lg text-green-500">
                    <i class="fas fa-couch  pr-2" title="Table Number" />
                    <span class="font-bold text-2xl subpixel-antialiased">
                      &nbsp;{order.order_detail.table_number}</span
                    >
                  </h2>
                {/if}

                {#if orderDetails[order.order_detail.order_id]}
                  <div class="customer-info text-md font-normal">
                    <h2 class="font-semibold text-sm">Customer Info</h2>
                    <p class="customer-name">
                      <i class="fas fa-user mr-2" />
                      {orderDetails[order.order_detail.order_id]?.contact_info
                        ?.full_name ||
                        orderDetails[order.order_detail.order_id]?.order_details
                          ?.user?.first_name}
                    </p>
                    <p class="customer-email">
                      <i class="fas fa-envelope mr-2" />
                      {orderDetails[order.order_detail.order_id]?.contact_info
                        ?.email ||
                        orderDetails[order.order_detail.order_id]?.order_details
                          ?.user?.email ||
                        'NA'}
                    </p>
                    <p class="customer-mobile">
                      <i class="fas fa-phone-volume mr-2" />{orderDetails[
                        order.order_detail.order_id
                      ]?.contact_info?.mobile_number ||
                        orderDetails[order.order_detail.order_id]?.order_details
                          ?.user.mobile_number ||
                        'NA'}
                    </p>
                    <p>
                      <i class="fas fa-user mr-2" />order for others:
                      {#if orderDetails[order.order_detail.order_id]?.contact_info?.order_for_others}
                        <i
                          class="fa fa-check-circle text-green-500 text-lg ml-2"
                        />
                      {:else}
                        <i
                          class="fas fa-times-circle text-red-500 text-lg ml-2"
                        />
                      {/if}
                    </p>
                    <p class="address-guide my-2">
                      <span class="font-bold">Special Instructions:</span> {orderDetails[
                        order.order_detail.order_id
                      ].special_instructions || 'NA'}
                    </p>
                    {#if orderDetails[order.order_detail.order_id]?.order_details?.address}
                      <div class="customer-address text-sm">
                        <span class="address-description">
                          <i class="fas fa-map-marker-alt mr-2" />
                          {orderDetails[order.order_detail.order_id]
                            .order_details?.address?.address},
                        </span>
                        <span class="address-city">
                          {orderDetails[order.order_detail.order_id]
                            .order_details?.address?.city}
                        </span>
                        <span class="address-postcode">
                          - {orderDetails[order.order_detail.order_id]
                            .order_details?.address?.postcode}
                        </span>
                        <p class="address-guide my-2">
                          Delivery Instructions: {orderDetails[
                            order.order_detail.order_id
                          ].order_details?.address?.note}
                        </p>
                      </div>
                    {/if}
                  </div>
                {/if}
                {#if orderDetails[order.order_detail.order_id]}
                  <div>
                    <CollapsibleCard key={order.order_detail.order_id}>
                      <h2
                        style="color:black;font-weight:800;"
                        slot="header"
                        class="heading"
                      >
                        Items: <i class="fas fa-caret-down" />
                      </h2>
                      <p slot="body" class="body">
                        <CardItems
                          products={orderDetails[order.order_detail.order_id]
                            .products}
                          invoiceDetails={orderDetails[
                            order.order_detail.order_id
                          ]}
                        />
                      </p>
                    </CollapsibleCard>
                  </div>
                {/if}
                <!-- Order Timeline For Delivery-->
                {#if order.order_detail.status === 1}
                  <div class="track mb-24">
                    <div
                      id="placed"
                      class="step"
                      class:active={order.order_detail.order_track_status
                        .ORDER_PLACED}
                    >
                      <span class="icon">
                        <i class="fa fa-check" />
                      </span>
                      <span class="text">Order Placed</span>
                    </div>
                    <div
                      id="progress"
                      class="step"
                      class:active={order.order_detail.order_track_status
                        .IN_PROGRESS}
                      on:click={() =>
                        updateOrderTrackStatus(
                          index,
                          order.order_detail.order_id,
                          orderStatus.ORDER_PLACED,
                          orderStatus.IN_PROGRESS
                        )}
                    >
                      <span class="tooltiptext">
                        <span class="text-white">Process the Order</span></span
                      >
                      <span class="icon"><i class="fa fa-spinner" /> </span>
                      <span class="text">In Progress</span>
                    </div>
                    <div
                      id="ready"
                      class="step"
                      class:active={order.order_detail.order_track_status.READY}
                      on:click={() =>
                        updateOrderTrackStatus(
                          index,
                          order.order_detail.order_id,
                          orderStatus.IN_PROGRESS,
                          orderStatus.READY
                        )}
                    >
                      <span class="tooltiptext">
                        <span class="text-white">Order is Prepared</span>
                      </span>
                      <span class="icon">
                        <i class="fa fa-check" />
                      </span>
                      <span class="text">Ready</span>
                    </div>
                    <div
                      id="delivery"
                      class="step"
                      class:active={order.order_detail.order_track_status
                        .OUT_FOR_DELIVERY}
                      on:click={() =>
                        updateOrderTrackStatus(
                          index,
                          order.order_detail.order_id,
                          orderStatus.READY,
                          orderStatus.OUT_FOR_DELIVERY
                        )}
                    >
                      <span class="tooltiptext">
                        <span class="text-white">Order has been dispatched</span
                        >
                      </span>
                      <span class="icon">
                        <i class="fa fa-truck" />
                      </span>
                      <span class="text">Out for Delivery</span>
                    </div>
                    <div
                      id="merchant"
                      class="step"
                      class:active={order.order_detail.order_track_status
                        .MERCHANT_RESOLVED}
                      on:click={() =>
                        updateOrderTrackStatus(
                          index,
                          order.order_detail.order_id,
                          orderStatus.OUT_FOR_DELIVERY,
                          orderStatus.MERCHANT_RESOLVED
                        )}
                    >
                      <span class="tooltiptext">
                        <span class="text-white"
                          >Order has been delivered to customer</span
                        >
                      </span>
                      <span class="icon">
                        <i class="fa fa-box" />
                      </span>
                      <span class="text">Merchant Resolved</span>
                    </div>
                    <div
                      id="customer"
                      class="step"
                      class:active={order.order_detail.order_track_status
                        .CUSTOMER_RESOLVED}
                      on:click={() =>
                        updateOrderTrackStatus(
                          index,
                          order.order_detail.order_id,
                          orderStatus.MERCHANT_RESOLVED,
                          orderStatus.CUSTOMER_RESOLVED
                        )}
                    >
                      <span class="tooltiptext">
                        <span class="text-white"
                          >Customer has verified the delivery</span
                        >
                      </span>
                      <span class="icon">
                        <i class="fa fa-user" />
                      </span>
                      <span class="text">Customer Resolved</span>
                    </div>
                  </div>
                {:else}
                  <!-- Order Timeline for BOPIS and DINE IN -->
                  <div class="track mb-24">
                    <div
                      id="placed"
                      class="step"
                      class:active={order.order_detail.order_track_status
                        .ORDER_PLACED}
                    >
                      <span class="icon"> <i class="fa fa-check" /> </span>
                      <span class="text">Order Placed</span>
                    </div>
                    <div
                      id="progress"
                      class="step"
                      class:active={order.order_detail.order_track_status
                        .IN_PROGRESS}
                      on:click={() =>
                        updateOrderTrackStatus(
                          index,
                          order.order_detail.order_id,
                          orderStatus.ORDER_PLACED,
                          orderStatus.IN_PROGRESS
                        )}
                    >
                      <span class="tooltiptext">
                        <span class="text-white">Process the Order</span>
                      </span>
                      <span class="icon"><i class="fa fa-spinner" /> </span>
                      <span class="text">In Progress</span>
                    </div>
                    <div
                      id="ready"
                      class="step"
                      class:active={order.order_detail.order_track_status.READY}
                      on:click={() =>
                        updateOrderTrackStatus(
                          index,
                          order.order_detail.order_id,
                          orderStatus.IN_PROGRESS,
                          orderStatus.READY
                        )}
                    >
                      <span class="tooltiptext">
                        <span class="text-white">Order is Prepared</span>
                      </span>
                      <span class="icon">
                        <i class="fa fa-check" />
                      </span>
                      <span class="text">Ready</span>
                    </div>
                    <div
                      id="merchant"
                      class="step"
                      class:active={order.order_detail.order_track_status
                        .MERCHANT_RESOLVED}
                      on:click={() =>
                        updateOrderTrackStatus(
                          index,
                          order.order_detail.order_id,
                          orderStatus.READY,
                          orderStatus.MERCHANT_RESOLVED
                        )}
                    >
                      <span class="tooltiptext">
                        <span class="text-white"
                          >Order has been picked by customer</span
                        >
                      </span>
                      <span class="icon">
                        <i class="fa fa-box" />
                      </span>
                      <span class="text">Merchant Resolved</span>
                    </div>
                    <div
                      id="customer"
                      class="step"
                      class:active={order.order_detail.order_track_status
                        .CUSTOMER_RESOLVED}
                      on:click={() =>
                        updateOrderTrackStatus(
                          index,
                          order.order_detail.order_id,
                          orderStatus.MERCHANT_RESOLVED,
                          orderStatus.CUSTOMER_RESOLVED
                        )}
                    >
                      <span class="tooltiptext">
                        <span class="text-white"
                          >Customer has verified the Order</span
                        >
                      </span>
                      <span class="icon">
                        <i class="fa fa-user" />
                      </span>
                      <span class="text">Customer Resolved</span>
                    </div>
                  </div>
                {/if}
              </div>

              <div class="invoice w-full lg:w-4/12">
                {#if orderDetails[order.order_detail.order_id]}
                  <CardInvoice
                    orderDetail={order.order_detail}
                    invoiceDetails={orderDetails[order.order_detail.order_id]}
                  />
                {/if}
                {#if order.order_detail.status === 1 && order.order_detail.current_track_status != 'ORDER_PLACED' && order.order_detail.delivery_partner != ''}
                  <div class="w-full xl:ml-10 mr-auto p-6">
                    <div
                      class="deliver-info-wrapper  border-1 border-slate-200 rounded-md"
                    >
                      <h2 class="uppercase text-md font-bold text-center">
                        Delivery Info
                      </h2>
                      <div class="flex w-full mt-3 mb-3">
                        <span
                          class="border-1 border-solid w-full border-slate-200"
                        />
                      </div>
                      <p class="body">
                        <CardRiderDetails
                          order_id={order.order_detail.order_id}
                          delivery_partner={order.order_detail.delivery_partner}
                        />
                      </p>
                    </div>
                  </div>
                {/if}
                <div class="lg:pl-16 pr-0">
                  {#if order.order_detail.current_track_status === orderStatus.ORDER_PLACED}
                    <button
                      class="m-2 bg-red-500 text-white active:bg-gray-700 text-sm font-bold uppercase px-4 py-2 shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 w-full rounded-full"
                      type="submit"
                      on:click|preventDefault={() =>
                        updateOrderTrackStatus(
                          index,
                          order.order_detail.order_id,
                          orderStatus.ORDER_PLACED,
                          orderStatus.REJECTED
                        )}
                    >
                      Reject Order
                    </button>
                  {/if}
                  {#if order.order_detail.current_track_status === orderStatus.OUT_FOR_DELIVERY}
                    <button
                      class="m-2 bg-red-500 text-white active:bg-gray-700 text-sm font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                      type="submit"
                      on:click|preventDefault={() =>
                        updateOrderTrackStatus(
                          index,
                          order.order_detail.order_id,
                          orderStatus.OUT_FOR_DELIVERY,
                          orderStatus.DELIVERY_FAILED
                        )}
                    >
                      Cancel
                    </button>
                  {/if}
                </div>
              </div>
            </div>
          </div>
        {/each}
      </div>
      {#if next > 0}
        <div class="w-full mx-auto text-center mt-2 mb-5">
          <button
            class="btn uppercase border-1 border-slate-500 border-solid rounded-full w-60 text-slate-500"
            on:click={handleChange}>Load More</button
          >
        </div>
      {/if}
      {#if next === total || next == -1}
        <div class="error block w-full overflow-x-auto m-4">
          <div class="text-sm font-semibold">You have reached the end.</div>
        </div>
      {/if}
    </Accordion>
  {:else}
    <div class="error block w-full overflow-x-auto m-4">
      <div>{noOrders}</div>
    </div>
  {/if}
</div>

<style>
  :global(.confirm-dialog) {
    padding: 30px 30px !important;
    position: fixed !important;
  }
  :global(.actions) {
    margin: 25px -30px -30px !important;
  }
  .track {
    position: relative;
    background-color: rgb(237 237 237);
    height: 7px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 60px;
    margin-top: 50px;
  }

  .track .step {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    width: 25%;
    margin-top: -18px;
    text-align: center;
    position: relative;
  }

  .track .step::before {
    height: 7px;
    position: absolute;
    content: '';
    width: 100%;
    left: 0;
    top: 18px;
  }

  .track .step.active::before {
    background: rgb(22, 190, 36);
  }
  .track .step.active .icon {
    background: rgb(36 171 3);
    color: #fff;
    box-shadow: 1px 0px 2px 0px #386a0a;
  }

  .track .icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    position: relative;
    border-radius: 100%;
    background: #ddd;
    cursor: pointer;
  }

  .track .step.active .text {
    font-weight: 400;
    color: #000;
  }

  .track .text {
    display: block;
    margin-top: 7px;
    font-size: 12px;
  }

  .error {
    text-align: center;
    width: 100%;
  }

  .btn {
    width: 100%;
    text-align: center;
    font-weight: 700;
    outline: none;
    border: none;
  }

  .inactive {
    cursor: not-allowed;
    filter: opacity(40%);
  }

  .tooltiptext {
    visibility: hidden;
    background-color: #555;
    color: white;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    position: absolute;
    z-index: 1;
    width: 100%;
    bottom: 100%;
    left: 50%;
    margin-left: -70px;
    margin-bottom: 5px;
    box-shadow: 2px 2px gray;
    opacity: 0.5;
  }

  #progress:hover .tooltiptext {
    visibility: visible;
  }

  #ready:hover .tooltiptext {
    visibility: visible;
  }

  #delivery:hover .tooltiptext {
    visibility: visible;
  }

  #merchant:hover .tooltiptext {
    visibility: visible;
  }

  #customer:hover .tooltiptext {
    visibility: visible;
  }

  @media (max-width: 440px) {
    .track .text {
      font-size: 10px;
    }
    .track .icon {
      height: 30px;
      width: 30px;
    }
  }
</style>
