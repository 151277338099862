<script>
  import axios from 'axios';
  import {showSuccessMessage} from '../../../utils/toast';
  import TinyEditor from '@tinymce/tinymce-svelte';
  import {authToken} from '../../../store/auth';
  import {parseAndShowErrorMessage} from '../../../utils/errorParser';
  import minifyHTML from '../../../utils/minifyHtml';
  import {onMount} from 'svelte';
  import {HTMLStringParser} from '../../../utils/minifyHtml';

  export let templateUpdateObj;
  export let emailTemplates;
  export let index;

  let htmlBody;

  let tinyConf = {
    plugins: [
      'advlist autolink lists link image charmap print preview anchor fullpage',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table paste emoticons autoresize'
    ],
    toolbar:
      'styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image emoticons | code',
    skin: 'small'
  };

  function updateEmailTemplateAPI() {
    let payload = {
      name: templateUpdateObj.name,
      service_provider: templateUpdateObj.service_provider,
      subject: templateUpdateObj.subject,
      html_body: minifyHTML(htmlBody),
      text_body: templateUpdateObj.text_body
    };
    axios
      .patch(
        `${morrDashboard.env.API_URL}/admin/email-templates/${templateUpdateObj.template_id}`,
        payload,
        {
          headers: {
            'x-auth-token': $authToken
          }
        }
      )
      .then(res => {
        emailTemplates[index] = templateUpdateObj;
        emailTemplates = [...emailTemplates];
        htmlBody = HTMLStringParser(templateUpdateObj.html_body);
        let successMessage = 'Successfully Updated Email Template';
        showSuccessMessage(successMessage);
      })
      .catch(err => {
        parseAndShowErrorMessage(err);
      });
  }

  onMount(() => {
    htmlBody = HTMLStringParser(templateUpdateObj.html_body);
  });
</script>

<div
  class="flex items-center justify-center p-4 shadow-xl rounded-lg bg-gray-200"
>
  <div class="w-full flex items-center justify-center">
    <form class="w-full flex flex-col items-center leading-10 rounded-xl">
      <h1 class="text-center font-bold text-xl">Edit Email Template</h1>
      <div
        class="w-full flex flex-row items-center justify-center px-2 xs:flex-col"
      >
        <div class="w-6/12 p-2">
          <input
            class="w-full my-2 px-2"
            id="name"
            type="text"
            placeholder="Name"
            bind:value={templateUpdateObj.name}
          />
          <input
            class="w-full my-2 px-2"
            id="service-provider"
            type="text"
            placeholder="Service Provider"
            bind:value={templateUpdateObj.service_provider}
          />
        </div>

        <div class="w-6/12 p-2">
          <input
            class="w-full my-2 px-2"
            id="subject"
            type="text"
            placeholder="Subject"
            bind:value={templateUpdateObj.subject}
          />
          <input
            class="w-full my-2 px-2"
            id="text-body"
            type="text"
            placeholder="Text Body"
            bind:value={templateUpdateObj.text_body}
          />
        </div>
      </div>

      <div class="w-full px-4 mx-4">
        <div class="bg-white w-full" style="font-size: 0.8rem;">
          <TinyEditor
            apiKey={morrDashboard.env.TINYMCE_API_KEY}
            conf={tinyConf}
            bind:value={htmlBody}
          />
        </div>
      </div>

      <div class="text-center w-full mt-2 mb-2">
        <button
          class="btn w-10/12 rounded-3xl uppercase py-0 font-bold outline-none"
          style="background-color: white;"
          type="button"
          on:click={updateEmailTemplateAPI}
        >
          Update Template</button
        >
      </div>
    </form>
  </div>
</div>
