<script>
  import {authToken} from '../../../store/auth';
  import axios from 'axios';
  import {showErrorMessage, showSuccessMessage} from '../../../utils/toast';
  import {onMount} from 'svelte';
  import Navigate from 'svelte-router-spa/src/components/navigate.svelte';

  export let exported_merchant_id;
  let validationError;

  function performOnboardingComplete() {
    grecaptcha.ready(function () {
      grecaptcha
        .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
          action: 'OnboardingComplete'
        })
        .then(function (token) {
          if (token) {
            postToOnboardingComplete(token);
          }
        });
    });
  }

  function postToOnboardingComplete(token) {
    axios
      .post(
        `${morrDashboard.env.API_URL}/admin/merchants/onboarding/${exported_merchant_id}/complete`,
        {},
        {
          headers: {
            'X-Auth-Token': $authToken,
            'x-google-token': token
          }
        }
      )
      .then(response => {
        showSuccessMessage('Successfully Added Merchant');
      })
      .catch(err => {
        validationError = err.response ? err.response.data.detail : err;
        showErrorMessage(validationError);
      });
  }

  onMount(() => {
    performOnboardingComplete();
  });
</script>

<div class="final">
  <div class="msgs">
    <h1 class="successmsg">
      Onboarding successful, we will get back to you shortly.
    </h1>
    <Navigate to={`/merchants/profile/${exported_merchant_id}`}
      ><button
        class="m-2 bg-blue-500 text-white active:bg-gray-700 text-sm font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
      >
        View Profile</button
      ></Navigate
    >
  </div>
</div>

<style>
  .final {
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 20px;
    padding: 10px;
  }
  .successmsg {
    color: rgb(97, 219, 97);
    font-size: 30px;
  }
</style>
