<script>
  import axios from 'axios';
  import 'intl-tel-input/build/css/intlTelInput.css';
  import {onMount} from 'svelte';

  import {authToken} from '../../store/auth';
  import {toast} from '@zerodevx/svelte-toast';

  import CardMobileNumberMultiple from './MobileNumber/CardMobileNumberMultiple.svelte'

  export let data;
  export let merchant_id;

  let baseApiURl = morrDashboard.env.API_URL;

  let numberOfContacts = 0;
  let numberOfDeletedContacts = 0;
  let contactNumbers;
  let deletedContacts = [];

  let contactFormFields = {
    contacts: [],
    deleted_contacts: []
  };

  let emptyContact = {
      country_code: 60,
      phone_number: '',
      order: 1,
      is_primary: false,
      is_whatsapp_contact: false
    }

  function updateContactDetails(apiContacts) {
    apiContacts.forEach(contact => {
      contactNumbers.push({...contact});
    });
    contactNumbers = [...contactNumbers];
  }

  function buildFormPayload(googleRecaptchaToken) {
    return {
      update_contacts: {
        contacts: contactNumbers,
        deleted_contacts: deletedContacts
      },
      captcha: {
        captcha_token: googleRecaptchaToken
      }
    };
  }

  function postToUpdateContactAPI(payload) {
    console.log('PAYLOAD ' + JSON.stringify(payload));

    axios
      .put(
        `${baseApiURl}/admin/merchants/update/${merchant_id}/contact`,
        payload,
        {
          headers: {
            'X-Auth-Token': $authToken
          }
        }
      )
      .then(response => {
        toast.push('Successfully updated merchant contacts', {
          theme: {
            '--toastBackground': '#48BB78',
            '--toastProgressBackground': '#2F855A'
          }
        });
        console.log('CONTACT_UPDATE', response);
        contactFormFields.deleted_contacts = [];
        deletedContacts = [];
        numberOfDeletedContacts = 0;
      })
      .catch(err => {
        console.error('CONTACT_UPDATE_ERROR', err);
        parseAndShowErrorMessage(err)
      });
  }

  function updateContact() {
    grecaptcha.ready(function () {
      grecaptcha
        .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
          action: 'UpdateContact'
        })
        .then(function (token) {
          if (token) {
            let payload = buildFormPayload(token);
            postToUpdateContactAPI(payload);
          }
        });
    });
  }

  function addMoreContacts() {
    contactNumbers.push({...emptyContact});
    contactNumbers = [...contactNumbers];
  }

  function restoreContact(event, index) {
    event.preventDefault();

    const restoredContact = deletedContacts[index];

    deletedContacts.splice(index, 1);
    contactNumbers.push(restoredContact);

    contactNumbers = [...contactNumbers];
    deletedContacts = [...deletedContacts];
  }

  onMount(() => {
    let apiContacts = data.contact_numbers;
    if (apiContacts.length > 0){
      contactNumbers = [];
      updateContactDetails(apiContacts);
    }else {
      contactNumbers = [{...emptyContact}];
    }

  });
</script>

<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0"
>
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between">
      <h6 class="text-gray-800 text-xl font-bold">Update Contact</h6>
    </div>
  </div>
  <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
    <form>
      <div class="rounded-t mb-0 px-6 py-6">
        <div class="text-center flex justify-between">
          <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
            Contact Information
          </h6>
          <button
            class="bg-gray-700 text-white active:bg-gray-800 font-bold uppercase text-xs px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 h-8"
            type="button"
            on:click|preventDefault={addMoreContacts}
          >
            Add New Contact
          </button>
        </div>
      </div>

      <div class="flex flex-wrap w-full">
        {#if contactNumbers}
          <CardMobileNumberMultiple
            bind:contactInfoList={contactNumbers}
            bind:deletedContactsList={deletedContacts}
            ></CardMobileNumberMultiple>
          {/if}
      </div>

      {#if numberOfDeletedContacts !== 0}
        <div class="rounded-t mb-0 px-6 py-6">
          <div class="text-center flex justify-between">
            <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
              Deleted Contacts
            </h6>
          </div>
        </div>
      {/if}

      <div class="flex flex-wrap">
        {#each deletedContacts as deletedContact, index}
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                for={`${index}-contact-number`}
              >
                Mobile Number
              </label>
              <input
                id={`${index}-contact-number`}
                type="text"
                class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                placeholder="Please enter a valid mobile number"
                bind:value={deletedContact.phone_number}
                readonly
              />
            </div>
          </div>

          <div class="w-full lg:w-4/12 px-0">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              for={`${index}-restore`}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </label>
            <button
              class="flex-shrink align-text-bottom"
              on:click={event => restoreContact(event, index)}
            >
              <i class="fas fa-undo text-green-500" />
            </button>
          </div>
          <hr class="w-full lg:w-12/12 mt-6 mb-6 border-b-1 border-gray-400" />
        {/each}
      </div>

      <div class="flex flex-wrap items-center">
        <div class="w-6/12 px-4 mt-4 mx-auto">
          <button
            class="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none w-full  ease-linear transition-all duration-150"
            type="submit"
            on:click|preventDefault={() => updateContact()}
          >
            Update Contact
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<style>
  :global(input.invalid) {
    border: 1px solid rgb(241, 48, 48);
  }
</style>
