<script>
  import CardMobileNumber from './CardMobileNumber.svelte';
	import {Confirm} from 'svelte-confirm';


	export let contactInfoList = [];
	export let deletedContactsList = [];

	function removeContact(event, index, contact) {
		event.preventDefault();

		// const removedContact = contactInfoList[index];
		contactInfoList.splice(contactInfoList.indexOf(contact), 1);
		deletedContactsList.push(contact);
		contactInfoList = [...contactInfoList];
		// contactInfoList = contactInfoList;
		deletedContactsList = [...deletedContactsList];
		console.log(deletedContactsList, contactInfoList)
	}

</script>
{#each contactInfoList as contact, index}
	<div class="wrapper flex w-full flex-row m-4">
		<div class="w-11/12">
			<CardMobileNumber bind:contactInfo={contact}></CardMobileNumber>
		</div>
		<div class="w-8">
			<label
				class="block uppercase text-gray-700 text-xs font-bold mb-2"
				for={`${index}-delete`}
			>
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
			</label>
			<Confirm
				themeColor="410"
				confirmTitle="Delete"
				cancelTitle="Cancel"
				let:confirm={confirmThis}
			>
				<button
					class="flex-shrink align-text-bottom"
					on:click|preventDefault={event =>
						confirmThis(removeContact, event, index, contact)}
				>
					<i class="fas fa-trash text-red-500" />
				</button>

				<span slot="title">
					Are you sure, you want to delete <span class="font-semibold"
						>{contact.phone_number}</span
					>?
				</span>
			</Confirm>
		</div>
	</div>
{/each}
