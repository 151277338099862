<script>
  import {authToken} from '../../../store/auth';
  import axios from 'axios';
  import {showErrorMessage, showSuccessMessage} from '../../../utils/toast';
  import {parseAndShowErrorMessage} from '../../../utils/errorParser';
  import {createEventDispatcher} from 'svelte';

  import CardMobileNumberMultiple from '../MobileNumber/CardMobileNumberMultiple.svelte'

  export let showComponent;
  export let exported_merchant_id;
  // export let merchantInfo;
  export let showPrevComponent;

  let validationError;
  let deletedContacts = [];


  let dispatch = createEventDispatcher();

  export let contactNumbers = [];

  function performAddContact() {
    grecaptcha.ready(function () {
      grecaptcha
        .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
          action: 'MerchantContact'
        })
        .then(function (token) {
          if (token) {
            postToCreateContactApi(token);
          }
        });
    });
  }

  function addMoreContacts() {

    let emptyContact = {
      country_code: '60',
      phone_number: '',
      is_primary: false,
      is_whatsapp_contact: false
    }

    contactNumbers.push(emptyContact);
    contactNumbers = contactNumbers;
  }


  function postToCreateContactApi(token) {
    let payload = contactNumbers;
    axios
      .post(
        `${morrDashboard.env.API_URL}/admin/merchants/onboarding/${exported_merchant_id}/contacts`,
        payload,
        {
          headers: {
            'X-Auth-Token': $authToken,
            'x-google-token': token
          }
        }
      )
      .then(response => {
        showSuccessMessage('Contact Saved');
        dispatch('GetMerchantID', exported_merchant_id);
        showPrevComponent = '';
        showComponent = 'Discount';
      })
      .catch(err => {
        parseAndShowErrorMessage(err)
      });
  }

  function handleSave() {
    performAddContact();
  }

  function handlePrev() {
    showPrevComponent = 'Address';
  }

</script>

<div class="main">
  <div class="rounded-t mb-0 px-6 py-6">
    <div class="text-center flex justify-between">
      <h6 class="title text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
        Contact Information
      </h6>
      <button
        class="bg-gray-700 text-white active:bg-gray-800 font-bold uppercase text-xs px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 h-8"
        type="button"
        on:click|preventDefault={addMoreContacts}
      >
        Add New Contact
      </button>
    </div>
  </div>

  {#if contactNumbers}
  <CardMobileNumberMultiple
    bind:contactInfoList={contactNumbers}
    bind:deletedContactsList={deletedContacts}
    ></CardMobileNumberMultiple>
    {/if}


  <div class="next-btn">
    <button
      class="bg-gray-700 text-white active:bg-gray-800 font-bold uppercase text-xs px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 h-8"
      type="button"
      on:click={handlePrev}
    >
      Prev
    </button>
    {#if showPrevComponent}
      <button
        class="bg-gray-700 text-white active:bg-gray-800 font-bold uppercase text-xs px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 h-8"
        type="button"
        on:click={handleSave}
      >
        Update & Next
      </button>
    {:else}
      <button
        class="bg-gray-700 text-white active:bg-gray-800 font-bold uppercase text-xs px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 h-8"
        type="button"
        on:click={handleSave}
      >
        Save & Next
      </button>
    {/if}
  </div>
</div>

<style>
  .title {
    justify-content: center;
    text-align: center;
    font-size: larger;
  }

  .next-btn {
    justify-content: center;
    text-align: center;
    margin: 20px auto;
  }
</style>
