<script>
  import {Datatable, rows} from 'svelte-simple-datatables';
  import axios from 'axios';
  import {authToken} from '../../../store/auth';
  import {onMount} from 'svelte';
  import {parseAndShowErrorMessage} from '../../../utils/errorParser';
  import {showSuccessMessage} from '../../../utils/toast';
  import {Confirm} from 'svelte-confirm';
  import Toggle from 'svelte-toggle';
  import QuickLinkCrudModal from './QuickLinkCrudModal.svelte';
  import {recaptchaWrapper} from '../../../utils/google-captcha';

  const settings = {
    sortable: true,
    pagination: false,
    rowPerPage: 10,
    scrollY: false,
    columnFilter: true,
    noRows: 'No entries to found',
    css: true
  };
  let baseApiURL = morrDashboard.env.API_V2_URL;

  let data;
  let currentUpdateIndex = null;
  let showModal = false;
  let color = 'light';
  let showQuickLinks = true;
  let quickLinkLoadingStatus = 'Loading..';
  let quicklink_id;
  let quickLinks = [];
  let quickLinkCrudCallback = () => {};

  function createQuickLink(payload) {
    recaptchaWrapper('QuickLinkUpdate', createQuickLinkApi, [payload]);
  }

  function createQuickLinkApi(token, payload) {
    axios
      .post(`${baseApiURL}/admin/homepage/quicklinks/`, payload, {
        headers: {
          'X-Auth-Token': $authToken,
          'X-Google-Token': token
        }
      })
      .then(response => {
        showSuccessMessage('Successfully added quick link');
        showModal = !showModal;
        data = response.data.data;
        getQuickLinkApi();
      })
      .catch(err => {
        parseAndShowErrorMessage(err);
      });
  }

  function updateQuickLink(payload) {
    recaptchaWrapper('QuickLinkUpdate', updateQuickLinkApi, [payload]);
  }

  function updateQuickLinkApi(token, payload) {
    axios
      .patch(
        `${baseApiURL}/admin/homepage/quicklinks/${quicklink_id}`,
        payload,
        {
          headers: {
            'X-Auth-Token': $authToken,
            'x-google-token': token
          }
        }
      )
      .then(response => {
        showSuccessMessage('Successfully updated quicklink');
        data = response.data.data;
        showModal = !showModal;
      })
      .catch(err => {
        parseAndShowErrorMessage(err);
      });
  }

  function getQuickLinkApi() {
    axios
      .get(`${baseApiURL}/admin/homepage/quicklinks/`, {
        headers: {
          'X-Auth-Token': $authToken
        }
      })
      .then(response => {
        quickLinks = response.data.data;
        console.log(response);
      })
      .catch(err => {
        parseAndShowErrorMessage(err);
      });
  }

  function visitUpdate(id, row, index) {
    quicklink_id = id;
    data = row;
    currentUpdateIndex = index;
    showModal = !showModal;
    operationType = 'Update';
    quickLinkCrudCallback = updateQuickLink;
  }

  $: if (data) {
    // After Updating a Product, update local copy as well without api call,
    // but we need to call rows api becoz if we update and delete it will not shows up the update list
    if (currentUpdateIndex != null && data != quickLinks[currentUpdateIndex]) {
      quickLinks[currentUpdateIndex] = data;
    }
  }

  function updateStatusOfQuickLink(event, quickLink, index) {
    if (event.type != 'click' || event.target.id.indexOf('toggle') === -1)
      return;
    let activeStatus = quickLink.is_listed ? false : true;
    let payload = {
      title: quickLink.title,
      order: quickLink.order,
      thumbnail: quickLink.thumbnail,
      is_listed: activeStatus,
      link_type: quickLink.link_type,
      link_destination: quickLink.link_destination,
      active_tab: quickLink.active_tab,
      extra_config: quickLink.extra_config
    };
    grecaptcha.ready(function () {
      grecaptcha
        .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
          action: 'QuickLink'
        })
        .then(function (token) {
          if (token) {
            axios
              .patch(
                `${baseApiURL}/admin/homepage/quicklinks/${quickLink.quick_link_id}`,
                payload,
                {
                  headers: {
                    'X-Auth-Token': $authToken,
                    'x-google-token': token
                  }
                }
              )
              .then(response => {
                showSuccessMessage('Successfully update status');
                currentUpdateIndex = index;
                data = response.data.data;
              })
              .catch(err => {
                parseAndShowErrorMessage(err);
              });
          }
        });
    });
  }

  function deleteQuickLink(quickLinkId, index) {
    grecaptcha.ready(function () {
      grecaptcha
        .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
          action: 'QuickLink'
        })
        .then(function (token) {
          if (token) {
            deleteQuickLinkAPI(quickLinkId, index, token);
          }
        });
    });
  }

  function deleteQuickLinkAPI(quickLinkId, index, token) {
    axios
      .delete(`${baseApiURL}/admin/homepage/quicklinks/${quickLinkId}`, {
        headers: {
          'X-Auth-Token': $authToken,
          'x-google-token': token
        }
      })
      .then(response => {
        showSuccessMessage('Successfully Deleted.');
        quickLinks.splice(index, 1);
      })
      .catch(err => {
        parseAndShowErrorMessage(err);
      });
  }

  onMount(() => {
    getQuickLinkApi();
  });

  let operationType;
</script>

{#if showModal}
  <div
    class="background overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
  >
    <div class="relative w-full my-6 mx-auto max-w-3xl">
      <div class="relative p-6 flex-auto">
        <QuickLinkCrudModal
          bind:showModal
          bind:operationType
          bind:quickLinkData={data}
          callback={quickLinkCrudCallback}
        />
      </div>
    </div>
  </div>
  <div class="opacity-25 fixed inset-0 z-40 bg-black" />
{/if}
<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0"
>
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between p-4">
      <h6 class="text-gray-800 text-xl font-bold">Quick Links</h6>
      <button
        class="z-10 bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        type="button"
        style="z-index: 1;"
        on:click={() => {
          showModal = !showModal;
          operationType = 'create';
          data = null;
          quicklink_id = null;
          quickLinkCrudCallback = createQuickLink;
        }}
      >
        Add New
      </button>
    </div>
  </div>
  <div class="block w-full overflow-x-auto h-screen bg-white">
    {#if showQuickLinks}
      <Datatable
        {settings}
        data={quickLinks}
        class="items-center w-full bg-transparent border-collapse my-4"
      >
        <thead>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="title"
          >
            Title
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="order"
          >
            Order
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="link-type"
          >
            Link Type
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="link-destination"
          >
            Destination
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="is-listed"
          >
            Is Listed
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="active-tab"
          >
            Active Tab
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="extra-conf"
          >
            Extra Config
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="edit"
          >
            <i class="fas fa-pen text-yellow-500" title="Edit" />
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="delete"
          >
            <i class="fas fa-trash text-red-500" title="Delete" />
          </th>
        </thead>
        <tbody>
          {#each $rows as row, index}
            <tr>
              <td
                class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color ===
                'light'
                  ? 'btext-gray-700'
                  : 'text-white'}"
              >
                <span class="flex flex-col items-center">
                  <img
                    src={row.thumbnail
                      ? row.thumbnail
                      : '../assets/img/res-default.ico'}
                    class="h-20 w-26 bg-white border inline m-2"
                    alt={row.title}
                  />
                  <span
                    class="font-bold {color === 'light'
                      ? 'text-gray-700'
                      : 'text-white'}"
                  >
                    {row.title}
                  </span>
                </span>
              </td>
              <td
                class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color ===
                'light'
                  ? 'btext-gray-700'
                  : 'text-white'}"
                >{row.order}
              </td>
              <td
                class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold"
              >
                {row.link_type.toUpperCase().replace('_', ' ')}
              </td>
              <td
                class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color ===
                'light'
                  ? 'text-gray-700'
                  : 'text-white'}"
                >{row.link_destination}
              </td>
              <td
                class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold items-center"
              >
                <Toggle
                  switchColor="#828282"
                  toggledColor="#e8ad90"
                  untoggledColor="#fff"
                  hideLabel
                  toggled={row.is_listed}
                  on:click={e => {
                    updateStatusOfQuickLink(e, row, index);
                  }}
                />
              </td>
              <td
                class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color ===
                'light'
                  ? 'btext-gray-700'
                  : 'text-white'}"
              >
                {row.active_tab}
              </td>
              <td
                class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color ===
                'light'
                  ? 'btext-gray-700'
                  : 'text-white'}"
              >
                {JSON.stringify(row.extra_config)}
              </td>
              <td
                class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color ===
                'light'
                  ? 'btext-gray-700'
                  : 'text-white'}"
              >
                <i
                  class="fas fa-pen text-yellow-500"
                  title="Edit"
                  on:click={() => visitUpdate(row.quick_link_id, row, index)}
                />
              </td>
              <td
                class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color ===
                'light'
                  ? 'btext-gray-700'
                  : 'text-white'}"
              >
                <Confirm
                  themeColor="410"
                  confirmTitle="Delete"
                  cancelTitle="Cancel"
                  let:confirm={confirmThis}
                >
                  <button
                    on:click={() =>
                      confirmThis(deleteQuickLink, row.quick_link_id, index)}
                  >
                    <i class="fas fa-trash text-red-500" />
                  </button>

                  <span slot="title">
                    Are you sure, you want to delete <span class="font-semibold"
                      >{row.title}</span
                    >?
                  </span>
                  <span slot="description" class="text-sm">
                    You won't be able to revert this.
                  </span>
                </Confirm>
              </td>
            </tr>
          {/each}
        </tbody>
      </Datatable>
    {:else}
      <div class="block w-full overflow-x-auto m-4">
        <div>{quickLinkLoadingStatus}</div>
      </div>
    {/if}
  </div>
</div>

<style>
  :global(.confirm-dialog) {
    padding: 30px 30px !important;
    position: fixed !important;
  }
  :global(.actions) {
    margin: 25px -30px -30px !important;
  }

  tr:nth-child(2n) {
    background-color: rgb(243, 243, 243);
  }
</style>
