<script>
  // core components
  import CardMerchantProfile from 'components/Cards/CardMerchantProfile.svelte';
  import {authToken} from '../../store/auth';
  import {onMount} from 'svelte';
  import {toast} from '@zerodevx/svelte-toast';
  import {Circle} from 'svelte-loading-spinners';
  import axios from 'axios';
  import {navigateTo} from 'svelte-router-spa';

  export let currentRoute;
  const params = {};

  let baseApiURl = morrDashboard.env.API_URL;

  let merchantInfo;
  let merchant_id;
  let transactionStats = {};

  function getMerchantProfile() {
    axios
      .get(`${baseApiURl}/merchants/${merchant_id}`, {
        headers: {
          'X-Auth-Token': $authToken
        }
      })
      .then(response => {
        merchantInfo = response.data.data;
      })
      .catch(err => {
        console.log('ERROR OCCURED', err);
        toast.push('Unable to fetch merchant profile', {
          theme: {
            '--toastBackground': '#F56565',
            '--toastProgressBackground': '#C53030'
          }
        });
      });
  }

  function loadTransactionStats(merchant_id) {
    let headers = {
      'X-Auth-Token': $authToken
    };
    axios
      .get(`${baseApiURl}/admin/payments/transactions/stats/${merchant_id}`, {
        headers: headers
      })
      .then(res => {
        console.log('TRANSACTION_STATS', res);
        transactionStats = res.data.data;
      })
      .catch(err => {
        console.error('TRANSACTION_STATS_ERROR', err);
      });
  }

  onMount(() => {
    merchant_id = currentRoute.namedParams.merchant_id;
    getMerchantProfile();
    loadTransactionStats(merchant_id);
  });

  function visitEditMerchantPage() {
    navigateTo(`/merchants/edit/${merchant_id}`);
  }
  function getMerchantCover(merchantInfo) {
    return merchantInfo.images?.cover?.length > 0
      ? merchantInfo.images.cover[0]
      : 'https://kaysharbor.com/wp-content/uploads/2017/04/12-design-principles-of-a-modern-eCommerce-store-776x415.png';
  }
</script>

<main class="merchant-profile-page">
  {#if merchantInfo && transactionStats}
    <section class="relative block h-500-px">
      <button
        class="absolute top-2 right-2 z-10 bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        type="button"
        style="z-index: 1;"
        on:click={visitEditMerchantPage}
      >
        Edit Merchant
      </button>
      <div
        class="absolute top-0 w-full h-full bg-center bg-cover"
        style={`background-image: url("${getMerchantCover(merchantInfo)}");`}
      >
        <span
          id="blackOverlay"
          class="w-full h-full absolute opacity-50 bg-black"
        />
      </div>
      <div
        class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
        style="transform: translateZ(0);"
      >
        <svg
          class="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            class="text-gray-300 fill-current"
            points="2560 0 2560 100 0 100"
          />
        </svg>
      </div>
    </section>

    <section class="information relative py-16 -mt-48">
      <div class="flex flex-wrap">
        <div class="w-full">
          <CardMerchantProfile merchant={merchantInfo} {transactionStats} />
        </div>
      </div>
    </section>
  {:else}
    <div
      class="w-full flex flex-wrap content-center justify-center text-center"
    >
      <Circle size="60" color="#2d3748" unit="px" duration="1s" />
    </div>
  {/if}
</main>
