import {showErrorMessage} from '../utils/toast';

export const parseAndShowErrorMessage = function (error) {
  if (error.response?.data?.detail || error?.detail) {
    let errroDetail = error?.response?.data?.detail || error?.detail;
    if (typeof errroDetail === 'string') {
      showErrorMessage(errroDetail);
    } else if (typeof errroDetail === 'object') {
      errroDetail.forEach(function (validationError) {
        if (validationError.hasOwnProperty('loc')) {
          let errorLocation = validationError['loc'].pop();
          showErrorMessage(
            `<span style="text-transform:capitalize; font-weight:900">${errorLocation.replace(
              /[^a-zA-Z ]/g,
              ' '
            )}</span> - ${validationError['msg']}`
          );
        } else {
          showErrorMessage(JSON.stringify(validationError));
        }
      });
    }
  }
   else {
    showErrorMessage(error);
  }
};
