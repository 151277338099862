// import { writable } from "svelte/store";

import {writable} from 'svelte-persistent-store/dist/local';

export const isLoggedIn = writable('is_logged_in', false);
export const authToken = writable('dashboard_token', '');
export const refreshToken = writable('merchant_token', '');
export const isSuperAdmin = writable('status_check', false);
export const loadRouter = writable('load_routes', false);
export const userMobileCountryCode = writable('country_code', 'MY');
export const userMobileCountryCodeVerified = writable('country_code_verified', false);
