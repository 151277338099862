<script>
  import CardProductCreate from 'components/Cards/CardProductCreate.svelte';
  export let currentRoute;
</script>

<div class="flex flex-wrap">
  <div class="w-full px-4">
    <CardProductCreate store_id={currentRoute.namedParams.store_id} />
  </div>
</div>
