<script>
  import CardAddStoreCategory from './CardAddStoreCategory.svelte';
  import axios from 'axios';
  import {authToken} from '../../../store/auth';
  import {onMount} from 'svelte';
  import {parseAndShowErrorMessage} from '../../../utils/errorParser';

  export let store_id;
  export let categories;

  let baseApiURL = morrDashboard.env.API_URL;
  let showProduct = false;
  let showAddCategory = false;
  let color = 'light';
  let items = [];

  function getProductListApi() {
    let headers = {
      'X-Auth-Token': $authToken
    };
    axios
      .get(`${baseApiURL}/admin/store/${store_id}/category/products`, {
        headers: headers
      })
      .then(res => {
        items = res.data.data;
        showProduct = true;
      })
      .catch(err => {
        console.error('ALL_Products_Categories_LISTING_ERROR', err);
        showProduct = false;
        noProduct = 'Products not found';
        parseAndShowErrorMessage(err);
      });
  }
  onMount(() => {
    getProductListApi();
  });
</script>

{#if showAddCategory}
  <div class="opacity-25 fixed inset-0 z-40 bg-black" />
  <div
    class="background overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
  >
    <div class="relative w-auto my-6 mx-auto max-w-3xl">
      <div class="relative p-6 flex-auto">
        <CardAddStoreCategory
          bind:categories
          bind:showAddCategory
          bind:store_id
        />
      </div>
    </div>
  </div>
  <div class="opacity-25 fixed inset-0 z-40 bg-black" />
{/if}

<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-100 border-0"
>
  <div
    class="rounded-t bg-white mb-0 px-6 py-6 flex flex-row justify-between border-b border-gray-200"
  >
    <h6 class="text-gray-800 text-xl font-bold flex">Category View</h6>
    <button
      class="z-1 bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
      type="button"
      on:click={() => {
        showAddCategory = !showAddCategory;
      }}
    >
      Add Category
    </button>
  </div>
  {#if showProduct}
    {#each Object.keys(items) as category}
      <h6 class="text-gray-800 text-xl font-bold m-4 py-2 px-4" id={category}>
        {category}
      </h6>
      <div class="products">
        {#each items[category] as product, index (product.product_id)}
          <div>
            <div class="product p-4">
              <div class="relative pb-48 overflow-hidden rounded-lg">
                <img
                  class="absolute inset-0 h-full w-full object-cover"
                  src={product.image_url}
                  alt=""
                />
              </div>
              <div class="p-4">
                <h2 class="mt-2 mb-2 font-bold">
                  {product.product_name}
                </h2>
                <p class="text-sm">
                  {product.description}
                </p>
                <div class="mt-3 flex items-center">
                  <span class="text-sm font-semibold">RM</span>&nbsp;<span
                    class="font-bold text-xl">{product.price}</span
                  >
                </div>
              </div>
              <div
                class="py-2 px-8 status flex border-t border-b text-xs text-gray-700 justify-evenly"
              >
                <div class="flex flex-row font-bold items-center m-2">
                  <p>Available?</p>
                  {#if product.is_available}
                    <i class="fa fa-check-circle text-green-500 text-lg ml-2" />
                  {:else}
                    <i class="fas fa-times-circle text-red-500 text-lg ml-2" />
                  {/if}
                </div>
                <div class="flex flex-row font-bold items-center m-2">
                  <p>Deliverable?</p>
                  {#if product.is_deliverable}
                    <i class="fa fa-check-circle text-green-500 text-lg ml-2" />
                  {:else}
                    <i class="fas fa-times-circle text-red-500 text-lg ml-2" />
                  {/if}
                </div>
                <div class="flex flex-row font-bold items-center m-2">
                  <p>Pickable?</p>
                  {#if product.is_pickable}
                    <i class="fa fa-check-circle text-green-500 text-lg ml-2" />
                  {:else}
                    <i class="fas fa-times-circle text-red-500 text-lg ml-2" />
                  {/if}
                </div>
              </div>
            </div>
          </div>
        {/each}

        {#if items[category].length === 0}
          <div
            class="text-black px-6 py-4 border-0 rounded relative mb-4 bg-orange-300"
          >
            <span class="text-xl inline-block mr-5 align-middle">
              <i class="fas fa-bell" />
            </span>
            <span class="inline-block align-middle mr-8">
              Please add products to this category
            </span>
          </div>
        {/if}
      </div>
    {/each}
  {:else}
    <div class="block w-full overflow-x-auto m-4">
      <div>{'No categories found'}</div>
    </div>
  {/if}
</div>

<style>
  .products {
    display: grid;
    grid-template-columns: 32% 32% 32%;
    align-items: center;
    justify-content: center;
  }

  .product {
    background-color: rgb(231, 221, 207);
    border-radius: 20px;
    margin: 20px;
  }

  @media (max-width: 540px) {
    button {
      margin: 5px;
    }
  }

  @media (max-width: 1000px) {
    .products {
      display: grid;
      grid-template-columns: 93%;
    }
  }
  @media (min-width: 1000px) and (max-width: 1400px) {
    .products {
      display: grid;
      grid-template-columns: 47% 47%;
    }
  }
  @media (max-width: 1497px) {
    .status {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
</style>
