<script>
  import {onMount} from 'svelte';
  import {authToken} from '../../../store/auth';
  import {isSuperAdmin} from '../../../store/admin';
  import axios from 'axios';

  import {form, bindClass} from 'svelte-forms';
  import Select from 'svelte-select';
  import {showSuccessMessage, showErrorMessage} from '../../../utils/toast';
  import {parseAndShowErrorMessage} from '../../../utils/errorParser';

  export let categories;
  export let showAddCategory;
  export let store_id;
  let availablePlatforms = [
    {
      value: 'ios',
      label: 'iOS'
    },
    {
      value: 'android',
      label: 'Android'
    }
  ];

  let categoryFormField = {
    name: '',
    store_id: store_id,
    restricted_platforms: null
  };

  const categoryForm = form(
    () => ({
      name: {value: categoryFormField.name, validators: ['required']},
      restricted_platforms: {
        value: categoryFormField.restricted_platforms,
        validators: []
      }
    }),
    {
      initCheck: true,
      validateOnChange: true
    }
  );

  function postToAddCategory() {
    if (!categoryFormField.name) {
      showErrorMessage("Category Name can't be empty");
    } else if (!$isSuperAdmin && !categoryFormField.store_id) {
      showErrorMessage('Store is required!');
    } else {
      const payload = categoryFormField;
      axios
        .post(`${morrDashboard.env.API_URL}/admin/store/category`, payload, {
          headers: {
            'X-auth-token': $authToken
          }
        })
        .then(response => {
          showSuccessMessage('New Category Created');
          categories = [...categories, {...payload}];
          categoryFormField.store_id = null;
          showAddCategory = false;
        })
        .catch(err => {
          parseAndShowErrorMessage(err);
        });
    }
  }

  function handleRestrictedPlatformSelect(event) {
    let restrictedPlatforms = event.detail || [];
    categoryFormField.restricted_platforms = restrictedPlatforms.map(
      platform => platform.value
    );
  }
  function handleRestrictedPlatformReset(event) {
    categoryFormField.restricted_platforms = undefined;
  }
</script>

<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-xl bg-gray-200 border-0"
>
  <div class="rounded-t mb-0 px-6 py-2">
    <h6 class="text-gray-800 text-lg font-bold">New Category</h6>
  </div>
  <div class="flex-auto px-2 lg:px-10 py-4 pt-0 my-2">
    <form>
      <div class="flex flex-wrap">
        <div class="w-full">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              for="category-name"
            >
              Name
            </label>
            <input
              id="category-name"
              type="text"
              class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
              placeholder="Please enter category Name"
              bind:value={categoryFormField.name}
              use:bindClass={{form: categoryForm, name: 'name'}}
            />
          </div>
        </div>
        <div class="w-full">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              for="restricted_platforms"
            >
              Restricted Platforms
            </label>
            <Select
              containerStyles="padding:3px 10px !important; background: #f5f5f5;"
              inputAttributes={{
                class:
                  'h-16 bg-gray-100 text-grey-darker text-grey-darkest border border-gray-100 font-bold w-full outline-none text-lg text-gray-600'
              }}
              inputStyles="font-size: 1.125rem;"
              items={availablePlatforms}
              value={categoryFormField.restricted_platforms}
              isMulti={true}
              placeholder="Restricted Platforms"
              noOptionsMessage="Listing enabled for all platforms"
              on:select={handleRestrictedPlatformSelect}
              on:clear={handleRestrictedPlatformReset}
            />
          </div>

          <div class="flex flex-row justify-between w-full">
            <button
              class="w-32 bg-gray-700 text-white active:bg-gray-800 font-bold uppercase text-xs px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 h-8"
              type="button"
              on:click|preventDefault={postToAddCategory}
            >
              Save
            </button>
            <button
              class="w-32 bg-gray-100 text-gray-700 active:bg-gray-800 font-bold uppercase text-xs px-2 py-2 rounded outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 h-8"
              type="button"
              on:click|preventDefault={() => {
                showAddCategory = false;
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
