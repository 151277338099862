<script>
  import {Datatable, rows} from 'svelte-simple-datatables';
  import axios from 'axios';
  import {authToken} from '../../../store/auth';
  import {onMount} from 'svelte';
  import {parseAndShowErrorMessage} from '../../../utils/errorParser';
  import CardEmailTemplateEdit from './CardEmailTemplateEdit.svelte';
  import {HTMLStringParser} from '../../../utils/minifyHtml';
  import Pagination from '@fouita/pagination';
  import {showSuccessMessage} from '../../../utils/toast';
  import {navigateTo} from 'svelte-router-spa';

  const settings = {
    sortable: true,
    pagination: false,
    rowPerPage: 10,
    scrollY: false,
    columnFilter: true,
    noRows: 'No entries to found',
    css: true
  };
  const limit = 10;
  let current = 1;
  let skip = 0;
  let totalItems = 0;

  let emailTemplates = [];
  let showEmailTemplates = false;
  let templateLoadingStatus = 'Loading templates ...';

  // template preview modal
  let isPreviewingTemplate = false;
  let templatePreview = null;

  // template edit modal
  let isShowingEditModal = false;
  let templateUpdateObj;
  let updateTemplateIndex = 0;

  function getEmailTemplatesAPI(skip) {
    let headers = {
      'X-Auth-Token': $authToken
    };
    axios

      .get(
        `${morrDashboard.env.API_URL}/admin/email-templates/?skip=${skip}&limit=${limit}`,
        {
          headers: headers
        }
      )
      .then(res => {
        console.log('ALL_EMAIL_TEMPLATE_LISTING', res);
        emailTemplates = res.data.data;
        totalItems = res.data.paging.total;
        showEmailTemplates = true;
      })
      .catch(err => {
        showEmailTemplates = false;
        templateLoadingStatus = 'Email Template not found';
        parseAndShowErrorMessage(err);
      });
  }

  function showTemplateEditModal() {
    isShowingEditModal = true;
  }

  function closeTemplateEditModal() {
    isShowingEditModal = false;
  }

  function showPreview() {
    isPreviewingTemplate = true;
  }

  function closePreview() {
    isPreviewingTemplate = false;
  }

  function editTemplate(index) {
    showTemplateEditModal();
    const emailTemplate = emailTemplates[index];
    templateUpdateObj = emailTemplate;
    updateTemplateIndex = index;
  }

  function showHTMLBodyPreview(index) {
    showPreview();
    const emailTemplate = emailTemplates[index];
    templatePreview = HTMLStringParser(emailTemplate.html_body);
  }

  function handleKeyDown(event, closeModalHandler) {
    if (event.key === 'Escape') closeModalHandler();
  }

  function updateTemplateActionAPI(index) {
    let emailTemplate = emailTemplates[index];
    const action = emailTemplate.is_published ? 'unpublish' : 'publish';
    showSuccessMessage(`${action}ing ${emailTemplate.name}`);

    let headers = {
      'x-auth-token': $authToken
    };
    axios
      .post(
        `${morrDashboard.env.API_URL}/admin/email-templates/manage/${emailTemplate.template_id}/${action}`,
        {},
        {
          headers: headers
        }
      )
      .then(res => {
        emailTemplate.is_published = !emailTemplate.is_published;
        emailTemplates[index] = emailTemplate;
        emailTemplates = [...emailTemplates];
        console.log('EMAIL_TEMPLATE_ACTION_UPDATE', res);
      })
      .catch(err => {
        parseAndShowErrorMessage(err);
      });
  }

  function deleteTemplateActionAPI(index) {
    let emailTemplate = emailTemplates[index];
    showSuccessMessage(`Deleting ${emailTemplate.name}`);

    let headers = {
      'x-auth-token': $authToken
    };
    axios
      .delete(
        `${morrDashboard.env.API_URL}/admin/email-templates/${emailTemplate.template_id}`,
        {
          headers: headers
        }
      )
      .then(res => {
        emailTemplates.splice(index, 1);
        emailTemplates = [...emailTemplates];
        console.log('EMAIL_TEMPLATE_ACTION_DELETE', res);
        showSuccessMessage(`${emailTemplate.name} successfully deleted`);
      })
      .catch(err => {
        parseAndShowErrorMessage(err);
      });
  }

  function paginate(e) {
    if (current === 1) {
      getEmailTemplatesAPI(0);
    } else if (current > 1) {
      skip = limit * (current - 1);
      getEmailTemplatesAPI(skip);
    }
  }

  $: if (isPreviewingTemplate) {
    document.addEventListener('keydown', event => {
      handleKeyDown(event, closePreview);
    });
  }

  $: if (isShowingEditModal) {
    document.addEventListener('keydown', event => {
      handleKeyDown(event, closeTemplateEditModal);
    });
  }

  onMount(() => {
    getEmailTemplatesAPI(0);
  });

  let color = 'light';
</script>

{#if isPreviewingTemplate}
  <div
    class="background overflow-x-hidden overflow-y-scroll fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
    on:click|self={() => closePreview()}
  >
    <div class="relative w-auto my-6 mx-auto max-w-3xl">
      <div class="relative p-6 flex-auto">
        <i
          class="fas fa-times-circle text-red-500 text-lg ml-2"
          on:click={() => closePreview()}
        />
        <div contenteditable="false" bind:innerHTML={templatePreview} />
      </div>
    </div>
  </div>
  <div class="opacity-25 fixed inset-0 z-40 bg-black" />
{/if}
{#if isShowingEditModal}
  <div
    class="background overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
    on:click|self={() => closeTemplateEditModal()}
  >
    <div class="relative w-auto my-6 mx-auto max-w-3xl">
      <div class="relative p-6 flex-auto">
        <CardEmailTemplateEdit
          bind:templateUpdateObj
          bind:emailTemplates
          index={updateTemplateIndex}
        />
      </div>
    </div>
  </div>
  <div class="opacity-25 fixed inset-0 z-40 bg-black" />
{/if}
<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0"
>
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between">
      <h6 class="text-gray-800 text-xl font-bold">Email Templates</h6>
      <button
        class="absolute top-2 right-2 z-10 bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        type="button"
        style="z-index: 1;"
        on:click={() => navigateTo('/admin/email-template/create')}
      >
        Add New
      </button>
    </div>
  </div>
  <div class="block w-full overflow-x-auto">
    {#if showEmailTemplates}
      <Datatable
        {settings}
        data={emailTemplates}
        class="items-center w-full bg-transparent border-collapse my-4"
      >
        <thead>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="template-name"
          >
            Template Name
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="subject"
          >
            Subject
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="service-provider"
          >
            Service Provider
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="status"
          >
            Status
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="preview"
          >
            Actions
          </th>
        </thead>
        <tbody>
          {#each $rows as row, index}
            <tr>
              <td
                class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color ===
                'light'
                  ? 'btext-gray-700'
                  : 'text-whit'}"
                >{row.name}
              </td>
              <td
                class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color ===
                'light'
                  ? 'btext-gray-700'
                  : 'text-whit'}"
                >{row.subject}
              </td>
              <td
                class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {row.is_published
                  ? 'text-green-500'
                  : 'text-gray-500'}"
              >
                {#if row.service_provider === 'ses'}
                  <i class="fab fa-aws text-blue-1000" title="Amazon SES" />
                {/if}
                <br />
                {row.service_provider.toUpperCase()}
              </td>
              <td
                class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color ===
                'light'
                  ? 'text-gray-700'
                  : 'text-white'}"
                >{row.status}
              </td>
              <td
                class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color ===
                'light'
                  ? 'btext-gray-700'
                  : 'text-whit'}"
              >
                <i
                  class="fas fa-eye text-blue-700"
                  on:click={() => showHTMLBodyPreview(index)}
                  title="Preview"
                />
                &nbsp;&nbsp; &nbsp;&nbsp;
                <i
                  class="fas fa-pen text-yellow-500"
                  on:click={() => editTemplate(index)}
                  title="Edit"
                />
                &nbsp;&nbsp; &nbsp;
                {#if row.is_published}
                  <i
                    class="fas fa-cloud-sun  text-red-500 text-lg"
                    title="Click to unpublish"
                    on:click={() => updateTemplateActionAPI(index)}
                  />
                {:else}
                  <i
                    class="fas fa-cloud text-green-500  text-lg"
                    title="click to publish template"
                    on:click={() => updateTemplateActionAPI(index)}
                  />
                {/if}
                &nbsp;&nbsp; &nbsp;&nbsp;
                <i
                  class="fas fa-trash text-red-500"
                  on:click={() => deleteTemplateActionAPI(index)}
                  title="Delete"
                />
              </td>
            </tr>
          {/each}
        </tbody>
      </Datatable>
      <Pagination
        small
        bind:current
        per_page={limit}
        num_items={totalItems}
        on:navigate={paginate}
      />
    {:else}
      <div class="block w-full overflow-x-auto m-4">
        <div>{templateLoadingStatus}</div>
      </div>
    {/if}
  </div>
</div>

<style>
  :global(.confirm-dialog) {
    padding: 30px 30px !important;
    position: fixed !important;
  }
  :global(.actions) {
    margin: 25px -30px -30px !important;
  }

  tr:nth-child(2n) {
    background-color: rgb(243, 243, 243);
  }
</style>
