import algoliasearch from 'algoliasearch';

/**
 * Algolia class used for searching
 */
class Algolia {
  /**
   * Constructor of Algolia class
   * @param {String} index - algolia index
   */
  constructor(index) {
    this.client = this.getAlgoliaClient();
    this.index = this.client.initIndex(index);
  }

  /**
   *
   * @param {String} query - query to be searched
   * @returns
   */
  async search(query) {
    return await this.index.search(query).then(({hits}) => hits);
  }

  /**
   * Creates a new algolia client.
   * @return algolia client.
   */
  getAlgoliaClient() {
    if (
      !(morrDashboard.env.ALGOLIA_APP_ID && morrDashboard.env.ALGOLIA_API_KEY)
    ) {
      throw new Error('Algolia APP ID/API KEY missing');
    }
    return algoliasearch(
      morrDashboard.env.ALGOLIA_APP_ID,
      morrDashboard.env.ALGOLIA_API_KEY
    );
  }
}

const AlgoliaIndices = Object.freeze({MERCHANT_INDEX: 'MERCHANT_INDEX'});

const algolia = new Algolia(AlgoliaIndices.MERCHANT_INDEX);

export default algolia;
