<script>
  import {Navigate} from 'svelte-router-spa';
  import {navigateTo} from 'svelte-router-spa';
  import {form, bindClass} from 'svelte-forms';
  import axios from 'axios';
  import {toast} from '@zerodevx/svelte-toast';
  import {isLoggedIn, authToken, refreshToken} from '../../store/auth';
  import {onMount, tick} from 'svelte';
  import queryString from 'query-string';
  import {showSuccessMessage, showErrorMessage} from '../../utils/toast';

  let password_reset_token = '';
  let new_password = '';
  let confirm_new_password = '';

  let showFormValidationError = {};

  let passwordResetForm = form(
    () => ({
      new_password: {value: new_password, validators: ['required']},
      confirm_new_password: {
        value: confirm_new_password,
        validators: ['required']
      }
    }),
    {
      initCheck: true,
      validateOnChange: true
    }
  );

  function requestPasswordResetAPI(googleRecaptchaToken) {
    let payload = {
      password_reset_token,
      new_password,
      confirm_new_password
    };

    axios
      .post(`${morrDashboard.env.API_URL}/users/password-reset`, payload)
      .then(res => {
        console.log('PASSWORD_RESET', res);

        showSuccessMessage(res.data.message);

        navigateTo('/auth/login');
      })
      .catch(err => {
        console.log('request failed', err.response);
        let errorMessage =
          err.response?.data?.detail || 'Oops.. something went wrong!';
        showErrorMessage(errorMessage);
      });
  }

  function performPasswordReset() {
    const queryParams = queryString.parse(window.location.search);
    password_reset_token = queryParams?.token;

    if (!password_reset_token || password_reset_token === '') {
      showErrorMessage('Invalid password reset link!');
      return;
    }

    if (new_password !== confirm_new_password) {
      showErrorMessage('Password and Confirm password not matched');
      return;
    }

    passwordResetForm.validate();
    if (!$passwordResetForm.valid) {
      showErrorMessage('Please enter valid details');
      return;
    }

    grecaptcha.ready(function () {
      grecaptcha
        .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
          action: 'PasswordReset'
        })
        .then(function (token) {
          if (token) {
            requestPasswordResetAPI(token);
          }
        });
    });
  }

  function enableFormValidationError(fieldName) {
    showFormValidationError[fieldName] = true;
    console.log(showFormValidationError);
  }
</script>

<div class="main-container">
  <div class="w-11/12 sm:10/12 md:w-9/12 lg:w-7/12 xl:w-6/12">
    <form
      class="forms-container form w-full p-8 leading-10 rounded-xl filter drop-shadow-md"
    >
      <img
        class="w-16 my-6"
        src="https://morr-app.s3.ap-south-1.amazonaws.com/assets/logo/icon_logo_transparent.png"
        alt="Welcome to Morr"
        title="Welcome to Morr"
      />
      <h1 class="text-center font-bold text-xl">Password Reset</h1>
      <input
        type="hidden"
        id="g-recaptcha-response"
        name="g-recaptcha-response"
      />
      <input
        class="w-10/12 my-2"
        id="grid-password"
        type="password"
        placeholder="New password"
        bind:value={new_password}
        on:change={() => {
          enableFormValidationError('new_password');
        }}
        use:bindClass={{
          form: passwordResetForm,
          invalid:
            'text-sm sm:text-base relative w-10/12 my-2 border rounded placeholder-gray-400 focus:border-indigo-400 focus:outline-none py-2 pr-2 pl-12 border-red-500'
        }}
      />
      <input
        class="w-10/12 my-2"
        id="grid-confirm-password"
        type="password"
        placeholder="Confirm Password"
        bind:value={confirm_new_password}
        on:change={() => {
          enableFormValidationError('confirm_new_password');
        }}
        use:bindClass={{
          form: passwordResetForm,
          invalid: 'border-red-500 focus:outline-none focus:border-red-500'
        }}
      />

      <div class="text-center w-full my-3">
        <button
          class="btn w-10/12 morr-background rounded-3xl uppercase py-0 h-10 font-bold outline-none"
          type="button"
          on:click={() => performPasswordReset()}
          disabled={!$passwordResetForm && $passwordResetForm?.valid}
          >Change password</button
        >
      </div>
      <div
        class="flex relative mt-4 w-full z-2 justify-between px-16 text-lg font-normal text-black"
      >
        <div class="space-x-2 inline-block">
          <Navigate to="/auth/forgot-password">
            <small>Generate password reset link</small>
          </Navigate>
        </div>

        <div class="space-x-2 inline-block">
          <Navigate to="/auth/register">
            <small>Create a new account</small>
          </Navigate>
        </div>
      </div>
    </form>
  </div>
</div>

<style>
  .messages {
    display: flex;
    justify-content: flex-start;
  }

  .main-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .check {
    display: flex;
    justify-content: flex-start;
  }
  .form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }

  .forms-container {
    background-color: #f5f1ea;
    border-radius: 10px;
    overflow: hidden;
    z-index: 2;
  }

  .form input {
    outline: none;
    border: none;
    border-bottom: 1px solid #b3b1b140;
    background: transparent;
  }

  .form-group {
    display: block;
  }

  .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  .form-group label {
    position: relative;
    cursor: pointer;
  }

  .form-group label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #d1193e;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
  }

  .form-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid #d1193e;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
</style>
