<script>
  import CardProductsDatatable from 'components/Cards/CardProductsDatatable.svelte';

  export let currentRoute;
</script>

<div>
  <div class="flex flex-wrap">
    <div class="w-full">
      <CardProductsDatatable
        brand_id={currentRoute.namedParams.brand_id}
        store_id={currentRoute.namedParams.store_id}
      />
    </div>
  </div>
</div>
