<script>
  export let products;
  export let invoiceDetails;
</script>

<div class="items-container">
  {#if products}
    <div class="flex flex-col w-full m-0">
      {#each products as product}
        <div
          class="test product w-full my-2 justify-between flex flex-col lg:flex-row max-w-full rounded-md border-1 border-slate-200"
        >
          <div
            class="product-thumbnail h-48 lg:h-auto lg:w-48 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden"
            style={`background-image: url('${product.product.image_url}')`}
            title={product.product.product_name}
          />
          <div
            class={`py-1 px-4 leading-relaxed  w-full ${
              product.addons.length > 0 ? 'lg:w-7/12' : ''
            }`}
          >
            <div class="mb-1 w-full">
              <div class="text-gray-900 font-bold text-lg">
                {product.product.product_name}
                {#if product.variation}
                  <span class="font-bold text-indigo-500"
                    >({product.variation.title})</span
                  >
                {/if}
                <span class="text-slate-500 text-2xl"
                  >x{product.number_of_products}</span
                >
              </div>

              <p class="font-normal my-2">
                <i class="fas fa-file-invoice-dollar" /> RM
                <span class="font-bold text-gray-800"
                  >{product.price_per_unit}</span
                >
              </p>
              <p
                class="text-gray-600 text-sm w-full break-words text-justify my-1"
              >
                Customer Note:
                <br />
                <i class="fas fa-quote-left" />
                {product.note || '-'}
                <i class="fas fa-quote-right" />
              </p>
            </div>
          </div>
          <div
            class={`py-1 px-4 leading-normal w-full ${
              product.addons.length > 0 ? 'lg:w-4/12' : ''
            }`}
          >
            {#if product.addons.length > 0}
              <div class="justify-between">
                <span class="text-slate-800 text-lg font-semibold mt-2"
                  >Addons:</span
                >
                <br />
                {#each product.addons as addon}
                  <div class="flex m-0 p-0 justify-between border-b mb-2 pb-2">
                    <p class="text-indigo-500 text-sm font-semibold">
                      {addon.name}
                    </p>
                    <p class="text-gray-600 text-sm">
                      RM <span class="text-gray-800 font-bold">
                        {addon.price}</span
                      >
                    </p>
                  </div>
                {/each}
              </div>
            {/if}
          </div>
        </div>
      {/each}
    </div>
  {/if}
</div>
