<script>
  import {createEventDispatcher} from 'svelte';
  import AddressCard from '../Address/AddressCard.svelte';

  export let showComponent;
  export let showPrevComponent;
  export let exported_merchant_id;
  export let merchantInfo;

  let dispatch = createEventDispatcher();
  let AddressCardRef;

  function handleSave() {
    dispatch('GetMerchantID', exported_merchant_id);
    showComponent = 'Contact';
  }


  function handlePrev() {
    showPrevComponent = 'Add Merchant';
  }

</script>

<div class="main">
  <h6 class="title text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
    Address Information
  </h6>
  <div class="flex flex-wrap">
    <AddressCard address={merchantInfo?.address || {}} merchant_id={exported_merchant_id} callbackfn={handleSave} bind:this={AddressCardRef} />
  </div>
  <div class="next-btn">
    <button
      class="bg-gray-700 text-white active:bg-gray-800 font-bold uppercase text-xs px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 h-8"
      type="button"
      on:click={handlePrev}
    >
      Prev
    </button>
    {#if showPrevComponent}
      <button
        class="bg-gray-700 text-white active:bg-gray-800 font-bold uppercase text-xs px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 h-8"
        type="button"
        on:click={() => AddressCardRef.handleUpdate()}
      >
        Update & Next
      </button>
    {:else}
      <button
        class="bg-gray-700 text-white active:bg-gray-800 font-bold uppercase text-xs px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 h-8"
        type="button"
        on:click={() => AddressCardRef.handleUpdate()}
      >
        Save & Next
      </button>
    {/if}
  </div>
</div>

<style>
  .title {
    justify-content: center;
    text-align: center;
    font-size: larger;
  }

  .next-btn {
    justify-content: center;
    text-align: center;
    margin: 20px auto;
  }
</style>
