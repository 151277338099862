<script>
  import {Navigate} from 'svelte-router-spa';
  import {form, bindClass} from 'svelte-forms';
  import axios from 'axios';
  import {showSuccessMessage} from '../../utils/toast';
  import {parseAndShowErrorMessage} from '../../utils/errorParser';

  let email = '';

  const forgotPasswordForm = form(
    () => ({
      email: {value: email, validators: ['required', 'email']}
    }),
    {
      initCheck: false,
      validateOnChange: true
    }
  );

  function requestResetPasswordApi(googleToken) {
    let payload = {
      email
    };

    axios
      .post(`${morrDashboard.env.API_URL}/users/forgot-password`, payload, {
        headers: {
          'x-google-token': googleToken,
          'x-platform': ''
        }
      })
      .then(res => {
        console.log('LOGGING_IN', res);
        showSuccessMessage(res.data.message);
      })
      .catch(err => {
        console.log('request failed', err.response);
        parseAndShowErrorMessage(err);
      });
  }

  function sendResetPasswordLink() {
    forgotPasswordForm.validate();

    grecaptcha.ready(function () {
      grecaptcha
        .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
          action: 'ForgotPassword'
        })
        .then(function (token) {
          if (token) {
            requestResetPasswordApi(token);
          }
        });
    });
  }
</script>

<div class="main-container">
  <div class="w-11/12 sm:10/12 md:w-9/12 lg:w-7/12 xl:w-6/12">
    <form
      class="forms-container form w-full p-8 leading-10 rounded-xl filter drop-shadow-md"
    >
      <img
        class="w-16 my-6"
        src="https://morr-app.s3.ap-south-1.amazonaws.com/assets/logo/icon_logo_transparent.png"
        alt="Welcome to Morr"
        title="Welcome to Morr"
      />
      <h1 class="text-center font-bold text-xl">Forgot password</h1>
      <input
        type="hidden"
        id="g-recaptcha-response"
        name="g-recaptcha-response"
      />
      <input
        id="grid-email"
        type="email"
        class="w-10/12 my-2"
        placeholder="Email"
        bind:value={email}
        use:bindClass={{
          form: forgotPasswordForm,
          invalid:
            'text-sm sm:text-base relative w-10/12 my-2 border rounded placeholder-gray-400 focus:border-indigo-400 focus:outline-none py-2 pr-2 pl-12 border-red-500'
        }}
      />
      {#if $forgotPasswordForm && $forgotPasswordForm.fields.email?.errors?.includes('required')}
        <div class="messages w-10/12">
          <p
            class="flex font-medium tracking-wide text-red-500 text-xs mt-1 ml-1"
          >
            * The email is required
          </p>
        </div>
      {/if}
      {#if $forgotPasswordForm && $forgotPasswordForm.fields.email?.errors?.includes('email')}
        <div class="messages w-10/12">
          <p
            class="flex font-medium tracking-wide text-red-500 text-xs mt-1 ml-1"
          >
            * The email is invalid!
          </p>
        </div>
      {/if}

      <div class="text-center w-full my-3">
        <button
          class="btn w-10/12 morr-background rounded-3xl uppercase py-0 h-10 font-bold outline-none"
          type="button"
          on:click={() => sendResetPasswordLink()}
          disabled={!$forgotPasswordForm.valid}
        >
          Email link
        </button>
      </div>
      <div
        class="flex relative mt-4 w-full z-2 justify-between px-16 text-lg font-normal text-black"
      >
        <div class="space-x-2 inline-block">
          <Navigate to="/auth/login">
            <small>remember the password?</small>
          </Navigate>
        </div>
        <div class="space-x-2 inline-block">
          <Navigate to="/auth/register">
            <small>Create new account</small>
          </Navigate>
        </div>
      </div>
    </form>
  </div>
</div>

<style>
  .messages {
    display: flex;
    justify-content: flex-start;
  }

  .main-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .check {
    display: flex;
    justify-content: flex-start;
  }
  .form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }

  .forms-container {
    background-color: #f5f1ea;
    border-radius: 10px;
    overflow: hidden;
    z-index: 2;
  }

  .form input {
    outline: none;
    border: none;
    border-bottom: 1px solid #b3b1b140;
    background: transparent;
  }

  .form-group {
    display: block;
  }

  .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  .form-group label {
    position: relative;
    cursor: pointer;
  }

  .form-group label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #d1193e;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
  }

  .form-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid #d1193e;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
</style>
