<script>
  import {navigateTo} from 'svelte-router-spa';
  import {onMount} from 'svelte';
  import {isLoggedIn, authToken, refreshToken} from '../../store/auth';
  import {isSuperAdmin} from '../../store/admin';
  import {tick} from 'svelte';
  import axios from 'axios';

  async function removeState() {
    console.log('Logging out User');
    isLoggedIn.set(false);
    authToken.set('');
    refreshToken.set('');
    isSuperAdmin.set(false);
    await tick();
  }
  onMount(async () => {
    console.log('Logging_out');

    const access_token = await axios.delete(
      `${morrDashboard.env.API_URL}/users/access-revoke`,
      {headers: {'X-Auth-Token': $authToken}}
    );
    const refresh_token = await axios.delete(
      `${morrDashboard.env.API_URL}/users/refresh-revoke`,
      {headers: {'X-Auth-Token': $refreshToken}}
    );
    console.log('Logged_out', access_token, refresh_token);
    await removeState();
    navigateTo('/auth/login');
  });
</script>
