<script>
  import {Datatable, rows} from 'svelte-simple-datatables';
  import axios from 'axios';
  import {authToken} from '../../store/auth';
  import {isSuperAdmin} from '../../store/admin';
  import {onMount} from 'svelte';
  import QrCode from 'svelte-qrcode';
  import Toggle from 'svelte-toggle';
  import {fade} from 'svelte/transition';
  import {toast} from '@zerodevx/svelte-toast';
  import {Confirm} from 'svelte-confirm';
  import {Navigate, routeIsActive} from 'svelte-router-spa';
  import ClipboardJS from 'clipboard';
  import {navigateTo} from 'svelte-router-spa';
  import {parseAndShowErrorMessage} from '../../utils/errorParser';
  import Pagination from '@fouita/pagination';

  import AlgoliaSearchBar from '../Search/AlgoliaSearchBar.svelte';

  let clipboard = new ClipboardJS('.clipboard');

  clipboard.on('success', function (e) {
    console.info('Action:', e.action);
    console.info('Text:', e.text);
    console.info('Trigger:', e.trigger);
    toast.pop();
    toast.push('link copied', {
      theme: {
        '--toastBackground': '#48BB78',
        '--toastProgressBackground': '#2F855A'
      }
    });

    e.clearSelection();
  });

  clipboard.on('error', function (e) {
    console.error('Action:', e.action);
    console.error('Trigger:', e.trigger);
    toast.pop();
    toast.push('Failed to copy', {
      theme: {
        '--toastBackground': '#F56565',
        '--toastProgressBackground': '#C53030'
      }
    });
  });

  let validationError = null;

  function showErrorMessage() {
    toast.push(validationError, {
      theme: {
        '--toastBackground': '#F56565',
        '--toastProgressBackground': '#C53030'
      }
    });
  }

  const settings = {
    sortable: true,
    pagination: false,
    rowPerPage: 10,
    scrollY: false,
    columnFilter: true,
    noRows: 'No entries to found',
    css: true,
    blocks: {
      searchInput: false
    }
  };

  const limit = 10;

  let current = 1;
  let skip = 0;
  let totalItems = 0;
  let allMerchants = [];
  let merchantsToShow = [];
  let showMerchants = false;
  let noMerchants = 'loading merchants ...';
  function getMerchantListApi(skip) {
    let headers = {
      'X-Auth-Token': $authToken
    };
    axios

      .get(
        `${morrDashboard.env.API_URL}/admin/merchants/?skip=${skip}&limit=${limit}`,
        {
          headers: headers
        }
      )
      .then(res => {
        console.log('ALL_MERCHANTS_LISTING', res);
        allMerchants = res.data.data;
        merchantsToShow = [...allMerchants];
        totalItems = res.data.paging.total;
        showMerchants = true;
      })
      .catch(err => {
        console.error('ALL_MERCHANTS_LISTING_ERROR', err);
        showMerchants = false;
        noMerchants = 'Merchants not found';
        parseAndShowErrorMessage(err);
      });
  }

  function generateQRCodeJSON(row) {
    let merchantData = {
      merchantUid: row.merchant_id,
      merchantPageUri:
        'https://app.morr.my/tabs/payments/pay/' + row.merchant_id,
      playStore: 'https://play.google.com/store/apps/details?id=io.morr.deals',
      appStore: 'https://apps.apple.com/my/app/morr/id1544728152'
    };
    return JSON.stringify(merchantData);
  }

  onMount(() => {
    setInterval(() => {
      if ($isSuperAdmin) {
        settings['blocks'] = {
          searchInput: false
        };
      } else {
        settings['blocks'] = {
          searchInput: true
        };
      }
    }, 700);
    getMerchantListApi(0);
  });

  let color = 'light';
  let QRcodes = {};

  function showOrHideQRCode(row, action) {
    QRcodes[row.merchant_id] = action;
  }

  function postToQuickListAPI(row, merchantID, googleRecaptchaToken) {
    let action = !row.is_listed;

    let payload = {
      captcha_token: googleRecaptchaToken
    };

    let headers = {
      'X-Auth-Token': $authToken
    };

    axios
      .post(
        `${morrDashboard.env.API_URL}/admin/merchants/quick-list/${merchantID}?action=${action}`,
        payload,
        {
          headers: headers
        }
      )
      .then(res => {
        console.log('MERCHANT_QUICK_LIST', res.data.data);
        row.is_listed = action;
        let status = res.data.data.success
          ? 'Successfully listed'
          : 'Successfully delisted';

        toast.push(status, {
          theme: {
            '--toastBackground': '#48BB78',
            '--toastProgressBackground': '#2F855A'
          }
        });
        validationError = null;
      })
      .catch(err => {
        console.error('MERCHANT_QUICK_LIST_ERROR', err);
        parseAndShowErrorMessage(err);
      });
  }

  function quickList(event, row, merchantID) {
    if (event.type != 'click' || event.target.id.indexOf('toggle') === -1)
      return;

    console.log('STARTING_LISTING');

    grecaptcha.ready(function () {
      grecaptcha
        .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
          action: 'QuickList'
        })
        .then(function (token) {
          if (token) {
            postToQuickListAPI(row, merchantID, token);
          }
        });
    });
  }

  function deleteMerchantAPI(merchantIndex, merchantID, googleRecaptchaToken) {
    let headers = {
      'X-Auth-Token': $authToken
    };

    axios
      .delete(`${morrDashboard.env.API_URL}/admin/merchants/${merchantID}`, {
        headers: headers,
        data: {
          captcha_token: googleRecaptchaToken
        }
      })
      .then(res => {
        merchantsToShow.splice(merchantIndex, 1);
        showMerchants = true;

        toast.push('Successfully deleted merchant profile', {
          theme: {
            '--toastBackground': '#48BB78',
            '--toastProgressBackground': '#2F855A'
          }
        });
      })
      .catch(err => {
        console.log('MERCHANT_DELETE', err);
        validationError = 'Unable to delete merchant';
        showErrorMessage();
      });
  }

  function deleteMerchant(merchantIndex, merchantID) {
    grecaptcha.ready(function () {
      grecaptcha
        .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
          action: 'QuickList'
        })
        .then(function (token) {
          if (token) {
            deleteMerchantAPI(merchantIndex, merchantID, token);
          }
        });
    });
  }

  function paginate(e) {
    if (current === 1) {
      getMerchantListApi(0);
    } else if (current > 1) {
      skip = limit * (current - 1);
      getMerchantListApi(skip);
    }
  }
</script>

<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0"
>
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between">
      <h6 class="text-gray-800 text-xl font-bold">Merchants</h6>
    </div>
  </div>
  <div class="merchant-table block w-full overflow-x-auto">
    {#if showMerchants}
      {#if $isSuperAdmin}
        <AlgoliaSearchBar
          bind:allMerchants
          bind:merchantsToShow
          searchOn="merchant"
        />
      {/if}
      <Datatable
        {settings}
        data={merchantsToShow}
        class="items-center w-full bg-transparent border-collapse my-4"
      >
        <thead>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="name"
          >
            Merchant Name
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="address.city"
          >
            City
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="address.country"
          >
            Country
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="(row) => +row.contact_numbers[0].country_code - row.contact_numbers[0].phone_number"
          >
            Phone
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="ip_address"
          >
            Listed on APP
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="ip_address"
          >
            Links
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="qr_code"
          >
            QR Code
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="delete"
          />
        </thead>
        <tbody>
          {#each $rows as row, index}
            <tr>
              <td
                class="border-t-0 px-6 border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-left flex flex-col items-left align-middle font-thin"
              >
                <div class="flex flex-row">
                  <img
                    src={row.images.profile?.length > 0
                      ? row.images.profile[0]
                      : '../assets/img/res-default.ico'}
                    class="h-12 w-12 bg-white rounded-full border inline"
                    alt={row.name}
                  />
                  <span
                    class="ml-3 font-bold {color === 'light'
                      ? 'text-gray-700'
                      : 'text-white'}"
                  >
                    <Navigate
                      to={`/merchants/profile/${row.merchant_id}`}
                      styles="text-xs uppercase py-3 font-bold block"
                    >
                      {row.name}
                    </Navigate>
                  </span>
                </div>
                <div class="flex flex-row">
                  <span
                    class="ml-3 font-semibold {color === 'light'
                      ? 'text-gray-700'
                      : 'text-white'}"
                  >
                    <Navigate
                      to={`/merchants/${row.merchant_id}/store`}
                      styles="text-xs py-3 block"
                    >
                      <h1>Store</h1>
                    </Navigate>
                  </span>
                  <span
                    class="ml-3 font-semibold {color === 'light'
                      ? 'text-gray-700'
                      : 'text-white'}"
                  >
                    {#if row.store_id}
                      <Navigate
                        to={`/merchants/store/categories/${row.store_id}`}
                        styles="text-xs py-3 block"
                      >
                        <h1>Category View</h1>
                      </Navigate>
                    {/if}
                  </span>

                  <span
                    class="ml-3 font-semibold {color === 'light'
                      ? 'text-gray-700'
                      : 'text-white'}"
                  >
                    {#if row.store_id && row.brand_id}
                      <Navigate
                        to={`/merchants/store/product/list/brand/${row.brand_id}/${row.store_id}`}
                        styles="text-xs py-3 block"
                      >
                        <h1>Products</h1>
                      </Navigate>
                    {:else if row.store_id && !row.brand_id}
                      <Navigate
                        to={`/merchants/store/product/list/${row.store_id}`}
                        styles="text-xs py-3 block"
                      >
                        <h1>Products</h1>
                      </Navigate>
                    {:else}
                      <Navigate
                        to={`/merchants/${row.merchant_id}/store`}
                        styles="text-xs py-3 block"
                      >
                        <h1>Products</h1>
                      </Navigate>
                    {/if}
                  </span>

                  <span
                    class="ml-3 font-semibold {color === 'light'
                      ? 'text-gray-700'
                      : 'text-white'}"
                  >
                    {#if row.store_id}
                      <Navigate
                        to={`/merchants/store/${row.merchant_id}/orders`}
                        styles="text-xs py-3 block"
                      >
                        <h1>Orders</h1>
                      </Navigate>
                    {:else}
                      <Navigate
                        to={`/merchants/${row.merchant_id}/store`}
                        styles="text-xs py-3 block"
                      >
                        <h1>Orders</h1>
                      </Navigate>
                    {/if}
                  </span>
                  <span
                    class="ml-3 font-semibold {color === 'light'
                      ? 'text-gray-700'
                      : 'text-white'}"
                  >
                    <Navigate
                      to={`/merchants/edit/${row.merchant_id}`}
                      styles="text-xs py-3 block"
                    >
                      <h1>Edit</h1>
                    </Navigate>
                  </span>
                </div>
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4"
                >{row.address.city}</td
              >
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4"
                >{row.address.country}</td
              >
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4"
              >
                {#if row.contact_numbers.length > 0}
                  <a
                    href="tel:+{row.contact_numbers[0].country_code} - {row
                      .contact_numbers[0].phone_number}"
                  >
                    +{row.contact_numbers[0].country_code} - {row
                      .contact_numbers[0].phone_number}
                  </a>
                {/if}
              </td>
              {#if $isSuperAdmin}
                <td
                  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4"
                >
                  <Toggle
                    switchColor="white"
                    toggledColor="green"
                    untoggledColor="red"
                    hideLabel
                    toggled={row.is_listed}
                    on:click={e => {
                      quickList(e, row, row.merchant_id);
                    }}
                  />
                </td>
              {:else}
                <td
                  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4"
                >
                  {row.is_listed ? 'Yes' : 'No'}
                </td>
              {/if}
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4"
              >
                <button
                  class="clipboard"
                  data-clipboard-text={`https://app.morr.my/tabs/merchants/${row.merchant_id}`}
                  style="text-align: top;"
                >
                  <i class="fas fa-mobile-alt text-lg" /> App Profile
                </button>
                <div class="py-2 h-1" />
                <button
                  class="clipboard"
                  data-clipboard-text={`https://app.morr.my/tabs/payments/pay/${row.merchant_id}`}
                >
                  <i class="fas fa-hand-holding-usd text-lg" /> Pay
                </button>
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4"
                on:click={() => showOrHideQRCode(row, true)}
                on:mouseleave={() => showOrHideQRCode(row, false)}
              >
                {#if QRcodes[row.merchant_id]}
                  <div in:fade out:fade={{x: 20, delay: 300, duration: 500}}>
                    <QrCode value={generateQRCodeJSON(row)} />
                  </div>
                {:else}
                  Click to reveal
                {/if}
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4"
              >
                <Confirm
                  themeColor="410"
                  confirmTitle="Delete"
                  cancelTitle="Cancel"
                  let:confirm={confirmThis}
                >
                  <button
                    on:click={() =>
                      confirmThis(deleteMerchant, index, row.merchant_id)}
                  >
                    <i class="fas fa-trash text-red-500" />
                  </button>

                  <span slot="title">
                    Are you sure, you want to delete <span class="font-semibold"
                      >{row.name}</span
                    >?
                  </span>
                  <span slot="description" class="text-sm">
                    You won't be able to revert this.
                  </span>
                </Confirm>
              </td>
            </tr>
          {/each}
        </tbody>
      </Datatable>
      <Pagination
        small
        bind:current
        per_page={limit}
        num_items={totalItems}
        on:navigate={paginate}
      />
    {:else}
      <div class="block w-full overflow-x-auto m-4">
        <div>{noMerchants}</div>
      </div>
    {/if}
  </div>
</div>

<style>
  :global(.confirm-dialog) {
    padding: 30px 30px !important;
    position: fixed !important;
  }
  :global(.actions) {
    margin: 25px -30px -30px !important;
  }

  .merchant-table {
    height: 65vh;
    background-color: white;
  }
</style>
