<script>
  import {form, bindClass} from 'svelte-forms';
  import Select from 'svelte-select';
  import {showErrorMessage} from '../../../utils/toast';
  import {createEventDispatcher, onMount} from 'svelte';
  import Toggle from 'svelte-toggle';
  import {linkType} from '../../../constants/linkType';

  import CardFilePondUploader from '../CardFilePondUploader.svelte';

  export let showModal;
  export let operationType;
  export let quickLinkData;
  export let callback;

  let renderTemplate = false;
  let dispatch = createEventDispatcher();

  let imageUrlContainer;
  $: if (imageUrlContainer != quickLinkFormField?.quickLink?.thumbnail) {
    if (imageUrlContainer) {
      quickLinkFormField.quickLink.thumbnail = imageUrlContainer.file_url;
      quickLinkFormField.quickLink.files = [imageUrlContainer.file_url];
    }
  }

  let validationError;

  let quickLinkFormField = {
    quickLink: {
      title: '',
      thumbnail: '',
      order: 0,
      link_type: '',
      link_destination: '',
      is_listed: false,
      active_tab: '',
      extraConfig: '{}',
      files: []
    }
  };

  const createQuickLinkForm = form(
    () => ({
      title: {
        value: quickLinkFormField.quickLink.title,
        validators: ['required']
      },
      order: {
        value: quickLinkFormField.quickLink.order,
        validators: ['required']
      },
      thumbnail: {
        value: quickLinkFormField.quickLink.thumbnail,
        validators: ['required']
      },
      link_type: {
        value: quickLinkFormField.quickLink.link_type,
        validators: ['required']
      },
      link_destination: {
        value: quickLinkFormField.quickLink.link_destination,
        validators: ['required']
      },
      active_tab: {
        value: quickLinkFormField.quickLink.active_tab,
        validators: []
      },
      is_listed: {
        value: quickLinkFormField.quickLink.is_listed,
        validators: ['required']
      }
    }),
    {
      initCheck: true,
      validateOnChange: true
    }
  );

  function buildFormPayload() {
    return {
      title: quickLinkFormField.quickLink.title,
      thumbnail: quickLinkFormField.quickLink.thumbnail,
      order: quickLinkFormField.quickLink.order,
      link_type: quickLinkFormField.quickLink.link_type,
      link_destination: quickLinkFormField.quickLink.link_destination,
      is_listed: quickLinkFormField.quickLink.is_listed,
      active_tab: quickLinkFormField.quickLink.active_tab,
      extra_config: JSON.parse(quickLinkFormField.quickLink.extraConfig || '{}')
    };
  }

  function createQuickLink() {
    validationError = null;

    if (!quickLinkFormField.quickLink.title) {
      validationError = 'Title is required';
    } else if (quickLinkFormField.quickLink.order < 0) {
      validationError = 'Order must be greater than or equal to 0';
    } else if (!quickLinkFormField.quickLink.thumbnail) {
      validationError = 'Thumbnail is required';
    } else if (!quickLinkFormField.quickLink.link_type) {
      validationError = 'Link type is required';
    } else if (!quickLinkFormField.quickLink.link_destination) {
      validationError = 'Link Destination is required';
    } else if (!quickLinkFormField.quickLink.active_tab) {
      validationError = 'Active tab is required';
    }

    if (validationError) {
      showErrorMessage(validationError);
      return;
    }

    let payload = buildFormPayload();
    // dispatch('quickLinkData', payload);
    callback(payload);
  }

  function handlelinkType(e) {
    quickLinkFormField.quickLink.link_type = e.detail.value;
  }

  function resetlinkType(e) {
    quickLinkFormField.quickLink.link_type = '';
  }

  let selectedLinkType;
  function getLinkType() {
    let links = linkType.filter(linkType => {
      if (linkType.value === quickLinkFormField.quickLink.link_type) {
        return linkType;
      }
    });
    console.log('selectedLinkType', links);
    if (links.length > 0) {
      selectedLinkType = links[0];
    }
  }

  onMount(() => {
    if (quickLinkData) {
      quickLinkFormField.quickLink = quickLinkData;
      quickLinkFormField.quickLink.files = [
        quickLinkFormField.quickLink.thumbnail
      ];
      getLinkType();
    }
    setTimeout(() => {
      renderTemplate = true;
    }, 500);
  });
</script>

{#if renderTemplate}
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-2xl bg-gray-200 border-0"
  >
    <div class="flex-auto px-4 lg:px-4 py-4 pt-0">
      <form>
        <div class="flex justify-between">
          <h6 class="text-gray-600 text-xl p-4 mt-3 mb-6 font-bold uppercase">
            {operationType === 'create' ? 'Create' : 'Update'} Quick Link
          </h6>
          <div />
        </div>
        <div>
          <div class="w-full">
            <div class="flex flex-wrap">
              <div class="px-4 w-full">
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-gray-700 text-xs font-bold mb-2"
                    for="title"
                  >
                    Title
                  </label>
                  <input
                    id="title"
                    type="text"
                    class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    placeholder="Please enter title"
                    bind:value={quickLinkFormField.quickLink.title}
                    use:bindClass={{
                      form: createQuickLinkForm,
                      name: 'title'
                    }}
                  />
                </div>
              </div>
            </div>

            <div class="flex flex-wrap">
              <div class="px-4 w-full md:w-1/2">
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-gray-700 text-xs font-bold mb-2"
                    for="link-type"
                  >
                    Link Type
                  </label>
                  <Select
                    containerStyles="min-height: 4rem; color: rgba(113, 128, 150, 1); background-color: rgba(247, 250, 252, 1); padding: 0.1rem 0.02rem; vertical-align:middle"
                    inputAttributes={{
                      class:
                        'h-16 bg-gray-100 text-grey-darker text-grey-darkest border border-gray-100 font-bold w-full px-1 outline-none text-lg text-gray-600'
                    }}
                    inputStyles="font-size: 1.1rem;"
                    items={linkType}
                    isMulti={false}
                    placeholder="Please select link type"
                    noOptionsMessage="No link types available"
                    bind:value={selectedLinkType}
                    on:select={handlelinkType}
                    on:clear={resetlinkType}
                  />
                </div>
              </div>
              <div class="px-4 w-full md:w-1/2">
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-gray-700 text-xs font-bold mb-2"
                    for="orientation"
                  >
                    Link Destination
                  </label>
                  <input
                    id="link-destination"
                    type="text"
                    class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    placeholder="Please enter link destination"
                    bind:value={quickLinkFormField.quickLink.link_destination}
                    use:bindClass={{
                      form: createQuickLinkForm,
                      name: 'link_destination'
                    }}
                  />
                </div>
              </div>
              <div class="px-4 w-full md:w-2/4">
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-gray-700 text-xs font-bold mb-2"
                    for="active-tab"
                  >
                    Active tab
                  </label>
                  <input
                    id="url"
                    type="text"
                    class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    placeholder="Please enter active tab"
                    bind:value={quickLinkFormField.quickLink.active_tab}
                    use:bindClass={{
                      form: createQuickLinkForm,
                      name: 'active_tab'
                    }}
                  />
                </div>
              </div>
              <div class="px-4 w-2/3 md:w-1/4">
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-gray-700 text-xs font-bold mb-2"
                    for="order"
                  >
                    Order
                  </label>
                  <input
                    id="order"
                    type="text"
                    class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    placeholder="Please enter sequence number"
                    bind:value={quickLinkFormField.quickLink.order}
                    use:bindClass={{
                      form: createQuickLinkForm,
                      name: 'order'
                    }}
                  />
                </div>
              </div>
              <div class="px-4 items-center w-1/3 md:w-1/4">
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-gray-700 text-xs font-bold mb-2"
                    for="is-active"
                  >
                    Status
                  </label>
                  <div class="mt-5">
                    <Toggle
                      switchColor="#828282"
                      toggledColor="#e8ad90"
                      untoggledColor="#fff"
                      hideLabel
                      bind:toggled={quickLinkFormField.quickLink.is_listed}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="px-4">
              <div class="relative w-full mb-3">
                <CardFilePondUploader
                  bind:imageUrlContainer
                  image_for="quicklinks"
                  image_type="profile"
                  bind:existingFiles={quickLinkFormField.quickLink.files}
                />

                <label
                  class="block uppercase text-gray-700 text-xs font-bold mb-2"
                  for="orientation"
                >
                  Extra Configuration
                </label>
                <textarea
                  id="extra-config"
                  type="text"
                  class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  bind:value={quickLinkFormField.quickLink.extraConfig}
                  use:bindClass={{
                    form: createQuickLinkForm,
                    name: 'extraConfig'
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="px-4">
          <div
            class="flex items-center justify-center w-full m-4 justify-items-center "
          >
            <button
              class="m-2 bg-blue-500 text-white active:bg-gray-700 text-sm font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
              type="submit"
              on:click|preventDefault={() => {
                createQuickLink();
              }}
            >
              {operationType === 'create' ? 'Save' : 'Update'}
            </button>
            <button
              class="m-2 bg-gray-200 text-slate active:bg-gray-700 text-sm font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
              type="submit"
              on:click|preventDefault={() => {
                showModal = !showModal;
              }}
            >
              Close
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
{/if}

<style>
  :global(input.invalid) {
    border: 1px solid rgb(241, 48, 48);
  }
</style>
