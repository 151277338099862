export function recaptchaWrapper(action, callback, args) {
  /* Recaptcha */

  return new Promise((resolve, reject) => {
    if (window.grecaptcha) {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {action})
          .then((token) => {
            console.log("GOOGLE_RECAPTCHA", token, args)
            args.splice(0, 0, token);
            return callback.apply(null, args);
          });
      });
    } else {
      reject('Google reCAPTCHA not loaded');
    }
  });
}
