<script>
	import { onMount } from 'svelte';
	import initPhoneValidator from '../../../utils/phone-number';

	export let contactInfo;
	let contactHandlerElement;
	let contactHandlerInstance;
	let isMounted = false;

	$: if (contactInfo && isMounted){
		let selectedCountryCode = contactInfo?.newCountryDialCode ? contactInfo.newCountryDialCode: contactHandlerInstance.getSelectedCountryData().dialCode;
		contactHandlerInstance.setNumber(`+${selectedCountryCode}${contactInfo.phone_number}`);
	}

	onMount(()=> {
		contactHandlerInstance = initPhoneValidator(contactHandlerElement);
		if (contactInfo.phone_number && contactInfo.country_code){
			contactInfo.newCountryDialCode = contactInfo.country_code;
			contactHandlerInstance.setNumber(`+${contactInfo.country_code}${contactInfo.phone_number}`);
		}
		isMounted = true;
		contactHandlerElement.addEventListener("countrychange", function() {
			let selectedCountryCode = contactHandlerInstance.getSelectedCountryData().dialCode;
			contactInfo.newCountryDialCode = selectedCountryCode;
		});
	})



</script>
<div class="mobile-number-wrapper">
	<div class="flex flex-wrap flex-row">
		<div class="w-full xl:w-5/12">
			<label
				class="block uppercase text-gray-700 text-xs font-bold mb-2"
				for={`${contactInfo.phone_number}-contact-number`}
			>
				Mobile Number
			</label>

			<input
				id={`${contactInfo.phone_number}-contact-number`}
				type="tel"
				pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
				class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
				placeholder="Please enter a valid mobile number"
				bind:value={contactInfo.phone_number}
				on:input={() => {
					contactHandlerInstance.setNumber(contactHandlerInstance.getNumber());
				}}
				on:change={() => {
					contactInfo.country_code =
					contactHandlerInstance.getSelectedCountryData().dialCode;
				}}
				bind:this={contactHandlerElement}
			/>
		</div>

		<div class="mx-2 mt-5 w-5/12 xl:w-3/12">
			<label
				class="block uppercase text-gray-700 text-xs font-bold mb-2 justify-around"
				for={`${contactInfo.phone_number}-is-primary`}
			>
				<input
					id={`${contactInfo.phone_number}-is-primary`}
					type="checkbox"
					class="form-checkbox text-gray-800 ml-1 w-12 h-12 ease-linear transition-all duration-150"
					name="is_primary"
					bind:checked={contactInfo.is_primary}
				/>
				<span class="ml-2 text-sm font-semibold text-gray-700">
					Primary Number ?
				</span>
			</label>
		</div>

		<div class="mt-5 w-5/12 xl:w-3/12">
			<label
				class="block uppercase text-gray-700 text-xs font-bold mb-2"
				for={`${contactInfo.phone_number}-is-whatsapp-contact`}
			>
				<input
					id={`${contactInfo.phone_number}-is-whatsapp-contact`}
					type="checkbox"
					class="form-checkbox text-gray-800 ml-1 w-12 h-12 ease-linear transition-all duration-150"
					name="is_whatsapp_contact"
					bind:checked={contactInfo.is_whatsapp_contact}
				/>
				<span class="ml-2 text-sm font-semibold text-gray-700">
					Whatsapp Number ?
				</span>
			</label>
		</div>
	</div>

</div>
