<script>
  import axios from 'axios';
  import {authToken} from '../../../store/auth';
  import {form, bindClass} from 'svelte-forms';
  import {showSuccessMessage, showErrorMessage} from '../../../utils/toast';
  import {parseAndShowErrorMessage} from '../../../utils/errorParser';
  import {onMount} from 'svelte';
  import Toggle from 'svelte-toggle';
  import Select from 'svelte-select';

  export let merchant_data;

  let deliveryPartners = [];

  let storeFormFields = {
    delivery_fee_per_km: 0,
    store_custom_tax_rate: 0,
    is_custom_tax_rate_enabled: false,
    schedule: {
      start: '00:00',
      end: '00:00'
    },
    is_third_party_delivery: false,
    delivery_partner: 'Bungkusit'
  };

  function updateStoreFormFields() {
    storeFormFields.delivery_fee_per_km = merchant_data.store
      .delivery_fee_per_km
      ? merchant_data.store.delivery_fee_per_km
      : 0;
    storeFormFields.store_custom_tax_rate = merchant_data.store
      .store_custom_tax_rate
      ? merchant_data.store.store_custom_tax_rate
      : 0;
    storeFormFields.is_custom_tax_rate_enabled = merchant_data.store
      .is_custom_tax_rate_enabled
      ? merchant_data.store.is_custom_tax_rate_enabled
      : false;
    storeFormFields.delivery_partner = merchant_data.store.delivery_partner
      ? merchant_data.store.delivery_partner
      : 'Bungkusit';
    storeFormFields.is_third_party_delivery = merchant_data.store
      .is_third_party_delivery_partner
      ? merchant_data.store.is_third_party_delivery_partner
      : false;
    storeFormFields.schedule = merchant_data.store.schedule
      ? merchant_data.store.schedule
      : {
          start: '00:00',
          end: '00:00'
        };
  }

  function getDeliveryPartnersApi() {
    axios
      .get(`${morrDashboard.env.API_URL}/admin/delivery-partners/`, {
        headers: {
          'x-auth-token': $authToken
        }
      })
      .then(res => {
        deliveryPartners = res.data.data;
      })
      .catch(err => {
        parseAndShowErrorMessage(err);
      });
  }

  onMount(() => {
    updateStoreFormFields();
    getDeliveryPartnersApi();
  });

  let storeUpdateForm = form(
    () => ({
      delivery_fee_per_km: {
        value: storeFormFields.delivery_fee_per_km,
        validators: ['required']
      },
      store_custom_tax_rate: {
        value: storeFormFields.store_custom_tax_rate,
        validators: ['required']
      }
    }),
    {
      initCheck: false,
      validateOnChange: true
    }
  );

  function buildFormPayload() {
    return {
      delivery_fee_per_km: storeFormFields.delivery_fee_per_km,
      store_custom_tax_rate: storeFormFields.store_custom_tax_rate,
      is_custom_tax_rate_enabled: storeFormFields.is_custom_tax_rate_enabled,
      is_third_party_delivery_partner: storeFormFields.is_third_party_delivery,
      delivery_partner: storeFormFields.delivery_partner,
      schedule: storeFormFields.schedule
    };
  }

  function postToUpdateStore(payload) {
    axios
      .put(
        `${morrDashboard.env.API_URL}/admin/merchants/${merchant_data.merchant_id}/update/store`,
        payload,
        {
          headers: {
            'x-auth-token': $authToken
          }
        }
      )
      .then(response => {
        const successMessage = 'Store Updated';
        showSuccessMessage(successMessage);
      })
      .catch(err => {
        parseAndShowErrorMessage(err);
      });
  }

  function handleDeliveryPartners(e) {
    storeFormFields.delivery_partner = e.detail.label;
  }

  function handleDeliveryPartnersReset() {
    storeFormFields.delivery_partner = 'Bungkusit';
  }

  function updateStore() {
    const payload = buildFormPayload();
    postToUpdateStore(payload);
  }
</script>

<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg border-0"
  style="background-color: rgb(245,241,234);"
>
  <div
    class="rounded-t bg-white mb-0 px-6 py-6"
    style="background-color:rgb(232,173,145)"
  >
    <h6 class="text-center text-gray-800 text-xl font-bold uppercase">
      {merchant_data.name}'s Store
    </h6>
  </div>
  <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
    <form>
      <div class="flex flex-col w-full">
        <h6 class="text-gray-500 text-sm mt-3 mb-4 font-bold uppercase">
          Delivery
        </h6>
        <div class="w-full px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-gray-700 text-xs font-bold m-2"
              for="store-delivery-fee"
            >
              Delivery fee per km
            </label>
            <input
              type="number"
              id="store-delivery-fee"
              class="px-3 py-3 placeholder-gray-800 text-gray-700 bg-white rounded-full text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
              placeholder="Delivery fee per km"
              min="0"
              step="0.2"
              bind:value={storeFormFields.delivery_fee_per_km}
              use:bindClass={{
                form: storeUpdateForm,
                name: 'delivery_fee_per_km'
              }}
            />
          </div>
          <div class="relative w-full mb-3 flex">
            <label
              class="block uppercase text-gray-700 text-xs font-bold m-2 mr-3"
              for="third-party-delivery"
              >Third Party Delivery
            </label>

            <Toggle
              switchColor="#828282"
              toggledColor="#e8ad90"
              untoggledColor="#fff"
              hideLabel
              bind:toggled={storeFormFields.is_third_party_delivery}
              style="border: 2px solid #222; height:1.3rem;"
            />
          </div>
          {#if storeFormFields.is_third_party_delivery}
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold m-2"
                for="delivery-partner"
              >
                Delivery Partner
              </label>
              <Select
                containerStyles="min-height: 4rem; color: rgba(113, 128, 150, 1); background-color: rgba(247, 250, 252, 1); vertical-align:middle;margin:10px"
                inputAttributes={{
                  class:
                    'h-16 bg-gray-100 text-grey-darker text-grey-darkest border border-gray-100 font-bold w-full py-1 px-1 outline-none text-lg text-gray-600'
                }}
                inputStyles="font-size: 1.125rem;"
                items={deliveryPartners}
                isMulti={false}
                value={merchant_data.store.delivery_partner}
                placeholder="Select Delivery Partner"
                noOptionsMessage="No Delivery Partners Available"
                on:select={handleDeliveryPartners}
                on:clear={handleDeliveryPartnersReset}
              />
            </div>
          {/if}
        </div>

        <h6 class="text-gray-500 text-sm mt-3 mb-2 font-bold uppercase">
          Taxation
        </h6>
        <div class="w-full px-4">
          <div class="relative w-full mb-2 mt-2 flex">
            <label
              class="block uppercase text-gray-700 text-xs font-bold m-2 mr-2"
              for="store-is-custom-tax-enabled"
              >Enable Custom Tax(%)
            </label>

            <Toggle
              switchColor="#828282"
              toggledColor="#e8ad90"
              untoggledColor="#fff"
              hideLabel
              bind:toggled={storeFormFields.is_custom_tax_rate_enabled}
              style="border: 2px solid #222; height:1.3rem;"
            />
          </div>
          {#if storeFormFields.is_custom_tax_rate_enabled}
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold m-2"
                for="store-custom-tax-rate"
              >
                Store Custom Tax (%)
              </label>
              <input
                type="number"
                id="store-custom-tax-rate"
                class="px-3 py-3 placeholder-gray-800 text-gray-700 bg-white rounded-full text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                placeholder="Store custom tax rate"
                min="0"
                step="0.2"
                bind:value={storeFormFields.store_custom_tax_rate}
                use:bindClass={{
                  form: storeUpdateForm,
                  name: 'store_custom_tax_rate'
                }}
              />
            </div>
          {/if}
        </div>

        <h6 class="text-gray-500 text-sm mt-3 mb-4 font-bold uppercase">
          Schedule
        </h6>
        <div class="w-full px-4">
          <div class="flex flex-col md:flex-row">
            <div class="w-full lg: w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-gray-700 text-xs font-bold mb-2"
                  for="store-schedule-start"
                  >Start
                </label>

                <input
                  type="time"
                  id="store-schedule-start"
                  class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded-full text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  placeholder="Start time"
                  bind:value={storeFormFields.schedule.start}
                />
              </div>
            </div>

            <div class="w-full lg: w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-gray-700 text-xs font-bold mb-2"
                  for="store-schedule-end"
                  >End
                </label>

                <input
                  type="time"
                  id="store-schedule-end"
                  class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded-full text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  placeholder="End time"
                  bind:value={storeFormFields.schedule.end}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="mt-6 border-b-1 border-gray-400" />
      <div class="flex flex-wrap items-center">
        <div class="sm:w-6/12 md:w-1/3 lg:2/12 px-4 mt-4 mx-auto">
          <button
            class="text-white active:bg-gray-700 text-sm font-bold uppercase px-4 py-2 rounded-full shadow hover:shadow-lg outline-none focus:outline-none w-full  ease-linear transition-all duration-150"
            style="background-color: rgb(232,173,144);"
            type="submit"
            disabled={!$storeUpdateForm.valid}
            on:click|preventDefault={() => updateStore()}
          >
            Update Store
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
