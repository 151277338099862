<script>
  import {Datatable, rows} from 'svelte-simple-datatables';
  import axios from 'axios';
  import {authToken} from '../../store/auth';
  import {isSuperAdmin} from '../../store/admin';
  import {onMount} from 'svelte';
  import dateFormat from 'dateformat';
  import Pagination from '@fouita/pagination';

  let current = 1;
  let nextItemIndex = 0;
  let num_items = 100;
  let per_page = 10;

  let color = 'light';

  let allTransactions = [];
  let showTransactions = false;
  let noTransactions = 'loading transactions ...';

  let transactionHistoryCache = {};
  async function changePage(evt) {
    current = evt.detail;
    console.log('Navigating to page ' + current);
    nextItemIndex = getNextSkipOffset(current, per_page);
    if (transactionHistoryCache.hasOwnProperty(current)) {
      allTransactions = transactionHistoryCache[current];
    }
    if (nextItemIndex > num_items) return;
    getTransactionApi(nextItemIndex, per_page);
  }

  function getNextSkipOffset(currentPage, perPage) {
    return (currentPage - 1) * perPage;
  }

  function getTransactionApi(skip, limit) {
    let headers = {
      'X-Auth-Token': $authToken
    };
    axios
      .get(
        `${morrDashboard.env.API_URL}/admin/payments/transactions/history?skip=${skip}&limit=${limit}`,
        {
          headers: headers
        }
      )
      .then(res => {
        console.log('ALL_Transactions_LISTING', res);
        allTransactions = res.data.data;
        transactionHistoryCache[current] = allTransactions;
        allTransactions = [...allTransactions];
        num_items = res.data.paging.total;
        nextItemIndex = res.data.paging.next;
        showTransactions = true;
      })
      .catch(err => {
        console.error('ALL_Transactions_LISTING_ERROR', err);
        showTransactions = false;
        noTransactions = 'Transactions not found';
      });
  }

  onMount(() => {
    nextItemIndex = 0;
    getTransactionApi(nextItemIndex, per_page);
  });

  const settings = {
    sortable: true,
    pagination: false,
    rowPerPage: 10,
    scrollY: false,
    columnFilter: true,
    noRows: 'No entries to found',
    css: true,
    blocks: {
      searchInput: false,
      paginationButtons: true,
      paginationRowCount: true
    }
  };

  function formatPaymentMode(paymentMode) {
    switch (paymentMode) {
      case 'DD':
        return 'NetBanking';
      case 'WA':
        return 'E-wallet';
      case 'CC':
        return 'Card';
      case 'CASH':
        return 'Pay Direct (Cash)';
      default:
        return paymentMode;
    }
  }

  function formatTransactionStatus(status) {
    switch (status) {
      case '0':
        return 'Successful';
      case '1':
        return 'Failed';
      case '2':
        return 'Pending or expired';
      case '4':
        return 'Rejected by Merchant';
      default:
        return 'incomplete';
    }
  }

  function formatDateTime(dateTimeStr) {
    return dateFormat(dateTimeStr + '+00:00', 'dddd, mmmm dS, yyyy, h:MM TT');
  }

  let lastRowColor = null;
  let lastOrderId = null;
  function getRowColor(numberOfRows, order_id, index) {
    let currentColor = 'bg-gray-200';
    if (lastRowColor === currentColor) {
      currentColor = 'bg-gray-100';
    }

    if (lastOrderId === order_id) {
      currentColor = lastRowColor;
    } else {
      lastRowColor = currentColor;
    }

    if (numberOfRows == 1) {
      currentColor = '';
    }

    lastOrderId = order_id;
    if (index === 0) {
      currentColor += ' border-t-2 border-yellow-200';
    }
    return currentColor;
  }

  function changePageSize(event) {
    transactionHistoryCache = {};
    current = 1;
    nextItemIndex = 0;
    per_page = event.target.value;
    getTransactionApi(nextItemIndex, per_page);
  }
</script>

<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0"
>
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between">
      <h6 class="text-gray-800 text-xl font-bold">Transaction History</h6>
    </div>
  </div>
  <div class="block w-full overflow-x-auto py-8 mb-4">
    {#if showTransactions}
      <Datatable
        {settings}
        data={allTransactions}
        class="items-center w-full bg-transparent border-collapse px-4"
      >
        <thead>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="(row) => row.merchant.name"
          >
            Merchant Name
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="address.city"
          >
            Transaction ID
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="address.country"
          >
            Payment Mode
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="(row) => +row.contact_numbers[0].country_code - row.contact_numbers[0].phone_number"
          >
            Amount
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="ip_address"
          >
            Status
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="ip_address"
          >
            Time
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="ip_address"
          >
            Points Redeemed
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left {color ===
            'light'
              ? 'bg-gray-100 text-gray-600 border-gray-200'
              : 'bg-red-700 text-red-200 border-red-600'}"
            data-key="ip_address"
          >
            Points Credited
          </th>
        </thead>
        <tbody class="h-screen">
          {#each $rows as row}
            {#each row.transactions as transaction, index}
              <tr
                class="{transaction.TxnStatus === '0'
                  ? 'bg-gradient-to-r from-green-300 to-blue-100'
                  : ''}  {transaction.TxnStatus === '1'
                  ? 'bg-gradient-to-l from-gray-300 to-red-100'
                  : ''}  {getRowColor(
                  row.transactions.length,
                  row.order_id,
                  index
                )} text-center "
              >
                {#if index == 0}
                  <th
                    class="border-t-0 px-6 border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-left "
                  >
                    <div class="flex items-center align-middle w-full">
                      <img
                        src={row.merchant.images.profile?.length > 0
                          ? row.merchant.images.profile[0]
                          : '../assets/img/res-default.ico'}
                        class="h-12 w-12 bg-white rounded-full border inline"
                        alt={row.merchant.name}
                      />
                      <span
                        class="ml-3 font-bold {color === 'light'
                          ? 'btext-gray-700'
                          : 'text-white'}"
                      >
                        {row.merchant.name}
                      </span>
                    </div>

                    <p class="block mt-4">
                      Order ID: #{row.order_id}
                    </p>
                  </th>
                {:else}
                  <th />
                {/if}

                <td
                  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4"
                >
                  <p>{transaction.PaymentID}</p>
                </td>
                <td
                  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4"
                >
                  {formatPaymentMode(transaction.PymtMethod)}
                </td>
                <td
                  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4"
                >
                  {transaction.CurrencyCode}
                  <span class="font-bold">{transaction.Amount}</span>
                </td>
                <td
                  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 uppercase font-semibold"
                >
                  {formatTransactionStatus(transaction.TxnStatus)}
                </td>
                <td
                  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4"
                >
                  {formatDateTime(transaction.created_at)}
                </td>
                <td
                  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4"
                >
                  {transaction.TxnStatus === '0' && transaction.Param7
                    ? transaction.Param7
                    : '-'}
                </td>
                <td
                  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4"
                >
                  {transaction.TxnStatus === '0' && transaction.Param6
                    ? transaction.Param6
                    : '-'}
                </td>
              </tr>
            {/each}
          {/each}
        </tbody>
      </Datatable>
      <div class="flex justify-between items-center px-4">
        <div class="flex items-center pagination">
          Per page:
          <select
            class="border px-2 rounded ml-2"
            bind:value={per_page}
            on:change={changePageSize}
          >
            <option selected={per_page == 10}>10</option>
            <option selected={per_page == 25}>25</option>
            <option selected={per_page == 50}>50</option>
          </select>
        </div>
        <Pagination
          bind:current
          {num_items}
          {per_page}
          on:navigate={changePage}
        />
      </div>
    {:else}
      <div class="block w-full overflow-x-auto m-4">
        <div>{noTransactions}</div>
      </div>
    {/if}
  </div>
</div>
