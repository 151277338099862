<script>
  // core components
  import CardProductTemplateDatatable from 'components/Cards/CardProductTemplateDatatable.svelte';
  export let currentRoute;
</script>

<div>
  <div class="flex flex-wrap">
    <div class="w-full">
      <CardProductTemplateDatatable
        brand_id={currentRoute.namedParams.brand_id}
      />
    </div>
  </div>
</div>
