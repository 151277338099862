<script>
  import {onMount} from 'svelte';

  export let invoiceDetails;
  export let orderDetail;
  let totalPrice = 0;
  let tax = 0;
  let status = 0;

  function totalProductPrice(invoiceDetails) {
    let productCount = invoiceDetails.products.length;
    for (let i = 0; i < productCount; i++) {
      totalPrice += invoiceDetails.products[i].total_price;
    }
  }

  function taxAmount() {
    let taxP = orderDetail.tax;
    tax = (taxP * totalPrice) / 100;
    return tax;
  }

  console.log('details invoice', invoiceDetails, orderDetail);
  totalProductPrice(invoiceDetails);
  status = orderDetail.status;
</script>

{#if invoiceDetails}
  <div class="w-full xl:ml-10 mr-auto px-2">
    <div class="max-w-md mx-auto rounded-lg overflow-hidden md:max-w-xl">
      <div class="md:flex">
        <div class="w-full p-3">
          <div
            class="border rounded-lg border-solid py-5 border-1 border-slate-200"
          >
            <div class="">
              <h1 class="text-gray-700 font-bold text-center uppercase">
                Invoice
              </h1>
              <div class="flex w-full mt-3 mb-3">
                <span class="border-1 border-solid w-full border-slate-200" />
              </div>
              <div class="flex justify-around px-4">
                <div class="title text-left w-6/12 font-normal">Price</div>
                <div class="value text-right  w-6/12">
                  <span class="mt-2 text-slate-400 font-bolder">RM</span>
                  <span class="text-gray-500 text-lg font-semibold">
                    {totalPrice}
                  </span>
                </div>
              </div>
              <div class="flex justify-around px-4">
                <div class="title text-left w-6/12 font-normal">
                  Tax: ({invoiceDetails.order_details.tax_percent || 0}%)
                </div>
                <div class="value text-right  w-6/12">
                  <span class="mt-2 text-slate-400 font-bolder">RM</span>
                  <span class="text-gray-500 text-lg font-semibold">
                    {invoiceDetails.order_details.tax_value || 0}
                  </span>
                </div>
              </div>
              {#if status === 1}
                <div class="flex justify-around px-4">
                  <div class="title text-left w-6/12 font-normal">
                    Delivery fee
                  </div>
                  <div class="value text-right  w-6/12">
                    <span class="mt-2 text-slate-400 font-bolder">RM</span>
                    <span class="text-gray-500 text-lg font-semibold">
                      {orderDetail.delivery_charge
                        ? orderDetail.delivery_charge
                        : 0}
                    </span>
                  </div>
                </div>
              {/if}
              <div class="flex w-full mt-3 mb-3">
                <span class="border-1 border-solid w-full border-slate-200" />
              </div>
              <div class="flex justify-around px-4">
                <div class="title text-left w-6/12 font-normal">Total</div>
                <div class="value text-right  w-6/12">
                  <span class="mt-2 text-slate-400 font-bolder">RM</span>
                  <span class="text-gray-500 text-lg font-bold">
                    {orderDetail ? orderDetail.total_price : 0}
                  </span>
                </div>
              </div>
              <div class="flex w-full mt-3 mb-3">
                <span class="border-1 border-solid w-full border-slate-200" />
              </div>
              <div class="flex justify-around px-4">
                <div class="title text-left w-6/12 font-semibold uppercase">
                  Amount Paid
                </div>
                <div class="value text-right  w-6/12">
                  <span class="mt-2 text-slate-400 font-bolder">RM</span>
                  <span class="text-slate-500 text-3xl font-bold">
                    {orderDetail.amount_paid ? orderDetail.amount_paid : 0}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}
