<script>
  import {form, bindClass} from 'svelte-forms';
  import Select from 'svelte-select';
  import {onMount} from 'svelte';
  import {showErrorMessage} from '../../../utils/toast';
  import Toggle from 'svelte-toggle';
  import CardFilePondUploader from '../CardFilePondUploader.svelte';
  import {linkType} from '../../../constants/linkType';

  export let showModal;
  export let operationType;
  export let promoBannerData;
  export let callback;

  let renderTemplate = false;

  let imageUrlContainer;
  $: if (imageUrlContainer != promoBannerFormField?.promoBanner?.thumbnail) {
    if (imageUrlContainer) {
      promoBannerFormField.promoBanner.thumbnail = imageUrlContainer.file_url;
      promoBannerFormField.promoBanner.files = [imageUrlContainer.file_url];
    }
  }

  let validationError;

  let promoBannerFormField = {
    promoBanner: {
      title: '',
      description: '',
      thumbnail: '',
      order: 0,
      is_listed: true,
      link_type: '',
      link_destination: '',
      active_tab: '',
      files: []
    }
  };

  const promoBannerForm = form(
    () => ({
      title: {
        value: promoBannerFormField.promoBanner.title,
        validators: ['required']
      },
      order: {
        value: promoBannerFormField.promoBanner.order,
        validators: ['required']
      },
      is_listed: {
        value: promoBannerFormField.promoBanner.is_listed,
        validators: ['required']
      },
      description: {
        value: promoBannerFormField.promoBanner.description
      },
      thumbnail: {
        value: promoBannerFormField.promoBanner.thumbnail,
        validators: ['required']
      }
    }),
    {
      initCheck: true,
      validateOnChange: true
    }
  );

  function buildFormPayload() {
    return {
      title: promoBannerFormField.promoBanner.title,
      order: promoBannerFormField.promoBanner.order,
      is_listed: promoBannerFormField.promoBanner.is_listed,
      description: promoBannerFormField.promoBanner.description,
      thumbnail: promoBannerFormField.promoBanner.thumbnail,
      link_type: promoBannerFormField.promoBanner.link_type,
      link_destination: promoBannerFormField.promoBanner.link_destination,
      active_tab: promoBannerFormField.promoBanner.active_tab
    };
  }

  function validatePromoBanner() {
    validationError = null;
    if (!promoBannerFormField.promoBanner.title) {
      validationError = 'Title is required';
    } else if (promoBannerFormField.promoBanner.order <= 0) {
      validationError = 'Order must be greater than 0';
    } else if (!promoBannerFormField.promoBanner.thumbnail) {
      validationError = 'Thumbnail is required';
    }
    if (promoBannerFormField.promoBanner.link_destination) {
      if (!promoBannerFormField.promoBanner.link_type) {
        validationError = 'Link type is required';
      }
    }
    if (validationError) {
      showErrorMessage(validationError);
      return;
    }
    let payload = buildFormPayload();
    callback(payload);
  }

  function handleLinkType(e) {
    promoBannerFormField.promoBanner.link_type = e.detail.value;
  }

  function resetLinkType(e) {
    promoBannerFormField.promoBanner.link_type = '';
  }

  let selectedLinkType;
  function getLinkType() {
    let links = linkType.filter(linkType => {
      if (linkType.value === promoBannerFormField.promoBanner.link_type) {
        return linkType;
      }
    });
    console.log('selectedLinkType', links);
    if (links.length > 0) {
      selectedLinkType = links[0];
    }
  }

  onMount(() => {
    if (promoBannerData) {
      promoBannerFormField.promoBanner = promoBannerData;
      promoBannerFormField.promoBanner.files = [
        promoBannerFormField.promoBanner.thumbnail
      ];
      getLinkType();
    }
    renderTemplate = true;
  });
</script>

{#if renderTemplate}
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-2xl bg-gray-200 border-0"
  >
    <div class="second flex-auto px-4 lg:px-4 py-4 pt-0">
      <form>
        <div class="flex justify-between">
          <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
            {operationType === 'Create' ? 'Create' : 'Update'} Promo Banner
          </h6>
          <div />
        </div>
        <div>
          <div class="w-full">
            <div class="px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-gray-700 text-xs font-bold my-2"
                  for="thumbnail"
                >
                  Thumbnail
                </label>
                <CardFilePondUploader
                  bind:imageUrlContainer
                  image_for="promoBanners"
                  image_type="cover"
                  bind:existingFiles={promoBannerFormField.promoBanner.files}
                />
              </div>
            </div>
            <div class="flex mb-3 flex-col md:flex-row justify-between">
              <div class="px-4 w-full md:w-8/12">
                <label
                  class="block uppercase text-gray-700 text-xs font-bold my-2"
                  for="title"
                >
                  Title
                </label>
                <input
                  id="title"
                  type="text"
                  class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  placeholder="Please enter promo-banner title"
                  bind:value={promoBannerFormField.promoBanner.title}
                  use:bindClass={{
                    form: promoBannerForm,
                    name: 'title'
                  }}
                />
              </div>

              <div class="px-4 w-full md:w-2/12">
                <label
                  class="block uppercase text-gray-700 text-xs font-bold my-2"
                  for="order"
                >
                  Order
                </label>
                <input
                  id="order"
                  type="text"
                  class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  placeholder="Please enter sequence number"
                  bind:value={promoBannerFormField.promoBanner.order}
                  use:bindClass={{
                    form: promoBannerForm,
                    name: 'order'
                  }}
                />
              </div>

              <div class="px-4 items-center">
                <label
                  class="block uppercase text-gray-700 text-xs font-bold my-2"
                  for="is-listed"
                >
                  Status
                </label>
                <Toggle
                  switchColor="#828282"
                  toggledColor="#e8ad90"
                  untoggledColor="#fff"
                  hideLabel
                  bind:toggled={promoBannerFormField.promoBanner.is_listed}
                />
              </div>
            </div>
            <div class="px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-gray-700 text-xs font-bold my-2"
                  for="description"
                >
                  Description
                </label>
                <textarea
                  id="description"
                  type="text"
                  class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  placeholder="Please enter your banner description"
                  bind:value={promoBannerFormField.promoBanner.description}
                  use:bindClass={{
                    form: promoBannerForm,
                    name: 'description'
                  }}
                />
              </div>
            </div>

            <div class="flex mb-3 flex-col md:flex-row justify-between">
              <div class="px-4 w-full md:w-full">
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-gray-700 text-xs font-bold my-2"
                    for="link-type"
                  >
                    Link Type
                  </label>
                  <Select
                    containerStyles="min-height: 4rem; color: rgba(113, 128, 150, 1); background-color: rgba(247, 250, 252, 1); padding: 0.1rem 0.1rem; vertical-align:middle"
                    inputAttributes={{
                      class:
                        'h-16 bg-gray-100 text-grey-darker text-grey-darkest border border-gray-100 font-bold w-full py-1 px-1 outline-none text-lg text-gray-600'
                    }}
                    inputStyles="font-size: 1.1rem;"
                    items={linkType}
                    isMulti={false}
                    placeholder="Please select Link Type"
                    noOptionsMessage="No link type available"
                    bind:value={selectedLinkType}
                    on:select={handleLinkType}
                    on:clear={resetLinkType}
                  />
                </div>
              </div>
            </div>
            <div class="flex mb-3 flex-col md:flex-row justify-between">
              <div class="px-4 w-full md:w-6/12">
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-gray-700 text-xs font-bold my-2"
                    for="link-destination"
                  >
                    Link Destination
                  </label>
                  <input
                    id="link_destination"
                    type="text"
                    class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    placeholder="Please enter link destination"
                    bind:value={promoBannerFormField.promoBanner
                      .link_destination}
                  />
                </div>
              </div>

              <div class="px-4 w-full md:w-6/12">
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-gray-700 text-xs font-bold my-2"
                    for="active-tab"
                  >
                    Active Tab
                  </label>
                  <input
                    id="active-tab"
                    type="text"
                    class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    placeholder="Please enter active tab"
                    bind:value={promoBannerFormField.promoBanner.active_tab}
                  />
                </div>
              </div>
            </div>
            <div class="px-4">
              <div
                class="flex items-center justify-center w-full m-4 justify-items-center "
              >
                <button
                  class="m-2 bg-blue-500 text-white active:bg-gray-700 text-sm font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                  type="submit"
                  on:click|preventDefault={() => {
                    validatePromoBanner();
                  }}
                >
                  {operationType === 'Create' ? 'Save' : 'Update'}
                </button>
                <button
                  class="m-2 bg-blue-500 text-white active:bg-gray-700 text-sm font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                  type="submit"
                  on:click|preventDefault={() => {
                    showModal = !showModal;
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
{/if}

<style>
  :global(input.invalid) {
    border: 1px solid rgb(241, 48, 48);
  }
</style>
